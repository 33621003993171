<template lang="pug">
app-container(:title="pageTitle" :sub-title="subTitle" :footerButtons="footerButtons" @goBack="goBack")
  h5 Kein Kind angelegt
  p Damit Du Pendelkinder verwenden kannst, musst du zunächst ein Kind in den Settings anlegen.
  p
    router-link.btn(:to="{ name: 'settings' }" tag="button") Kind anlegen


</template>

<script>
import AppContainer from "../app_container.vue"

export default {
  components: { AppContainer },
  data() {
    return {
      pageTitle: "Pendelkinder",
      subTitle: "Kind fehlt"
    }
  },
  computed: {
    footerButtons() {
      return {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
