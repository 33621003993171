<template lang="pug">
  ul
    li(@click="editTodo(todo)" :class="role")
      | {{ title }}
</template>

<script>
export default {
  props: {
    todo: {
      type: Object,
      required: true
    }
  },
  computed: {
    role() {
      if (this.todo.parent) {
        return this.todo.parent.role
      }
      return "none"
    },
    title() {
      let parts = []
      if (this.todo.parent_username) {
        parts.push(this.todo.parent_username.toUpperCase())
      }
      parts.push(this.todo.title)
      parts.push(this.todo.child.name)
      return parts.join(" | ")
    }
  },
  methods: {
    editTodo() {
      this.$router.push({ name: "edit_todo", params: { id: this.todo.id } })
    }
  }
}
</script>
