<template lang="pug">
  .app-onboarding-start.uk-text-center
    h4.title Weitere Einstellungen
    p Prüfe deine Einstellungen in der App und schaue, was du dort noch aktivieren möchtest (Ferien, Push-Benachrichtigungen etc.).

    h4 Einladung bereits erhalten

    p Hast du einen Einladungslink zur Koppelung erhalten, kannst du ihn nun anklicken und eure beiden Apps sind mit einander verbunden.
</template>

<script>
export default {
  created() {
    this.validateForm()
  },
  methods: {
    validateForm() {
      this.$emit("isFormValidChange", true)
    }
  }
}
</script>
