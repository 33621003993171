import VueRouter from "vue-router"
import store from "./store"

import Invite from "./components/invites/invite.vue"

import SignUp from "./components/users/sign_up.vue"
import SignIn from "./components/users/sign_in.vue"
import CodeSignIn from "./components/users/code_sign_in.vue"
import Terms from "./components/users/terms.vue"
import PrivacyPolicy from "./components/users/privacy_policy.vue"
import TermsAndConditions from "./components/users/terms_and_conditions.vue"
import NewPassword from "./components/users/new_password.vue"
import EditPassword from "./components/users/edit_password.vue"
import AccountConfirmation from "./components/users/account_confirmation.vue"
import Page from "./components/pages/page.vue"

import ChildrenMissing from "./components/users/children_missing.vue"
import WelcomeUser from "./components/users/welcome.vue"
// import AppHome from "./components/users/welcome.vue"
import OnboardingStart from "./components/users/onboarding_start.vue"
import Onboarding from "./components/users/onboarding.vue"
import NewSubscription from "./components/users/new_subscription.vue"
import Settings from "./components/users/settings.vue"

import Contacts from "./components/contacts/contacts.vue"
import Contact from "./components/contacts/contact.vue"
import EditContact from "./components/contacts/form_contact.vue"
import NewContact from "./components/contacts/form_contact.vue"

import Calendar from "./components/calendar/overview.vue"
import NewCalendarEntry from "./components/calendar/form_date.vue"
import EditCalendarEntry from "./components/calendar/form_date.vue"
import SpecialCalendarEntry from "./components/calendar/form_date.vue"

import Messager from "./components/messenger/messenger.vue"

import Todos from "./components/todos/todos.vue"
import NewTodoEntry from "./components/todos/form_todo.vue"
import EditTodoEntry from "./components/todos/form_todo.vue"

import Schedule from "./components/schedule/timetable.vue"
import FormScheduleEntries from "./components/schedule/form_timetable_entries.vue"

import Uploads from "./components/uploads/uploads.vue"
import NewUpload from "./components/uploads/form_upload.vue"
import EditUpload from "./components/uploads/form_upload.vue"

import News from "./components/news/news.vue"
import Download from "./components/news/download.vue"

import AppWelcome from "./components/app_onboarding/welcome.vue"
import AppOnboarding from "./components/app_onboarding/overview.vue"
import AppOnboardingStart from "./components/app_onboarding/start.vue"
import AppOnboardingChildren from "./components/app_onboarding/children.vue"
import AppOnboardingParents from "./components/app_onboarding/parents.vue"
import AppOnboardingParentsEmail from "./components/app_onboarding/parents_email.vue"
import AppOnboardingSettingsInfo from "./components/app_onboarding/settings_info.vue"
import AppOnboardingSettingsCode from "./components/app_onboarding/settings_code.vue"
import AppOnboardingCompleted from "./components/app_onboarding/completed.vue"

const publicRoutes = [
  {
    path: "/invites/:token",
    component: Invite,
    name: "invite",
    props: true
  },
  {
    path: "/users/sign_up",
    component: SignUp,
    name: "new_user_registration",
    meta: { isSignInDisallowed: true }
  },
  {
    path: "/users/sign_in",
    component: SignIn,
    name: "new_user_session",
    meta: { isSignInDisallowed: true }
  },
  {
    path: "/users/code_sign_in",
    component: CodeSignIn,
    name: "new_activation_code_session",
    meta: { isSignInDisallowed: true }
  },
  {
    path: "/users/password/new",
    component: NewPassword,
    name: "new_user_password",
    meta: { isSignInDisallowed: true },
    props: true
  },
  {
    path: "/users/password/edit/:token",
    component: EditPassword,
    name: "edit_user_password",
    meta: { isSignInDisallowed: true },
    props: true
  },
  { path: "/users/terms", component: Terms, name: "terms" },
  {
    path: "/users/privacy_policy",
    component: PrivacyPolicy,
    name: "privacy_policy"
  },
  {
    path: "/users/terms_and_conditions",
    component: TermsAndConditions,
    name: "terms_and_conditions"
  },
  {
    path: "/users/confirmation/:token",
    component: AccountConfirmation,
    name: "account_confirmation",
    props: true
  },
  {
    path: "/pages/:permalink",
    component: Page,
    name: "page",
    props: true
  },
  {
    path: "/users/welcome",
    component: WelcomeUser,
    name: "welcome_user"
  },
  {
    path: "/onboarding",
    component: OnboardingStart,
    name: "onboarding_start"
  }
]

const privateRoutes = [
  {
    path: "/",
    component: Calendar,
    name: "root"
  },
  {
    path: "/children/missing",
    component: ChildrenMissing,
    name: "child_information"
  },
  {
    path: "/users/settings_onboarding",
    component: Onboarding,
    name: "settings_onboarding",
    meta: { needSubscription: false }
  },
  {
    path: "/users/subscription/new",
    component: NewSubscription,
    name: "new_subscription",
    meta: {
      needSubscription: false,
      guestForbitten: true
    }
  },
  {
    path: "/users/settings",
    component: Settings,
    name: "settings",
    meta: { guestForbitten: true }
  },

  {
    path: "/contacts",
    component: Contacts,
    name: "contacts"
  },
  {
    path: "/contacts/new",
    component: NewContact,
    name: "new_contact",
    meta: { needChildren: true }
  },
  {
    path: "/contacts/:id/edit",
    component: EditContact,
    name: "edit_contact",
    meta: { needChildren: true }
  },
  {
    path: "/contacts/:id",
    component: Contact,
    name: "contact"
  },
  {
    path: "/calendar",
    component: Calendar,
    name: "calendar"
  },
  {
    path: "/calendar/new",
    component: NewCalendarEntry,
    name: "new_calendar",
    props: true,
    meta: {
      needChildren: true,
      guestForbitten: true
    }
  },
  {
    path: "/calendar/:id/special",
    component: SpecialCalendarEntry,
    name: "special_calendar",
    props: true,
    meta: {
      needChildren: true,
      guestForbitten: true
    }
  },
  {
    path: "/calendar/:id/edit",
    component: EditCalendarEntry,
    name: "edit_calendar",
    props: true,
    meta: { needChildren: true }
  },
  {
    path: "/messenger",
    component: Messager,
    name: "messenger",
    meta: { guestForbitten: true }
  },
  {
    path: "/todos",
    component: Todos,
    name: "todos"
  },
  {
    path: "/todos/new",
    component: NewTodoEntry,
    name: "new_todo",
    meta: {
      needChildren: true,
      guestForbitten: true
    }
  },
  {
    path: "/todos/:id/edit",
    component: EditTodoEntry,
    name: "edit_todo",
    meta: {
      needChildren: true,
      guestForbitten: true
    }
  },
  {
    path: "/schedule",
    component: Schedule,
    name: "schedule",
    props: true
  },
  {
    path: "/children/:childId/schedule/edit",
    component: FormScheduleEntries,
    name: "form_timetable_entries",
    meta: {
      needChildren: true,
      guestForbitten: true
    }
  },

  { path: "/uploads", component: Uploads, name: "uploads" },
  { path: "/uploads/:id", component: EditUpload, name: "upload" },
  {
    path: "/uploads/new",
    component: NewUpload,
    name: "new_upload",
    meta: { guestForbitten: true }
  },

  { path: "/news", component: News, name: "news" },
  {
    path: "/news/download",
    component: Download,
    name: "news_download",
    meta: { guestForbitten: true }
  },

  {
    path: "/app",
    component: Calendar,
    name: "app_start"
  },

  {
    path: "/app-welcome",
    component: AppWelcome,
    name: "app_welcome",
    meta: { isAppWelcome: true }
  },
  {
    path: "/app-onboarding",
    component: AppOnboarding,
    name: "app_onboarding",
    meta: { isAppOnboarding: true },
    redirect: { name: "app_onboarding_start" },
    children: [
      {
        path: "start",
        name: "app_onboarding_start",
        component: AppOnboardingStart,
        meta: { isAppOnboarding: true }
      },
      {
        path: "children",
        name: "app_onboarding_children",
        component: AppOnboardingChildren,
        meta: { isAppOnboarding: true }
      },
      {
        path: "parents",
        name: "app_onboarding_parents",
        component: AppOnboardingParents,
        meta: { isAppOnboarding: true, skipForParents: true }
      },
      {
        path: "parents_email",
        name: "app_onboarding_parents_email",
        component: AppOnboardingParentsEmail,
        meta: { isAppOnboarding: true, skipForParents: true }
      },
      {
        path: "settings_code",
        name: "app_onboarding_settings_code",
        component: AppOnboardingSettingsCode,
        meta: { isAppOnboarding: true }
      },
      {
        path: "settings_info",
        name: "app_onboarding_settings_info",
        component: AppOnboardingSettingsInfo,
        meta: { isAppOnboarding: true }
      }
    ]
  },
  {
    path: "/app-onboarding-completed",
    component: AppOnboardingCompleted,
    name: "app_onboarding_completed"
  }
]

const routes = [
  ...publicRoutes.map(route => ({
    ...route,
    meta: { ...route.meta, isPublic: true }
  })),
  ...privateRoutes
]

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    const origin = { x: 0, y: 0 }
    return savedPosition || origin
  }
})

const signedIn = () => store.getters.isUserPresent

const checkSignin = async (to, from, next) => {
  if (!signedIn()) {
    await store.dispatch("checkSignin")
  }

  next()
}

const decideForcedScreen = (to, from, next) => {
  if (!signedIn()) {
    return to.meta.isPublic ? next() : next({ name: "onboarding_start" })
  }

  next()
}

const needChildren = (to, from, next) => {
  if (
    to.meta &&
    to.meta.needChildren &&
    Object.values(store.state.children.children).length == 0
  ) {
    next({ name: "child_information" })
    return
  }

  next()
}

const isPlanActive = () => {
  return (store.getters.isSubscription || store.getters.isTestAccount)
}

const needSubscription = (to, from, next) => {
  if (
    signedIn() &&
    to.meta &&
    to.meta.needSubscription !== false &&
    !isPlanActive()
  ) {
    next({ name: "new_subscription" })
    return
  }

  next()
}

const needOnboarding = (to, from, next) => {
  if (
    isPlanActive() &&
    signedIn() &&
    to.meta &&
    Object.keys(to.meta).length &&
    to.meta.isAppWelcome !== true &&
    to.meta.isAppOnboarding !== true &&
    !store.getters.isOnboardingCompleted
  ) {
    next({ name: "app_welcome" })
    return
  }

  next()
}

const disallowSignedIn = (to, from, next) => {
  if (to.meta && to.meta.isSignInDisallowed && store.getters.isUserPresent) {
    next({ name: "root" })
    return
  }

  next()
}

;[
  checkSignin,
  decideForcedScreen,
  disallowSignedIn,
  needChildren,
  needSubscription,
  needOnboarding
].forEach(guard => router.beforeEach(guard))

export default router
