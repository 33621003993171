var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-container" } },
    [
      _vm.fetching
        ? [
            _c("transition", { attrs: { name: "fade" } }),
            _c("loading-animation")
          ]
        : _vm._e(),
      _c("div", { attrs: { id: "navigation-bar" } }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(
              "div",
              {
                staticClass: "titles",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.titleClick($event)
                  }
                }
              },
              [
                _c("h1", [_vm._v(_vm._s(_vm.title))]),
                _vm.subTitle && _vm.subTitle !== ""
                  ? _c("h2", [_vm._v(_vm._s(_vm.subTitle))])
                  : _vm._t("sub-title")
              ],
              2
            ),
            _vm.isNavigationBarMenuVisible
              ? _c("navigation-bar-menu", { on: { onLogout: _vm.doLogout } })
              : _vm._e()
          ],
          1
        ),
        _vm.showBottom
          ? _c("div", { staticClass: "bottom" }, [_vm._t("nav-bottom")], 2)
          : _vm._e()
      ]),
      _c(
        "div",
        {
          class: { "full-navigation": _vm.showBottom },
          attrs: { id: "main-container" }
        },
        [
          _vm.shouldShowGuestForbittenMessage
            ? _c("section", [
                _c("p", [
                  _vm._v(
                    "Dies ist der Leseaccount von Pendelkinder. Du hast hier die Möglichkeit in alle Daten reinzusehen. Aber du kannst keine Termine oder Änderungen vornehmen."
                  )
                ])
              ])
            : _vm._t("default")
        ],
        2
      ),
      _c(
        "div",
        { attrs: { id: "footer-bar" } },
        [
          _vm.shouldShowGuestForbittenMessage
            ? [
                _c("router-link", {
                  staticClass: "center-button",
                  attrs: { to: { name: "root" } }
                })
              ]
            : [
                _vm.footerButtons.centerView
                  ? [_vm._t("centerView")]
                  : [
                      _vm.footerButtons.left
                        ? [
                            _vm.footerButtons.left.link
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "left-button",
                                    class: {
                                      active: _vm.footerButtons.left.active,
                                      inactive:
                                        _vm.footerButtons.left.active == false
                                    },
                                    attrs: {
                                      to: { name: _vm.footerButtons.left.link }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.footerButtons.left.title))]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass: "left-button",
                                    class: {
                                      active: _vm.footerButtons.left.active,
                                      inactive:
                                        _vm.footerButtons.left.active == false
                                    },
                                    attrs: { href: "#" },
                                    on: { click: _vm.leftButtonClick }
                                  },
                                  [_vm._v(_vm._s(_vm.footerButtons.left.title))]
                                )
                          ]
                        : _vm._e(),
                      _vm.footerButtons.right
                        ? _c(
                            "a",
                            {
                              staticClass: "right-button",
                              class: {
                                active: _vm.footerButtons.right.active,
                                inactive:
                                  _vm.footerButtons.right.active == false
                              },
                              attrs: { href: "#" },
                              on: { click: _vm.rightButtonClick }
                            },
                            [_vm._v(_vm._s(_vm.footerButtons.right.title))]
                          )
                        : _vm._e(),
                      _vm.showFooterCenterButton
                        ? _c("a", {
                            staticClass: "center-button",
                            class:
                              !_vm.isGuest &&
                              _vm.footerButtons &&
                              _vm.footerButtons.center &&
                              _vm.footerButtons.center.type
                                ? _vm.footerButtons.center.type
                                : "",
                            attrs: { href: "#" },
                            on: { click: _vm.centerButtonClick }
                          })
                        : _vm._e()
                    ]
              ]
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }