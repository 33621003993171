var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: { title: "Anmelden", "sub-title": "Pendelkinder" },
      on: { submit: _vm.onSubmit }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v("Hallo liebe, lieber " + _vm._s(_vm.name))]),
        _c("p", [
          _vm._v(
            "Um die Pendelkinder-App im vollem Umfang zu verwenden, benötigen wir von dir noch ein paar Angaben. Bist du bereit, dann klicke auf den blauen Button, um zu starten."
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }