var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.images.length
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "carousel",
                  { attrs: { "per-page": 1, loop: _vm.loop } },
                  _vm._l(_vm.images, function(image, index) {
                    return _c(
                      "slide",
                      {
                        key: image.id,
                        on: {
                          slideclick: function($event) {
                            return _vm.showImage(image)
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "upload",
                          attrs: { src: image.uploadUrl }
                        })
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.documents.length
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "ul",
                _vm._l(_vm.documents, function(document) {
                  return _c("li", { key: document.id }, [
                    _c(
                      "a",
                      { attrs: { href: document.uploadUrl, target: "_blank" } },
                      [_vm._v(_vm._s(document.filename))]
                    )
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _c("fullscreen-image", {
        model: {
          value: _vm.fullscreenImage,
          callback: function($$v) {
            _vm.fullscreenImage = $$v
          },
          expression: "fullscreenImage"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }