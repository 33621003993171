import fetchApi from "../lib/fetch_api.js"
import { unauthorizedHandler } from "../lib/error_handler"
import _ from "lodash/string"

const buildUploadParams = function(attrs) {
  let headers
  let formData
  if (attrs.files) {
    formData = new FormData()
    Object.keys(attrs).forEach(key => {
      const value = attrs[key]
      const railsKey = _.snakeCase(key)
      if (key === "files") {
        for (let i = 0, n = value.length; i < n; i++) {
          formData.append(`upload[${railsKey}][]`, value[i])
        }
        return
      }

      if (attrs[key]) {
        formData.append(`upload[${railsKey}]`, attrs[key])
      }
    })

    headers = {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json"
    }
  }

  return {
    data: formData || attrs,
    headers
  }
}

export default {
  state: {
    uploads: {}
  },
  mutations: {
    setUploadsArray(state, uploads) {
      state.uploads = uploads
    },
    setUploadAttrs(state, upload) {
      const _upload = state.uploads[upload.id] || {}
      state.uploads = {
        ...state.uploads,
        [upload.id]: { ..._upload, ...upload }
      }
    },
    addUpload(state, upload) {
      state.uploads = { ...state.uploads, [upload.id]: upload }
    },
    removeUpload(state, upload) {
      let uploads = state.uploads
      delete uploads[upload.id]
      state.uploads = { ...state.uploads }
    },
    clearSession(state) {
      state.uploads = {}
    }
  },
  actions: {
    async updateUpload({ commit }, attrs) {
      commit("setUploadAttrs", attrs) // Update store and UI immediately for responsiveness

      const { data, headers } = buildUploadParams(attrs)

      const response = await fetchApi(`/api/uploads/${attrs.id}`, {
        method: "PUT",
        data,
        headers
      })
      const { error, ...upload } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setUploadAttrs", upload) // Update store and UI with potential changes from server
      }
    },
    async loadUploads({ commit, dispatch }) {
      try {
        const uploads = await dispatch("GET", { path: "/api/uploads" })
        // const response = await fetchApi('/api/uploads', { method: 'GET' })
        // const { error, ...uploads } = await response.json()
        Object.values(uploads).forEach(upload => commit("addUpload", upload))
      } catch (error) {
        console.log("error", error)
        unauthorizedHandler.ignore({ statusCode: 404 }).handle(error)
      }
    },
    async createUpload({ commit }, attrs) {
      const { data, headers } = buildUploadParams(attrs)

      const response = await fetchApi("/api/uploads", {
        method: "POST",
        data,
        headers
      })
      const { error, ...upload } = await response.json()

      if (!error) {
        commit("addUpload", upload)
      }
    },
    async removeUpload({ commit }, attrs) {
      const response = await fetchApi(`/api/uploads/${attrs.id}`, {
        method: "DELETE"
      })
      const { error, ...upload } = await response.json()

      if (!error) {
        commit("removeUpload", upload)
      }
    }
  }
}
