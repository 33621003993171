var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.message.upload
      ? _c("div", { staticClass: "message", class: _vm.listClass }, [
          _c("img", {
            attrs: { src: _vm.message.upload },
            on: { click: _vm.showImage }
          }),
          !_vm.hasBody
            ? _c("div", { staticClass: "date" }, [
                _vm._v(_vm._s(_vm.createdAt))
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.hasBody
      ? _c("div", { staticClass: "message", class: _vm.listClass }, [
          _c("div", { staticClass: "bubble" }, [
            _c(
              "div",
              [
                _c(
                  "vue-markdown",
                  { key: _vm.message.id, attrs: { emoji: false } },
                  [_vm._v(_vm._s(_vm.message.body))]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.createdAt))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }