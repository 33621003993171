var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-height", attrs: { id: "app" } },
    [
      _vm.isLoading
        ? [
            _c("transition", { attrs: { name: "fade" } }),
            _c("loading-animation")
          ]
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view", { staticClass: "full-height" })],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }