import fetchApi from "../lib/fetch_api.js"

export default {
  state: {
    isLoading: false,
    messages: []
  },
  mutations: {
    loadMessages(state, messages) {
      state.isLoading = false
      state.messages = messages
    },
    addMessage(state, message) {
      if (state.messages.find(msg => msg.id === message.id)) {
        return
      }
      state.messages.push(message)
    },
    clearSession(state) {
      state.messages = []
    }
  },
  actions: {
    async loadMessages({ commit, state }) {
      state.isLoading = true
      const response = await fetchApi("/api/messages", { method: "GET" })
      const { messages } = await response.json()

      commit("loadMessages", messages || [])
    },
    async markMessagesAsRead({ dispatch }) {
      await fetchApi("/api/messages?mark_as_read=true", { method: "GET" })
      dispatch("refreshUser")
    },
    addMessage({ commit }, message) {
      commit("addMessage", message)
    }
  }
}
