// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./icon-call.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call[data-v-7fb745e6]{display:block;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-position:100% 0%;background-repeat:no-repeat;background-size:22px}`, "",{"version":3,"sources":["webpack://./app/javascript/components/contacts/contact_field.vue"],"names":[],"mappings":"AA2CA,uBACE,aAAA,CACA,kDAAA,CACA,2BAAA,CACA,2BAAA,CACA,oBAAA","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.call {\n  display: block;\n  background: url(./icon-call.png);\n  background-position: 100% 0%;\n  background-repeat: no-repeat;\n  background-size: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
