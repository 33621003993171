var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "steps" },
    _vm._l(_vm.onboardingChildRoutes, function(onboardingRoute) {
      return _c("button", {
        key: onboardingRoute.name,
        class: { active: _vm.$route.name == onboardingRoute.name }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }