<template lang="pug">
app-container(
  title="Anmelden"
  sub-title="Pendelkinder"
  :footer-buttons="footerButtons"
  :show-navigation-bar-button="false"
  @goToOnboarding="goToOnboarding"
)
  .app-onboarding
    .app-onboarding-start.uk-text-center
      h4.title Hallo {{ user.name }}
      p Wir freuen uns sehr, dass du dich bei der Pendelkinder-App angemeldet hast.
      p Wir möchten nun die Einstellungen für diese App gemeinsam mit dir vornehmen und dir dabei die Bedeutung der Einstellungsmöglichkeiten erläutern.
      p
        strong
          | Klicke auf den blauen Button,
          br
          | wenn du bereit dafür bist.
</template>

<script>
import AppContainer from "../app_container.vue"
import { mapState } from "vuex"

export default {
  components: { AppContainer },
  computed: {
    ...mapState({
      user: ({ user }) => user.user
    }),
    footerButtons() {
      return {
        center: {
          type: "active",
          action: "goToOnboarding"
        }
      }
    }
  },
  methods: {
    goToOnboarding() {
      this.$router.push({ name: "app_onboarding" })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

:deep(h4) {
  font-size: 1.1rem;
  font-weight: bold;

  &.title {
    color: $turkies-color;
  }
}
</style>
