var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.value && _vm.value.src,
          expression: "value && value.src"
        }
      ],
      ref: "uploadForm"
    },
    [
      _c("input", {
        ref: "uploadField",
        attrs: { name: "image", type: "file", accept: "image/*" },
        on: { change: _vm.pinImage }
      }),
      _c("div", { staticClass: "preview" }, [
        _c("img", { attrs: { src: _vm.value.src } }),
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.value.name))]),
        _c(
          "span",
          [_c("icon", { attrs: { icon: "close" }, on: { click: _vm.remove } })],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }