<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "m"
    },
    tag: {
      type: String,
      default: "i"
    }
  },
  render(h) {
    return h(this.tag, {
      on: this.$listeners,
      attrs: {
        "uk-icon": `icon: ${this.icon};ratio: ${
          { s: 0.5, m: 1, l: 1.5, xxl: 10 }[this.size]
        }`
      }
    })
  }
}
</script>
