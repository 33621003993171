var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        fetching: _vm.submitting,
        footerButtons: _vm.footerButtons
      },
      on: { submit: _vm.onSubmit }
    },
    [
      _c(
        "form",
        {
          staticClass: "signupForm",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("input-group", {
            attrs: { name: "email", type: "email", label: "E-Mail Adresse" },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "email"
            }
          }),
          _c("input-group", {
            staticClass: "password",
            attrs: { name: "password", type: "password", label: "Passwort" },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "password"
            }
          }),
          _c(
            "p",
            { staticClass: "uk-text-center uk-margin-top" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "new_user_password",
                      params: { givenEmail: _vm.email }
                    }
                  }
                },
                [_vm._v("Passwort vergessen?")]
              )
            ],
            1
          ),
          _c(
            "button",
            { staticClass: "invisible", attrs: { disabled: !_vm.submittable } },
            [_vm._v("Einloggen")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }