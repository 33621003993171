<template lang="pug">
  form(ref="uploadForm" v-show="value && value.src")
    input(name="image" ref="uploadField" type="file" accept="image/*" @change="pinImage")
    .preview
      img(:src="value.src")
      span.title {{ value.name }}
      span
        icon(icon="close" @click="remove")
</template>

<script>
import Icon from "../helpers/icon.vue"

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.$on("selectFile", this.selectFile)
  },
  beforeDestroy() {
    this.$off("selectFile", this.selectFile)
  },
  methods: {
    selectFile(event) {
      this.$refs.uploadField.click(event)
    },
    pinImage(event) {
      if (!event.target || !event.target.files.length) {
        return
      }
      const file = event.target.files[0]
      const previewImage = {
        src: URL.createObjectURL(file),
        name: file.name,
        file: file
      }
      this.$emit("input", previewImage)
      this.$refs.uploadForm.reset()
    },
    remove() {
      this.$emit("input", {})
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  display: none;
}

.preview {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  span {
    padding-left: 15px;

    &.title {
      flex-grow: 1;
    }
  }
}
</style>
