var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        fetching: _vm.submitting
      },
      on: { submit: _vm.onSubmit }
    },
    [
      _vm.submitting
        ? _c(
            "div",
            [
              _c("vue-markdown", {
                staticClass: "text-center text-small mt-2",
                attrs: { source: _vm.description }
              })
            ],
            1
          )
        : _c("div", [
            _c("p", { staticClass: "text-center" }, [
              _c("strong", [_vm._v(_vm._s(_vm.description))])
            ]),
            _vm.buttonTitle
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("router-link", { attrs: { to: { name: "root" } } }, [
                      _vm._v(_vm._s(_vm.buttonTitle))
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }