var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plan" }, [
    _c("div", { staticClass: "header" }, [
      !_vm.isDeletePlan
        ? _c("div", {
            staticClass: "switch",
            class: { active: _vm.plan.active }
          })
        : _vm._e(),
      _c("div", { staticClass: "titles" }, [
        _c("h1", [_vm._v(_vm._s(_vm.plan.group))]),
        _c(
          "h2",
          [
            _c("vue-markdown", { key: _vm.titleKey }, [
              _vm._v(_vm._s(_vm.plan.title))
            ])
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "description" },
      [
        _c("vue-markdown", { key: _vm.plan.key }, [
          _vm._v(_vm._s(_vm.plan.text))
        ])
      ],
      1
    ),
    _vm.isBookable
      ? _c("div", { staticClass: "big-input-group" }, [
          _c("button", { on: { click: _vm.purchase } }, [_vm._v("Kaufen")])
        ])
      : _vm.isDeletePlan
      ? _c(
          "div",
          { staticClass: "big-input-group" },
          [
            !_vm.user.markedAsDeletedAt
              ? _c("delete-account-modal", { staticClass: "delete-account" })
              : _c("button", { on: { click: _vm.cancelDeletion } }, [
                  _vm._v("Löschen abbrechen")
                ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }