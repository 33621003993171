/* eslint no-console: 0 */
// Expects https://github.com/fnando/i18n-js to be installed and I18n to be defined globally.
/* eslint no-undef: 0 */

import Vue from "vue"
import VueRouter from "vue-router"
import App from "../app.vue"
import VueResource from "vue-resource"
import UIkit from "uikit"
import Vuikit from "vuikit"
import Icons from "uikit/dist/js/uikit-icons"
import VueTextareaAutosize from "vue-textarea-autosize"
import common from "../mixins/common"
import i18n from "../mixins/i18n"
import "./app.scss"

I18n.locale = document.querySelector("html").getAttribute("lang") || "de"

Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(Vuikit)
Vue.use(VueTextareaAutosize)
UIkit.use(Icons)

Vue.mixin(common)
Vue.mixin(i18n)

document.addEventListener("DOMContentLoaded", () => {
  // if (['staging', 'production'].includes(environment())) {
  //   window.onerror = appsignalErrorHandler
  //   Vue.config.errorHandler = (error) =>
  //     appsignalErrorHandler(error.message, 'vue-error', 0, 0, error)
  // }

  document.body.appendChild(document.createElement("vueapp"))
  window.app = new Vue({
    render: h => h(App)
  }).$mount("vueapp")
})
