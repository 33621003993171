class ErrorHandler {
  constructor(ignoredStatusCodes = []) {
    this.ignoredStatusCodes = ignoredStatusCodes
  }

  ignore({ statusCode }) {
    return new ErrorHandler([...this.ignoredStatusCodes, statusCode])
  }

  handle(error) {
    if (this.ignoredStatusCodes.includes(error.statusCode)) {
      return
    }

    throw error
  }
}

const errorHandler = new ErrorHandler()
export default errorHandler

// User is not signed in:
export const unauthorizedHandler = errorHandler.ignore({ statusCode: 401 })
