<template lang="pug">
a.nav-button(@click="toggleNavigation")
  .badge-icon(v-show="unreadCount > 0") {{ unreadCount }}
  transition(name="fade")
    .bg-view(v-if="navigationVisible")
  transition(name="fade")
    ul.menu(v-if="navigationVisible")
      li
        router-link(:to="{ name: 'calendar' }")
          | Kalender
          .column
      li
        router-link(:to="{ name: 'messenger' }")
          | Messenger
          .column(:class="{ notification: unreadMessagesCount > 0 }")
            .badge-icon {{ unreadMessagesCount }}
      li
        router-link(:to="{ name: 'todos' }")
          | Aufgaben
          .column
      li
        router-link(:to="{ name: 'contacts' }")
          | Kontakte
          .column
      li
        router-link(:to="{ name: 'uploads' }")
          | Handzettel
          .column
      li
        router-link(:to="{ name: 'schedule' }")
          | Stundenplan
          .column
      li
        router-link(:to="{ name: 'news' }")
          | Aktivitäten
          .column(:class="{ notification: unreadNewsCount > 0 }")
            .badge-icon {{ unreadNewsCount }}
      li
        router-link(:to="{ name: 'settings' }")
          | Einstellungen
          .column
      li
        router-link(:to="{ name: 'page', params: { permalink: 'help' } }")
          | Hilfe
          .column
      li
        a(href="#" @click.prevent="logout")
          | Logout
          .column
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      navigationVisible: false
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user
    }),
    unreadCount() {
      return this.user.unreadMessagesCount + this.user.unreadNewsCount
    },
    unreadMessagesCount() {
      return this.user.unreadMessagesCount
    },
    unreadNewsCount() {
      return this.user.unreadNewsCount
    }
  },
  methods: {
    toggleNavigation() {
      this.navigationVisible = !this.navigationVisible
    },
    logout() {
      this.$emit("onLogout")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.bg-view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
}

.badge-icon {
  position: absolute;
  display: inline-block;
  background: $turkies-color;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  color: white;
  text-align: center;
  font-size: 10px;
  vertical-align: top;
  line-height: 14px;
  margin-left: 18px;
  margin-top: -1px;
}

.menu li a {
  white-space: nowrap;

  .column {
    display: inline-block;
    width: 20px;
    height: 20px;
    visibility: hidden;

    &.notification {
      visibility: visible;
      background-image: url("./bell.png");
      background-size: cover;
      background-position: 50%;
      text-align: center;

      .badge-icon {
        position: static;
        background: $dove-gray-color;
        margin-left: 4px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
