import moment from "moment"
moment.locale("de")

export default {
  computed: {
    staticMaxHours() {
      return 13
    },
    weekdays() {
      return ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"]
    }
  },
  methods: {
    async fetchTimetable() {
      this.fetching = true
      const response = await this.$http.get(
        `/api/children/${this.currentChild.id}/schedules`
      )

      let maxHours = 5
      let entries = {}
      if (response.ok) {
        response.body.schedule.forEach(entry => {
          const key = `${entry.weekday}-${entry.hour}`
          if (entry.hour + 1 > maxHours && entry.title) {
            maxHours = entry.hour + 1
          }
          entries[key] = {
            id: entry.id,
            value: entry.title
          }
        })

        let childHours = {}
        response.body.hours.forEach(hourObject => {
          let hours = []
          for (let i = 0; i < this.staticMaxHours; i++) {
            const hour = hourObject.hours[i]
            if (hour) {
              hours.push({
                start_on: hour.start_on
                  ? moment(hour.start_on).format("HH:mm")
                  : null,
                end_on: hour.end_on ? moment(hour.end_on).format("HH:mm") : null
              })
            } else {
              hours.push({
                start_on: null,
                end_on: null
              })
            }
          }

          childHours[hourObject.child_id] = hours
        })

        this.maxHours = maxHours
        this.timetable = entries
        this.hours = childHours
      }

      this.fetching = false
    },
    firstOn(field, hour, entries) {
      for (let i = 0, n = this.weekdays.length; i < n; i++) {
        const entry = entries[`${i}-${hour}`]
        if (entry && entry[field]) {
          return entry[field]
        }
      }
    },
    firstStartOn(hour, entries) {
      return this.firstOn("startOn", hour, entries)
    },
    firstEndOn(hour, entries) {
      return this.firstOn("endOn", hour, entries)
    }
  }
}
