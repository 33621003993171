<template>
  <vue-markdown>
    ### Allgemein

    1. Pendelkinder, Pendelkinder.de und die Pendelkinder-App (nachfolgend „Pendelkinder“) bezeichnet die gegenständlichen Dienste der Pendelkinder SAV GmbH, Ricarda-Huch-Str. 12, 61350 Bad Homburg. Weitere Informationen über Pendelkinder finden Sie im Impres- sum. Mit der aktiven Bestätigung bzw. der Registrierung als Nutzer bei Pendelkinder akzeptieren Sie diese Allgemeinen Geschäftsbedingungen (AGB) für die Nutzung von Pendelkinder.
    2. Pendelkinder ist ein Organisationstool zum Verwalten von Terminen und wichtigen Informationen rund um die gemeinsamen Kinder von getrennt lebenden Eltern. Mit der aktiven Bestätigung bzw. der Registrierung als Nutzer bei Pendelkinder werden diese All- gemeinen Geschäftsbedingungen (AGB) für die Nutzung von Pendelkinder akzeptiert.

    ### Zugang zur Pendelkinder-App

    1. Um Pendelkinder nutzen zu können muss zuerst ein Account über das Registrierungsformular in der App ausgefüllt werden. Hierbei werden Vorname, ein Kürzel sowie die E-Mail-Adresse erfasst. Zusätzlich wird ein persönliches Passwort vom Nutzer festgelegt.
    2. Ein bindender Vertrag kommt für beide Vertragspartner erst dann zustande, wenn der Nutzer per Klick des vorgesehenen Buttons die Registrierung vollzieht, die Allgemeinen Geschäftsbedingungen sowie die Datenschutzerklärung anerkennt und den Account durch Klicken des Links in der Aktivierungsmail aktiviert.
    4. Nach erfolgter Bestätigung hat der Nutzer das Recht zur Nutzung der Leistungen im zwischen den Parteien vereinbarten Umfang. Der Nutzer hat die ihm zugewiesene Benutzerkennung sowie das Passwort vor dem Zugriff durch Dritte geschützt aufzubewahren.
    5. Bedingung für die Registrierung ist die Volljährigkeit des Nutzers.

    ### Testphase

    1. Die Dienste von Pendelkinder können während einer Testphase kostenlos getestet werden. Nach dem Ablauf der Testphase, hat der Nutzer die Möglichkeit die App durch Zahlung einer einmaligen Gebühr zu erwerben. Die Kostenpflichtigkeit dieses Dienstes ist explizit angegeben.
    2. Die Voraussetzungen für die Nutzung dieser Testphase sowie Angaben zum Zeitraum und Umfang sind auf der Website und in den App-Stores (Google Play / iTunes) angegeben. Im Testzeitraum stehen Ihnen alle Funktionen der App kostenlos zur Verfügung.

    ### Kauf der App – Lizenzumfang

    1. Damit Sie die Leistungen von Pendelkinder in vollem Umfang nach Ablauf des Testzeitraums nutzen können, können Sie nach Beendigung der Testphase eine kostenpflichtige Lizenz der Vollversion der Pendelkinder App erwerben.
    2. Pendelkinder räumt dem Lizenznehmer ein nicht-exklusives, nicht unterlizenzierbares, nicht übertragbares Recht ein, die Anwendung auf einem in seinem Eigentum stehenden oder rechtmäßig seiner Verfügungsbefugnis unterstehenden von der Anwendung unterstützten mobilen Endgerät zu installieren und zu nutzen.
    2. Diese Lizenz findet auch Anwendung auf potentielle Updates, die Pendelkinder als zusätzliches oder ersetzendes Angebot zur Anwendung einstellt, soweit das Update nicht einer eigenständigen Lizenzvereinbarung unterliegt.
    3. Es ist dem Lizenznehmer nicht gestattet, die Anwendung zu verbreiten, Dritten zugänglich zu machen (außer in dem Umfang, der durch die Apple Nutzungsbedingungen erlaubt ist), sie zu verleihen, zu vermieten, zu verpachten, zu verkaufen oder auf sonstige Weise weiterzuverbreiten.
    4. Pendelkinder behält sich das Recht vor, diese Lizenzbedingungen zu ändern.
    5. Sie können den Erwerb über unsere iOS- oder Android-App mit einem App-Store- Betreiber abschließen. Unter dem Punkt „Vollversion“ in der Pendelkinder-App ist der Preis für den Lizenzerwerb angegeben. Zum Abschluss des Kaufs müssen Sie auf dem Vollversion-Screen den Button "kaufen" klicken, woraufhin ein Popup erscheint, in dem Sie Ihr iTunes- bzw. Google-Play-Kennwort eingeben müssen. Sobald Sie das getan haben, erscheint ein Popup, in dem Sie den Kauf noch einmal bestätigen müssen. An dieser Stelle können Sie den Vorgang auch noch abbrechen. Bestätigen Sie den Kauf, führt der App-Store ihre Bestellung aus und Sie erhalten ihre Pendelkinder-Lizenz für die weitere Nutzung der App.

    ### Netzwerk-Code

    1. Jeder Lizenznehmer erhält einen Netzwerk-Code. Dieser Netzwerk-Code dient zur Weitergabe an Dritte und erteilt eine Leseberechtigung, mit der man alle Informationen einsehen, jedoch keine Daten einpflegen, ändern oder löschen kann.
    2. Das Recht der Weitergabe des Netzwerk-Codes liegt beim Lizenznehmer. Der Nutzer verpflichtet sich, sorgsam und mit bedacht diesen zu verteilen. Pendelkinder kann nicht für ein unangemessenes Verteilen des Codes belangt werden.

    ### Account Löschen

    1. Löschen des Accounts durch den Nutzer ist jederzeit möglich. Dazu gehen Sie im Menüpunkt „Einstellung“ auf „Account löschen“. Ihr Account wird innerhalb von 24 Stunden gelöscht.
    2. Ist der Nutzer mit einem anderen Elternteil in der App verbunden, werden all die Daten gelöscht, die vom gekündigten Account getätigt wurden. Einzig der Name der eingetragenen Kinder bleibt bestehen. Pendelkinder kann hierfür vom verbundenen Accountinhaber nicht belangt werden.
    Rechte & Pflichten der Nutzer
    1. Der Nutzer von Pendelkinder ist für die Richtigkeit seiner im Rahmen der Anmeldung und der Nutzung der App bereitgestellten Angaben/Informationen allein verantwortlich.
    2. Der Nutzer versichert, dass er mit der Nutzung von Pendelkinder keine gewerblichen und/oder geschäftlichen Absichten verfolgt. Er verpflichtet sich, Pendelkinder nicht gewerblich oder geschäftlich zu nutzen.
    3. Dem Nutzer sind die folgenden Verhaltensweisen untersagt: - Widerrechtliche Verwendung Daten Dritter;
        - Bedrohung/Belästigung Dritter;
        - Persönlichkeitsrechtsverletzungen gegenüber Dritten;
        - Einstellen von pornografischen Inhalten oder Nacktdarstellungen; - Verwendung mit Virus befallender Software;
    4. Der Nutzer ist verpflichtet, Pendelkinder von jeglicher Haftung und von allen Ansprüchen Dritter freizustellen, die sich aus unzulässigen Verhaltensweisen des Nutzers ergeben – bspw. Verletzung von Persönlichkeitsrechten.
    5. Pendelkinder ist berechtigt, im Falle des begründeten hinreichenden Verdachts einer Rechtsverletzung und des Verstoßes gegen die den Nutzer treffenden Vertragspflichten, Inhalte, die der Nutzer in das Portal gestellt hat, zu sperren / zu löschen. Diese Maßnahme setzt grundsätzlich eine vorhergehende Abmahnung des Nutzers durch Pendelkinder voraus. Bei erheblichen schweren Pflichtverletzungen ist eine Abmahnung entbehrlich. Sperrungen/Löschungen, die auf eine gerichtliche oder behördliche Entscheidung gestützt werden können, sind jederzeit auch ohne vorhergehende Abmahnung zulässig.
    6. Es ist dem Nutzer nicht gestattet, über Pendelkinder Inhalte einzustellen bzw. darüber zu verbreiten, die rechtswidrig, irreführend, bösartig, diskriminierend, pornographisch, bedrohlich, beleidigend, obszön, diffamierend, ethisch anstößig, gewaltverherrlichend, belästigend, für Minderjährige ungeeignet, rassistisch, volksverhetzend, ausländerfeindlich oder sonst verabscheuungswürdig und/ oder verwerflich sind sowie frei von Viren, Wür- mern, Trojanern oder anderen bösartigen Schadcodes, die die Funktionsfähigkeit der App gefährden oder beeinträchtigen können.

    ### Nutzungsbestimmungen

    Der Nutzer erwirbt mit seiner Registrierung ein persönliches, nicht übertragbares Recht zur Nutzung der Anwendung. Er ist verpflichtet, Benutzerkennung und Passwort, die zur Nutzung der Anwendung erforderlich sind, geheim zu halten und Dritten gegenüber nicht zugänglich zu machen.

    Soweit dem Nutzer mit seiner Registrierung und/oder mit Vertragsschluss das Recht eingeräumt wird, eigene Inhalte (bspw. Wörter, Zeichen, Bilder, etc.) zur Nutzung der Dienstleistungen in der Anwendung zu hinterlegen, verpflichtet er sich, dass solche Inhalte weder gegen geltendes Recht noch gegen Vereinbarungen mit Dritten oder Lizenz- und Urheberrechte Dritter verstoßen.

    ### Widerrufsrecht

    1. Wenn Nutzer Verbraucher im Sinne des § 13 BGB sind, gilt für Sie das nachfolgende Widerrufsrecht.
    2. Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlus- ses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Pendelkinder SAV GmbH, Ricarda-Huch-Str. 12, 61350 Bad Homburg, E-Mail: hello@pendelkinder.de mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Ent- schluss, diesen Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    3. Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebo- tene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Das Widerrufsrecht erlischt bei einem Ver- trag zur Erbringung von Dienstleistungen, wenn der Unternehmer die Dienstleistung voll- ständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nach- dem der Verbraucher dazu seine ausdrückliche Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass er sein Widerrufsrecht bei vollständiger Vertrags- erfüllung durch den Unternehmer verliert.

    ### Datenschutz

    Einzelheiten zum Schutz und der Nutzung der durch den Nutzer zur Verfügung gestellten Daten durch Pendelkinder können unserer Datenschutzerklärung entnommen werden. Die Datenschutzerklärung bildet ein Bestandteil dieser AGB.

    ### Haftung

    Es gelten die gesetzlichen Regelungen, soweit nachstehend nichts Abweichendes vereinbart ist.
    1. Pendelkinder haftet dem Grunde nach ausschließlich für Schäden, die Pendelkinder, deren gesetzliche Vertreter und/oder deren Erfüllungsgehilfen vorsätzlich oder grob fahrlässig herbeigeführt haben.
    2. Des weiteren haftet Pendelkinder dem Grunde nach für Schäden der Nutzer aus einer Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer Pflichtverletzung von Pendelkinder, deren gesetzlichen Vertretern und/oder deren Erfüllungsgehilfen beruhen.
    3. Ferner besteht eine Haftung dem Grunde nach für Schäden, die durch die Verletzung einer Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut und vertrauen darf (Kardinalpflicht), entstanden sind.
    4. Pendelkinder haftet nicht dafür, dass die übermittelten Nachrichten und Informationen richtig und vollständig sind und den Nutzer rechtzeitig erreichen.
    5. Pendelkinder garantiert keine ununterbrochene Erreichbarkeit der Angebote. Pendelkinder übernimmt keine Haftung für den vorübergehender Ausfall der Angebote, der auf technischen Gründen oder sonstigen Gründen basiert, die nicht durch Pendelkinder zu vertreten ist (z.B. höhere Gewalt, Verschulden Dritter usw.). Auch sind kurzzeitige Ausfälle durch Wartungs- und Änderungsmaßnahmen möglich. Diese werden, soweit möglich, frühzeitig angekündigt.
    6. Stellt Pendelkinder seinen Dienst vorübergehend oder endgültig aus wichtigem Grunde ganz oder teilweise ein, so begründet dieser Umstand keine Haftung.
    7. Pendelkinder kann für die Richtigkeit der Angaben anderer Nutzer keine Verantwortung oder Gewähr übernehmen.
    8. Um Pendelkinder nutzen zu können, muss der Nutzer die erforderlichen Technologien auf seinem Browser oder Smartphone bereithalten und deren Nutzung ermöglichen. Eine lediglich eingeschränkte Nutzbarkeit bspw. bedingt durch die Verwendung älterer Technologien durch den Nutzer hat Pendelkinder nicht zu vertreten.
    9. Pendelkinder haftet nicht für Urheberrechtsverletzungen, die durch die Nutzer begangen werden.
    10. Die Webseite von Pendelkinder enthält Links zu externen Webseiten Dritter, auf deren Inhalte Pendelkinder keinen Einfluss hat. Deshalb übernimmt Pendelkinder für diese fremden Inhalte keine Haftung. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten – ohne konkrete Anhaltspunkte einer Rechtsverletzung – erfolgt nicht. Bei Bekanntwerden von Rechtsverletzungen wird Pendelkinder derartige Links umgehend entfernen.
    11. Pendelkinder übernimmt für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte der Webseite keine Gewähr. Pendelkinder ist nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen wird Pendelkinder diese Inhalte umgehend entfernen.
    12. Sind die Konten von zwei Elternteilen verbunden und ein Elternteil löscht seinen Account, so begründet die daraus resultierenden Umstände, wie in „Kündigung Punkt 3“ beschrieben, keine Haftung für Pendelkinder.

    ### Schlussbestimmungen

    Es gilt das Recht der Bundesrepublik Deutschland. Mündliche oder schriftliche Nebenabreden bestehen nicht. Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam sein oder werden, so bleibt der Vertrag und die übrigen Bestimmungen wirksam. Die unwirksame Bestimmung wird von den Vertragsparteien einvernehmlich durch eine solche Bestimmung ersetzt, welche dem wirtschaftlichen Zweck der unwirksamen Bestimmung in rechtswirksamer Weise am nächsten kommt. Die vorstehende Regelung gilt entsprechend bei Regelungslücken.
  </vue-markdown>
</template>

<script>
import VueMarkdown from "vue-markdown"
export default {
  components: {
    VueMarkdown
  }
}
</script>
