var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedValue,
            expression: "selectedValue"
          }
        ],
        class: _vm.classNames,
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.selectedValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      [
        _c(
          "option",
          { attrs: { disabled: "" }, domProps: { value: _vm.defaultValue } },
          [_vm._v(_vm._s(_vm.placeholder))]
        ),
        _vm._l(_vm.options, function(option) {
          return _c("option", { domProps: { value: option.id } }, [
            _vm._v(_vm._s(option.value))
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }