var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Kontakt",
        "sub-title": "Pendelkinder",
        footerButtons: _vm.footerButtons
      },
      on: {
        deleteContact: _vm.deleteContact,
        submit: _vm.saveContact,
        goBack: _vm.goBack
      }
    },
    [
      _c("div", { staticClass: "contact" }, [
        _vm.loaded
          ? _c("div", { staticClass: "strong-input-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.firstName,
                        expression: "firstName"
                      }
                    ],
                    attrs: { placeholder: "Vorname" },
                    domProps: { value: _vm.firstName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.firstName = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.lastName,
                        expression: "lastName"
                      }
                    ],
                    attrs: { placeholder: "Nachname" },
                    domProps: { value: _vm.lastName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.lastName = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.phone,
                        expression: "phone"
                      }
                    ],
                    attrs: { placeholder: "Telefonnummer" },
                    domProps: { value: _vm.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.phone = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mobile,
                        expression: "mobile"
                      }
                    ],
                    attrs: { placeholder: "Mobilnummer" },
                    domProps: { value: _vm.mobile },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.mobile = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      }
                    ],
                    attrs: { placeholder: "E-Mail", type: "email" },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.website,
                        expression: "website"
                      }
                    ],
                    attrs: { placeholder: "Website" },
                    domProps: { value: _vm.website },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.website = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.address,
                        expression: "address"
                      }
                    ],
                    attrs: { placeholder: "Adresse" },
                    domProps: { value: _vm.address },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.address = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.postalCode,
                        expression: "postalCode"
                      }
                    ],
                    attrs: { placeholder: "Postleitzahl" },
                    domProps: { value: _vm.postalCode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.postalCode = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.city,
                        expression: "city"
                      }
                    ],
                    attrs: { placeholder: "Stadt" },
                    domProps: { value: _vm.city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.city = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.notes,
                        expression: "notes"
                      }
                    ],
                    attrs: { placeholder: "Weitere Infos" },
                    domProps: { value: _vm.notes },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.notes = $event.target.value
                      }
                    }
                  })
                ])
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }