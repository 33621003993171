var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        staticClass: "expandable",
        attrs: { type: "text", placeholder: _vm.placeholder, readonly: "" },
        domProps: { value: _vm.values },
        on: { click: _vm.showModal }
      }),
      _c(
        "vk-modal",
        {
          staticClass: "uk-modal-full",
          attrs: { show: _vm.modalVisible },
          on: {
            "update:show": function($event) {
              _vm.modalVisible = $event
            }
          }
        },
        [
          _c("icon", {
            staticClass: "save",
            attrs: { icon: "check", size: "l", tag: "button" },
            on: { click: _vm.save }
          }),
          _c(
            "div",
            {
              staticClass: "uk-padding-large selection",
              attrs: { "uk-height-viewport": "" }
            },
            [
              _c(
                "p",
                [
                  !_vm.selectedOptions.length
                    ? [_vm._v(_vm._s(_vm.tip))]
                    : _vm._l(_vm.selectedOptions, function(option) {
                        return _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.removeOption(option)
                              }
                            }
                          },
                          [
                            _vm._v(_vm._s(option.value)),
                            _c("icon", { attrs: { icon: "close", size: "s" } })
                          ],
                          1
                        )
                      })
                ],
                2
              ),
              _c(
                "ul",
                _vm._l(_vm.unselectedOptions, function(option) {
                  return _c(
                    "li",
                    {
                      on: {
                        click: function($event) {
                          return _vm.selectOption(option)
                        }
                      }
                    },
                    [_vm._v(_vm._s(option.value))]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }