<template lang="pug">
  div
    input.input.strong-placeholder(readonly placeholder="Passwort" sub-label="Kein Leerzeichen und mindestens 6 Zeichen" autocomplete="new-password")
    .appended
      button(@click="wantEditPassword") ändern

    vk-modal(:show.sync="showPasswordConfirmation" overflow-auto)
      form(@submit="submit")
        vk-modal-close(@click="showPasswordConfirmation = false")
        vk-modal-title(slot="header") Passwort ändern

        p.strong-input-group
          input-field#password(v-model="newPassword" type="password" placeholder="Passwort")

        p Bitte bestätige das eingegebene Passwort noch einmal:
        p.strong-input-group
          input-field#confirm-password(v-model="passwordConfirmation" type="password" placeholder="Passwort bestätigen" :class="passwordConfirmationClass")

        p Zum Bestätigen gebe hier bitte dein aktuelles Passwort ein:
        p.strong-input-group
          input-field#current-password(v-model="currentPassword" type="password" placeholder="aktuelles Passwort")

        .uk-text-right(slot="footer")
          input.uk-button.uk-margin-small-right.uk-button-submit(type="submit" value="Passwort ändern")
</template>

<script>
import InputField from "../helpers/input_field.vue"
import { mapActions } from "vuex"

export default {
  components: {
    InputField
  },
  data() {
    return {
      showPasswordConfirmation: false,
      newPassword: "",
      passwordConfirmation: "",
      currentPassword: ""
    }
  },
  computed: {
    isPasswordValid() {
      if (!this.newPassword || !this.passwordConfirmation) {
        return false
      }
      return (
        this.newPassword === this.passwordConfirmation &&
        (this.newPassword.length > 5 && this.newPassword.length < 129)
      )
    },
    passwordConfirmationClass() {
      if (!this.passwordConfirmation || this.passwordConfirmation === "") {
        return
      }
      if (this.isPasswordValid) {
        return "ok"
      }
      return "error"
    }
  },
  methods: {
    ...mapActions(["updatePassword"]),
    wantEditPassword() {
      this.showPasswordConfirmation = true
    },
    async submit(event) {
      event.preventDefault()

      if (!this.isPasswordValid) {
        this.notify(
          "Bitte prüfe die Passwörter. Diese müssen übereinstimmen und zwischen 6 und 128 Zeichen lang sein.",
          "danger"
        )
        return
      }

      const form = {
        password: this.newPassword,
        passwordConfirmation: this.passwordConfirmation,
        currentPassword: this.currentPassword
      }

      this.$emit("startLoading")
      const result = await this.updatePassword(form)
      this.$emit("doneLoading")

      if (result) {
        this.showPasswordConfirmation = false
        this.newPassword = null
        this.passwordConfirmation = null
        this.currentPassword = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.strong-input-group {
  .ok :deep(input:focus) {
    border-color: green;
  }

  .error :deep(input:focus) {
    border-color: red;
  }

  :deep(input::placeholder) {
    color: $dark-gray-color;
    font-weight: normal;
  }
}

.uk-button {
  padding: 0 20px;
  line-height: 32px;
  font-size: 14px;
}
</style>
