var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    [
      _vm.entry
        ? [
            _vm.entry.value
              ? _c("span", [_vm._v(_vm._s(_vm.entry.value))])
              : _c("span", { staticClass: "no-entry" }),
            _vm.date && _vm.entry.value
              ? _c("div", [_vm._v(_vm._s(_vm.date) + " ")])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }