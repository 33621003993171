<template lang="pug">
app-container(title="Stundenplan" :sub-title="subTitle" :footerButtons="footerButtons" @submit="saveSchedule" @goBack="goBack")
  .schedule
    .day-select
      inline-select-field(:options="weekdayOptions" v-model="weekDay")

    table.list
      tbody
        tr(v-for="hour in staticMaxHours" v-if="values && values[hour-1]")
          td {{ hour }}.
          td.strong-input-group
            input(v-model="values[hour-1].title")
          td.strong-input-group
            input(v-model="hours[currentChild.id][hour-1].start_on" type="time" placeholder="von")
          td.strong-input-group
            input(v-model="hours[currentChild.id][hour-1].end_on" type="time" placeholder="bis")
</template>

<script>
import { mapState } from "vuex"
import AppContainer from "../app_container.vue"
import fetchApi from "../../lib/fetch_api.js"
import fetchSchedule from "./_fetch_schedule"
import InlineSelectField from "../helpers/inline_select_field.vue"

import moment from "moment"
moment.locale("de")

export default {
  components: { AppContainer, InlineSelectField },
  mixins: [fetchSchedule],
  data: () => ({
    weekDay: 0,
    timetable: {},
    hours: {},
    values: [],
    chachedValues: {}
  }),
  computed: {
    ...mapState({
      children: ({ children }) => children.children,
      todos: ({ todos }) => todos.todos
    }),
    footerButtons() {
      return {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }
    },
    subTitle() {
      return this.currentChild.name
    },
    currentDay() {
      return this.weekdays[this.weekDay]
    },
    currentChild() {
      return this.children[this.$route.params.childId]
    },
    weekdayOptions() {
      return this.weekdays.map((weekDay, i) => {
        return {
          title: weekDay,
          value: i
        }
      })
    }
  },
  watch: {
    weekDay() {
      this.updateValues(false)
    },
    timetable() {
      this.updateValues(true)
    }
  },
  created() {
    this.fetchTimetable()
  },
  methods: {
    async saveSchedule() {
      try {
        this.updateValues(false)
        const data = {
          values: this.chachedValues,
          hours: this.hours
        }
        const response = await fetchApi(
          `/api/children/${this.currentChild.id}/schedules`,
          { method: "POST", data }
        )
        const { error } = await response.json()

        if (!error) {
          this.$router.push({
            name: "schedule",
            params: { id: parseInt(this.$route.params.childId, 10) }
          })
        } else {
          this.notify("Eintrag konnte nicht gespeichert werden.", "danger")
        }
      } catch (e) {
        console.log(e)
        this.notify("Eintrag konnte nicht gespeichert werden.", "danger")
      }
    },
    updateValues(reset) {
      if (!this.chachedValues) {
        return
      }
      if (!reset && this.chachedValues[this.weekDay]) {
        this.values = this.chachedValues[this.weekDay]
        return
      }

      for (let wi = 0, n = this.weekdays.length; wi < n; wi++) {
        let values = []
        for (let i = 0; i < this.staticMaxHours; i++) {
          const entry = this.timetable[`${wi}-${i}`]
          if (entry) {
            values.push({
              title: entry.value
            })
          } else {
            values.push({
              title: ""
            })
          }
        }
        this.chachedValues[wi] = values
      }

      this.values = this.chachedValues[this.weekDay]
    },
    async deleteTodo() {
      if (confirm("Soll der Eintrag wirklich gelöscht werden?")) {
        await this.$store.dispatch("removeTodo", this.todo)
        this.$router.go(-1)
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.schedule {
  margin: 0 -15px;
}
</style>
