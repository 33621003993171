<template lang="pug">
app-container(title="Anmelden" sub-title="Pendelkinder" :fetching="submitting" :footerButtons="footerButtons" @submit="onSubmit")
  form.signupForm(@submit.prevent="onSubmit")
    input-group(name="email" type="email" label="E-Mail Adresse" v-model.trim="email")
    input-group.password(name="password" type="password" label="Passwort" v-model.trim="password")

    p.uk-text-center.uk-margin-top
      router-link(:to="{ name: 'new_user_password', params: { givenEmail: email } }") Passwort vergessen?
    button.invisible(:disabled="!submittable") Einloggen
</template>

<script>
import AppContainer from "../app_container.vue"
import InputGroup from "../helpers/input_group.vue"

export default {
  components: { AppContainer, InputGroup },
  data: () => ({
    email: "",
    password: "",
    submitting: false,
    submittable: true,
    footerButtons: {
      left: {
        title: "Login",
        action: "",
        active: true
      },
      right: {
        title: "Anmelden",
        link: "new_user_registration",
        active: false
      }
    }
  }),
  methods: {
    async onSubmit() {
      this.submitting = true

      const { email, password } = this
      try {
        await this.$store.dispatch("signIn", {
          email,
          password,
          rememberMe: true
        })

        this.$router.push({ name: "app_start" })
      } catch (e) {
        this.notify(
          "Dein E-Mail oder Passwort ist falsch. Bitte versuche es noch einmal.",
          "danger"
        )
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
.signupForm {
  margin-top: 40px;
}
.input-group {
  margin-bottom: 30px;
}
:deep .password input {
  text-transform: none !important;
}
</style>
