import { mapState } from "vuex"

export default {
  computed: {
    ...mapState({
      accountMembers: ({ account }) => {
        console.log("members", account.accountMembers)
        return (account.accountMembers || []).filter(member => !`${member.id}`.startsWith("invite"))
      }
    }),

    onboardingChildRoutes() {
      const accountMemberCount = this.accountMembers.length
      return this.$router.options.routes
        .find(route => route.meta && route.meta.isAppOnboarding)
        .children.filter(childRoute => accountMemberCount < 2 || !childRoute.meta.skipForParents)
    },
    currentOnboardingRouteIndex() {
      return this.onboardingChildRoutes.findIndex(route => route.name === this.$route.name)
    },
    previousOnboardingRoute() {
      const index = this.currentOnboardingRouteIndex
      if (index <= 0) {
        return
      }
      return this.onboardingChildRoutes[index-1]
    },
    nextOnboardingRoute() {
      const index = this.currentOnboardingRouteIndex
      if (index < 0) {
        return
      }
      return this.onboardingChildRoutes[index+1]
    }
  }
}
