var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", [
    _c(
      "li",
      {
        class: _vm.role,
        on: {
          click: function($event) {
            return _vm.editTodo(_vm.todo)
          }
        }
      },
      [_vm._v(_vm._s(_vm.title))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }