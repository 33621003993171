<template lang="pug">
  app-container(title="Passwort" sub-title="Pendelkinder" :footer-buttons="footerButtons" @submit="submit" @goBack="goBack")
    form.uk-text-center(@submit.prevent="submit" action="#")
      h1 Passwort zurücksetzen
      div(v-if="state == 'invalidToken'")
        p Das Passwort kann nicht geändert werden. Bitte fordere eine neue Anfrage an:
      div(v-else)
        p Bitte gib ein neues Passwort an.
        p(:class="{ error: state === 'error' }")
          input.uk-input(type="password" v-model.trim="password" @change="resetError" placeholder="Passwort")
          div(v-if="state === 'error'") Passwort darf keine Leerzeichen enthalten und muss mindestens 6 Zeichen lang sein.
      div
        button.uk-button(type="submit" role="submit") Weiter
        p
          router-link(:to="{ name: 'new_user_session' }") Login
</template>

<script>
import AppContainer from "../app_container.vue"

export default {
  components: {
    AppContainer
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      password: null,
      state: null
    }
  },
  computed: {
    footerButtons() {
      return {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }
    }
  },
  methods: {
    resetError() {
      this.state = null
    },
    async submit() {
      if (this.state === "invalidToken") {
        this.$router.push({ name: "password-reset" })
        return
      }

      if (this.state === "submitting") {
        return
      }
      this.state = "submitting"

      const password = this.password || ""

      if (password.length < 6) {
        this.state = "error"
        return
      }

      const data = {
        password,
        resetPasswordToken: this.token
      }

      try {
        await this.$store.dispatch("setNewPassword", data)
        this.notify("Dein Passwort wurde geändert. Bitte melde dich nun an.")
        this.goTo({ name: "new_user_session" })
      } catch (e) {
        if (e.response.status === 422) {
          this.state = "invalidToken"
        } else {
          this.state = "error"
        }
      }

      return false
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

h1 {
  font-size: 20px;
  color: #6db5e4;
}

button.uk-button {
  background: $dove-gray-color;
  color: #fff;
  text-transform: none;
}
</style>
