var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        staticClass: "input",
        attrs: { type: "text", value: "Account löschen", readonly: "" }
      }),
      _c("div", { staticClass: "appended" }, [
        _c("button", { on: { click: _vm.wantDeleteAccount } }, [
          _vm._v("Löschen")
        ])
      ]),
      _c(
        "vk-modal",
        {
          attrs: { show: _vm.showDeleteConfirmation, "overflow-auto": "" },
          on: {
            "update:show": function($event) {
              _vm.showDeleteConfirmation = $event
            }
          }
        },
        [
          _c(
            "form",
            { on: { submit: _vm.submit } },
            [
              _c("vk-modal-close", {
                on: {
                  click: function($event) {
                    _vm.showDeleteConfirmation = false
                  }
                }
              }),
              _c(
                "vk-modal-title",
                { attrs: { slot: "header" }, slot: "header" },
                [_vm._v("Account löschen")]
              ),
              _c("p", [
                _vm._v(
                  "Bitte gib dein Passwort ein, um dein Account zu löschen. Deine Daten & dein Account werden daraufhin innerhalb von 24 Stunden gelöscht."
                )
              ]),
              _c("p", { staticClass: "strong-input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  attrs: { type: "password", placeholder: "Passwort" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "uk-text-right",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("input", {
                    staticClass:
                      "uk-button uk-margin-small-right uk-button-submit",
                    attrs: { type: "submit", value: "Löschen" }
                  })
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }