<template lang="pug">
app-container(title="Stundenplan" :footerButtons="footerButtons" :fetching="fetching" :showBottom="true" @submit="addEntires")
  template(slot="sub-title")
    inline-select-field(:options="childOptions" v-model="childId")
  .schedule
    table.timetable
      thead
        tr
          th
          th(
            v-for="weekDay in weekDays"
            :key="weekDay.name"
          ) {{ weekDay.name }}
      tbody
        tr(v-for="hour in maxHours")
          th {{ hour }}. 
          timetable-cell(
            v-for="weekDay in weekDays"
            :key="weekDay.key"
            :timetable="timetable"
            :week-day="weekDay.key"
            :hour="hour"
            :time-entry="timeFor(hour)"
          )
</template>

<script>
import moment from "moment"
moment.locale("de")

import AppContainer from "../app_container.vue"
import { mapState } from "vuex"
import fetchSchedule from "./_fetch_schedule"
import TimetableCell from "./timetable_cell.vue"
import InlineSelectField from "../helpers/inline_select_field.vue"

export default {
  components: { AppContainer, TimetableCell, InlineSelectField },
  mixins: [fetchSchedule],
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    year: moment(new Date()).format("YYYY"),
    fetching: false,
    timetable: {},
    maxHours: 5,
    childId: null
  }),
  computed: {
    ...mapState({
      children: ({ children }) => children.children
    }),
    childOptions() {
      return Object.keys(this.children).map(key => {
        const child = this.children[key]
        return {
          value: child.id,
          title: child.name
        }
      })
    },
    footerButtons() {
      return {
        center: {
          type: "plus"
        }
      }
    },
    weekDays() {
      return [
        {
          name: "M",
          key: 0
        },
        {
          name: "D",
          key: 1
        },
        {
          name: "M",
          key: 2
        },
        {
          name: "D",
          key: 3
        },
        {
          name: "F",
          key: 4
        }
      ]
    },
    currentChild() {
      if (this.childId) {
        return this.children[this.childId]
      } else {
        for (const childId in this.children) {
          return this.children[childId]
        }
      }

      return null
    }
  },
  watch: {
    childId() {
      this.fetchTimetable()
    }
  },
  mounted() {
    if (this.id) {
      this.childId = this.id
      return
    }
    if (Object.keys(this.children).length == 0) {
      return
    }
    this.childId = Object.values(this.children)[0].id
  },
  methods: {
    timeFor(hour) {
      if (!this.hours || !this.hours[this.currentChild.id]) {
        return {}
      }
      return this.hours[this.currentChild.id][hour - 1]
    },
    addEntires() {
      this.$router.push({
        name: "form_timetable_entries",
        params: { childId: this.currentChild.id }
      })
    },
    editTodo(event) {
      this.$router.push({ name: "edit_todo", params: { id: event.id } })
    }
  }
}
</script>

<style scoped>
>>> .inline-select-field select,
>>> .inline-select-field span {
  color: #fff !important;
}
</style>
