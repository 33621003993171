<template lang="pug">
  app-container(
    title="Vollversion"
    sub-title="Pendelkinder"
    :show-footer-center-button="hasPlan"
    :show-navigation-bar-button="hasPlan"
    :fetching="purchasing"
    @submit="goTo({ name: 'root' })"
  )
    .container
      subscription-plan(
        v-for="plan in plans"
        :key="plan.key"
        :plan="plan"
      )
      p.uk-text-center(v-if="isExpired")
        a(href="#" @click.prevent="signOut") Ausloggen
      p.uk-text-center
        a(href="pendelkinder://iap/restore") Kauf wiederherstellen
      p.uk-text-center
        | Es gelten die
        |
        a(href="#" @click.prevent="showTermsAndConditions = true") Nutzungsbedingungen
        |
        | und
        |
        a(href="#" @click.prevent="showPrivacy = true") Datenschutzbestimmungen
        |
        | der Pendelkinder App

    vk-modal(:show.sync="showPrivacy" overflow-auto)
      vk-modal-close(@click="showPrivacy = false")
      vk-modal-title(slot="header") Datenschutz
      privacy-policy
      .uk-text-center(slot="footer")
        vk-button.uk-margin-small-right.close-button(@click="showPrivacy = false") Schließen
    vk-modal(:show.sync="showTermsAndConditions" overflow-auto)
      vk-modal-close(@click="showTermsAndConditions = false")
      vk-modal-title(slot="header" style="font-size: 1.7rem;") All­ge­meine Geschäfts­be­din­gun­gen
      terms-and-conditions
      .uk-text-center(slot="footer")
        vk-button.uk-margin-small-right.close-button(@click="showTermsAndConditions = false") Schließen
</template>

<script>
import AppContainer from "../app_container.vue"
import SubscriptionPlan from "./subscription_plan.vue"
import PrivacyPolicy from "./privacy_policy.vue"
import TermsAndConditions from "./terms_and_conditions.vue"
import { mapGetters, mapState, mapActions } from "vuex"

export default {
  components: {
    AppContainer,
    SubscriptionPlan,
    PrivacyPolicy,
    TermsAndConditions
  },
  data() {
    return {
      purchasing: false,
      showPrivacy: false,
      showTermsAndConditions: false
    }
  },
  computed: {
    ...mapGetters(["isTestAccount", "isSubscription"]),
    ...mapState({
      user: ({ user }) => user.user,
      plans: ({ user }) => user.plans
    }),
    hasPlan() {
      return this.user.plan !== "expired"
    },
    isExpired() {
      return !this.isTestAccount && !this.isSubscription
    }
  },
  async created() {
    await this.loadPlans()
  },
  mounted() {
    if (this.isExpired) {
      this.notify(
        "<h1>Testphase abgelaufen</h1><p>Um die App weiterhin im vollen Umfang zu nutzen, hast du nun die Möglichkeit ein Kauf abzuschließen..</p>",
        "expired",
        { timeout: 0, closeAll: true }
      )
    }

    window.addEventListener("message", this.receiveMessage, false)
  },
  methods: {
    ...mapActions(["loadPlans", "purchaseProduct"]),
    async receiveMessage(event) {
      let data = event.data
      if (typeof data !== "object") {
        try {
          data = JSON.parse(data)
        } catch (_) {
          return
        }
      }
      try {
        if (data.key === "iap") {
          const purchase = typeof data.value === "string" || typeof data.value === "String" ? JSON.parse(data.value) : data.value
          switch (purchase.result) {
            case "purchased":
            case "restored":
              this.purchasing = true
              try {
                await this.purchaseProduct(purchase)
                this.notify(purchase.result === "restored" ? "Wiederherstellung erfolgreich abgeschlossen" : "Kauf erfolgreich abgeschlossen")
              } catch (e) {
                this.showError()
              }

              this.loadPlans()
              this.$store.dispatch("refreshUser")
              this.purchasing = false
              break
            case "nothingToRestore":
              this.notify(
                "Es konnte keine Buchung gefunden werden. Wenn du bereits ein Pendelkinder Abonnement abgeschlossen hast, wende dich bitte an unseren Support.",
                "error",
                { timeout: 10000 }
              )
              break
            default:
              this.showError()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    showError() {
      this.notify(
        "Buchung konnte nicht abgeschlossen werden! Bitte versuche es erneut.",
        "error"
      )
    },
    async signOut() {
      await this.$store.dispatch("signOut")
      this.$router.push({ name: "app_start" })
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage)
  }
}
</script>

<style lang="scss" scoped>
.uk-notification {
  display: block !important;
  background: green;
  height: 100px;
  width: 100px;
}
</style>
