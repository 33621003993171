<template lang="pug">
  select(:value="value"
    @input="input"
    @blur="input"
    @change="input"
    ref="select"
  )
    option(v-for="option in options" :value="option.date" :selected="isCurrent(option.date)") {{ option.name }}
</template>

<script>
import moment from "moment"
moment.locale("de")

export default {
  props: {
    value: {
      required: true,
      type: Date
    }
  },
  computed: {
    options() {
      const count = 48
      let loopDate = moment(this.value).subtract(count / 2, "month")

      let options = []
      for (let i = 0; i < count; i++) {
        options.push({
          date: loopDate.toDate(),
          name: loopDate.format("MMMM YYYY")
        })
        loopDate = loopDate.add(1, "month")
      }

      return options
    }
  },
  methods: {
    isCurrent(date) {
      return (
        moment(date).format("MMMM YYYY") ==
        moment(this.value).format("MMMM YYYY")
      )
    },
    input(event) {
      this.$emit("input", new Date(event.target.value))
    },
    focus() {
      this.$refs.select.focus()
    }
  }
}
</script>
