<template lang="pug">
  .app-onboarding-start.uk-text-center
    h4.title Leseberechtigung
    p Du kannst Leseberechtigungen für den Kalender, Stundenplan, Kontakte und die Uploads vergeben. Dies geschieht über einen Netzwerkcode, der von deiner Patchworkfamilie genutzt und nach dem Download der App eingegeben werden kann. Deinen Netzwerkcode findest du auch unter den Einstellungen in der App.

    .big-input-group
      input.input.uk-text-center(type="text" readonly :value="code")
</template>

<script>
export default {
  created() {
    this.validateForm()
  },
  computed: {
    code() {
      return this.$store.state.account.account.accessCode
    }
  },
  methods: {
    validateForm() {
      this.$emit("isFormValidChange", true)
    }
  }
}
</script>

<style scoped>
.big-input-group .input {
  font-size: 1.4em;
  height: 40px;
}
</style>
