var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-onboarding-start uk-text-center" },
    [
      _c("h4", { staticClass: "title" }, [_vm._v("Kinder")]),
      _c("p", [
        _vm._v(
          "Alle Aktivitäten in der App können euren Kindern zugeordnet werden. Hierfür benötigen wir die Namen eurer Kinder. "
        )
      ]),
      _c("h4", [_vm._v("Wie heißen deine Kinder?")]),
      _vm._l(_vm.children, function(child) {
        return _c("child-input", {
          key: child.id,
          attrs: { child: child },
          on: { submit: _vm.removeStoredChild, change: _vm.updateStoredChild }
        })
      }),
      _c("child-input", {
        attrs: { child: _vm.newChild, action: "new" },
        on: { submit: _vm.appendChild }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }