var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-onboarding-start uk-text-center" }, [
    _c("h4", { staticClass: "title" }, [_vm._v("Elternteil 2")]),
    _c("p", [
      _vm._v(
        "Zur alleinigen Nutzung der App und für den Testzeitraum benötigen wir ein vorläufiges Kürzel für den anderen Elternteil (bestehend aus zwei Buchstaben). Verbindet ihr eure Apps, kann natürlich jeder sein eigenes Kürzel bestimmen."
      )
    ]),
    _c("h4", [_vm._v("Vorläufiges Kürzel")]),
    _c("div", { staticClass: "big-input-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.newParentUserName,
            expression: "newParentUserName",
            modifiers: { trim: true }
          }
        ],
        staticClass: "input",
        attrs: {
          type: "text",
          maxlength: "2",
          placeholder: "Kürzel Elternteil 2"
        },
        domProps: { value: _vm.newParentUserName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.newParentUserName = $event.target.value.trim()
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      }),
      _c("div", { staticClass: "appended" }, [
        _c("button", { on: { click: _vm.updateName } }, [
          _vm._v(_vm._s(_vm.label))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }