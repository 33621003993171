<template lang="pug">
  app-container(title="Anmelden" sub-title="Pendelkinder" :fetching="fetching" @submit="onSubmit")
    .uk-text-center
      h1 Einladung

      template(v-if="sameAccount")
        p Du bist mit dem selben Account eingeloggt, mit dem du dich verbinden möchtest.
      template(v-else-if="validInvite")
        p Du wurdest von {{ invite.inviter.email }} eingeladen, gemeinsam die Pendelkinder-App zu nutzen. Um beide Konten miteinander zu verbinden, nehme die Einladung mit einem klick an.
        p.uk-margin-large-top
          button.uk-button(@click="onSubmit") Einladung annehmen
      template(v-else)
        | Es ist etwas schief gegangen. Bitte versuche den Link aus der E-Mail erneut zu öffnen.
</template>

<script>
import AppContainer from "../app_container.vue"
import fetchApi from "../../lib/fetch_api.js"
import { mapState, mapGetters } from "vuex"

export default {
  components: {
    AppContainer
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fetching: false,
      invite: null
    }
  },
  computed: {
    ...mapState({
      account: ({ account }) => account.account
    }),
    ...mapGetters(["isUserPresent"]),
    sameAccount() {
      if (!this.validInvite) {
        return false
      }
      if (!this.account) {
        return false
      }

      return this.invite.accountId === this.account.id
    },
    validInvite() {
      return Boolean(this.invite && this.invite.id)
    }
  },
  created() {
    this.loadInvite()
  },
  methods: {
    async onSubmit() {
      if (this.sameAccount || !this.validInvite) {
        return
      }
      this.fetching = true
      try {
        const response = await fetchApi(`/api/invites/${this.token}`, {
          method: "PUT"
        })
        const { result } = await response.json()
        if (result) {
          await this.$store.dispatch("reloadSession")
          this.notify("Dein Konto wurde verknüpft.", "warning")
          this.$router.push({ name: "root" })
        } else if (this.isUserPresent && this.account && this.account.id) {
          this.notify("Dein Konto konnte nicht verknüpft werden.", "danger")
        } else {
          this.$router.push({ name: "new_user_registration" })
        }
      } catch (e) {
        console.log("error", e)
      } finally {
        this.fetching = false
      }
    },
    async loadInvite() {
      this.fetching = true
      try {
        const response = await fetchApi(`/api/invites/${this.token}`, {
          method: "GET"
        })
        const { error, invite } = await response.json()
        if (!error) {
          this.invite = invite
        }
      } catch (e) {
        //
      } finally {
        this.fetching = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

h1 {
  font-size: 20px;
  color: #6db5e4;
}

button.uk-button {
  background: $dove-gray-color;
  color: #fff;
  text-transform: none;
}
</style>
