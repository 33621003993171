var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-onboarding-start uk-text-center" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Weitere Einstellungen")]),
      _c("p", [
        _vm._v(
          "Prüfe deine Einstellungen in der App und schaue, was du dort noch aktivieren möchtest (Ferien, Push-Benachrichtigungen etc.)."
        )
      ]),
      _c("h4", [_vm._v("Einladung bereits erhalten")]),
      _c("p", [
        _vm._v(
          "Hast du einen Einladungslink zur Koppelung erhalten, kannst du ihn nun anklicken und eure beiden Apps sind mit einander verbunden."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }