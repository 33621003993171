<template lang="pug">
  p.input-group.mt-2
    input-field.center(
      :id="name"
      :type="type"
      :value="value"
      ref="input"
      :max="maxLength"
      @input="onUpdateValue($event)"
    )
    label(:for="name") {{ label }}
      span(v-if="subLabel") {{ subLabel }}
    slot
</template>

<script>
import InputField from "./input_field.vue"

export default {
  components: {
    InputField
  },
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    autofocus: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    label: { type: String, required: true },
    subLabel: { type: String, default: "" },
    maxLength: { type: Number, default: null }
  },
  methods: {
    onUpdateValue(value) {
      this.$emit("input", value)
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  position: relative;
}
</style>
