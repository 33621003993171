import fetchApi from "../lib/fetch_api.js"
import http from "../services/http"

const registerPush = () => (document.location.href = "pendelkinder://askpush")

function updateBadgeCount(counts) {
  const count = (counts.unreadMessagesCount || 0) + (counts.unreadNewsCount || 0)
  document.location.href = `pendelkinder://badgeUpdate/?count=${count}`
  return true
}

export default {
  state: {
    user: null,
    parents: {},
    plans: [],
    fetchingPlans: false,
    ignoredTrialWarning: false
  },
  getters: {
    isUserPresent: ({ user }) => !!user,
    isSubscription: ({ user }) =>
      user && ["monthly", "yearly", "full"].includes(user.plan),
    isTestAccount: ({ user }) => user && user.plan === "test",
    parents: ({ parents }) => parents || [],
    isOnboardingCompleted: ({ user }) => Boolean(user && user.onboardingCompletedAt),
    parentIds(state, _, rootState) {
      if (!state.user) {
        return []
      }
      const userId = state.user.id
      return rootState.account.accountMembers.
        map(accountMember => accountMember.userId === userId ? accountMember.parentId : null).
        filter(id => id)
    }
  },
  mutations: {
    setUser(state, user) {
      state.parents = user.parents || {}
      delete user.parents

      state.user = user
      updateBadgeCount({ unreadMessagesCount: user.unreadMessagesCount, unreadNewsCount: user.unreadNewsCount })
    },
    addParent(state, attrs) {
      state.parents = { ...state.parents, [attrs.id]: attrs }
    },
    clearSession(state, rootState) {
      state.user = null
      state.account = null
      state.todos = null
      state.children = null
      state.parents = null
      state.plans = []
      state.fetchingPlans = false
      state.ignoredTrialWarning = false

      rootState.onboarding.parentType = null
      rootState.onboarding.children = []
      rootState.onboarding.parentUserName = null
      updateBadgeCount({ unreadMessagesCount: 0, unreadNewsCount: 0 })
    },
    setPlans(state, plans) {
      state.plans = plans
    },
    setUnreadCounts(state, { messagesCount, newsCount }) {
      if (typeof messagesCount === "number") {
        state.user.unreadMessagesCount = messagesCount
      }
      if (typeof newsCount === "number") {
        state.user.unreadNewsCount = newsCount
      }
      updateBadgeCount({
        unreadMessagesCount: state.user.unreadMessagesCount || 0,
        unreadNewsCount: state.user.unreadNewsCount || 0
      })
    }
  },
  actions: {
    async updateUser({ state, commit }, attrs) {
      const newUser = { ...state.user, ...attrs }
      commit("setUser", newUser) // Update store and UI immediately for responsiveness

      const response = await fetchApi("/api/user", {
        method: "PUT",
        data: { user: attrs }
      })
      const { error, ...user } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setUser", user) // Update store and UI with potential changes from server
      }
    },
    updatePushToken(_, token) {
      const data = { user: { token } }
      fetchApi("/api/users/me", { method: "PUT", data })
    },
    async signIn({ dispatch }, { email, password, code, rememberMe }) {
      const data = { user: { email, password, code, rememberMe } }

      const response = await fetchApi("/api/users/sign_in", {
        method: "POST",
        data
      })
      const { error, ...user } = await response.json()

      if (error) {
        console.log("error", error)
        throw new Error(error)
      }
      await dispatch("initializeSession", { user })
    },
    async signOut({ commit, rootState }) {
      await fetchApi("/api/users/sign_out", { method: "DELETE" })
      commit("clearSession", rootState)
    },
    async signUp(
      _,
      {
        username,
        name,
        email,
        password,
        passwordConfirmation,
        termsAndPrivacyPolicy
      }
    ) {
      const rememberMe = true
      const data = {
        user: {
          username,
          name,
          email,
          password,
          passwordConfirmation,
          termsAndPrivacyPolicy,
          rememberMe
        }
      }
      const response = await fetchApi("/api/users", { method: "POST", data })
      const { errors } = await response.json()

      if (errors) {
        throw new Error("Ein Fehler ist aufgetreten.")
      }
    },
    async refreshUser({ commit, rootState }) {
      const userResponse = await fetchApi("/api/user", { method: "GET" })
      if (userResponse.status === 401) {
        commit("clearSession", rootState)
        throw new Error("Not Authenticated")
      }
      const { error, ...user } = await userResponse.json()

      if (error) {
        throw new Error(error)
      }

      commit("setUser", user)
    },
    async checkSignin({ dispatch }) {
      const response = await fetchApi("/api/user", { method: "GET" })

      if (response.status === 401) {
        return
      }

      const { error, ...user } = await response.json()

      if (error) {
        throw new Error(error)
      }

      await dispatch("initializeSession", { user })
    },
    async newPassword(_, { email }) {
      const data = { user: { email } }
      const response = await http.post("/api/users/password", data)
      return response
    },
    async setNewPassword(
      _,
      { password, passwordConfirmation, resetPasswordToken }
    ) {
      const data = {
        user: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: resetPasswordToken
        }
      }
      const response = await http.put("/api/users/password", data)
      return response
    },
    async updatePassword(
      { dispatch },
      { password, passwordConfirmation, currentPassword }
    ) {
      const data = {
        user: {
          password,
          passwordConfirmation,
          currentPassword
        }
      }

      try {
        const response = await fetchApi("/api/users", { method: "PUT", data })

        if (!response.ok) {
          const json = await response.json()
          if (json && json.errors && json.errors.currentPassword) {
            const message =
              "Das jetzige Passwort wurde falsch eingegeben. Bitte versuche es erneut."
            dispatch("notify", { message }, { root: true })
            return false
          }
        }
      } catch (e) {
        const message =
          "Dein Passwort konnte nicht zurück gesetzt werden. Bitte versuche es erneut."
        dispatch("notify", { message }, { root: true })
        return false
      }
      const message = "Passwort wurde erfolgreich geändert."
      dispatch("notify", { message, status: "success" }, { root: true })

      return true
    },
    async deleteAccount({ commit }, { password }) {
      try {
        const data = {
          user: {
            password
          }
        }

        const response = await fetchApi("/api/user", { method: "DELETE", data })
        if (response.ok) {
          const user = await response.json()
          commit("setUser", user)
        }
        return response.ok
      } catch (e) {
        return false
      }
    },
    async inviteParent({ commit }, { email, username }) {
      const data = { email, username }
      const response = await fetchApi("/api/parents", { method: "POST", data })

      const { error, ...parent } = await response.json()

      if (error) {
        throw new Error(error)
      }

      commit("addParent", parent)
    },
    async completeOnboarding({ commit }) {
      const data = {
        user: {
          onboarding_completed_at: new Date()
        }
      }
      const response = await fetchApi("/api/user", { method: "PUT", data })
      const { error, ...user } = await response.json()

      if (error) {
        throw new Error(error)
      }

      commit("setUser", user)
    },
    async resendConfirmationEmail({ commit, dispatch }, { email }) {
      const data = { user: { email } }
      await dispatch("POST", { path: "/api/users/confirmation", data })

      commit("setFlash", {
        type: "notice",
        message:
          "Falls Ihre E-Mail-Adresse in unserer Datenbank existiert, erhalten Sie in wenigen Minuten eine E-Mail, mit der Anleitung, wie Sie Ihr Passwort zurücksetzen können."
      })
    },
    async sendConfirmationToken({ dispatch }, { token }) {
      try {
        await dispatch("GET", {
          path: `/api/users/confirmation?confirmation_token=${token}`
        })
      } catch (error) {
        if (!error.json) {
          throw error
        }

        const errorKey = error.json.email
          ? "email_already_confirmed"
          : "invalid_confirmation_token"
        throw new Error(errorKey)
      }
    },
    registerPush() {
      registerPush()
    },
    togglePushNotifications({ dispatch, state }) {
      const user = state.user
      const newState = !user.isPushActive

      if (!user.hasPushToken && newState) {
        registerPush()
      }

      const newUser = { ...user, ...{ active_push: newState } }
      dispatch("updateUser", newUser)
    },
    async loadPlans({ state, commit, rootState }) {
      state.fetchingPlans = true
      const response = await fetchApi("/api/plans", { method: "GET" })
      const { error, plans } = await response.json()

      if (response.status === 401) {
        commit("clearSession", rootState)
        state.fetchingPlans = false
        throw new Error("Not Authenticated")
      } else if (!error) {
        commit("setPlans", plans)
      }
      state.fetchingPlans = false
    },
    async purchaseProduct({ state, commit, rootState }, { transactionId, receipt, purchaseToken, productId }) {
      const data = { purchase: { transactionId, receipt, purchaseToken, productId } }
      const response = await fetchApi("/api/purchases", {
        method: "POST",
        data
      })
      await response.json()

      if (response.status === 401) {
        commit("clearSession", rootState)
        state.fetchingPlans = false
        throw new Error("Not Authenticated")
      }
    },
    setUnreadCounts({ commit }, counts) {
      commit("setUnreadCounts", counts)
    }
  }
}
