var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "nav-button", on: { click: _vm.toggleNavigation } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.unreadCount > 0,
              expression: "unreadCount > 0"
            }
          ],
          staticClass: "badge-icon"
        },
        [_vm._v(_vm._s(_vm.unreadCount))]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.navigationVisible ? _c("div", { staticClass: "bg-view" }) : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.navigationVisible
          ? _c("ul", { staticClass: "menu" }, [
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "calendar" } } }, [
                    _vm._v("Kalender"),
                    _c("div", { staticClass: "column" })
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "messenger" } } }, [
                    _vm._v("Messenger"),
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        class: { notification: _vm.unreadMessagesCount > 0 }
                      },
                      [
                        _c("div", { staticClass: "badge-icon" }, [
                          _vm._v(_vm._s(_vm.unreadMessagesCount))
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "todos" } } }, [
                    _vm._v("Aufgaben"),
                    _c("div", { staticClass: "column" })
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "contacts" } } }, [
                    _vm._v("Kontakte"),
                    _c("div", { staticClass: "column" })
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "uploads" } } }, [
                    _vm._v("Handzettel"),
                    _c("div", { staticClass: "column" })
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "schedule" } } }, [
                    _vm._v("Stundenplan"),
                    _c("div", { staticClass: "column" })
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "news" } } }, [
                    _vm._v("Aktivitäten"),
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        class: { notification: _vm.unreadNewsCount > 0 }
                      },
                      [
                        _c("div", { staticClass: "badge-icon" }, [
                          _vm._v(_vm._s(_vm.unreadNewsCount))
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "settings" } } }, [
                    _vm._v("Einstellungen"),
                    _c("div", { staticClass: "column" })
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "page", params: { permalink: "help" } }
                      }
                    },
                    [_vm._v("Hilfe"), _c("div", { staticClass: "column" })]
                  )
                ],
                1
              ),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.logout($event)
                      }
                    }
                  },
                  [_vm._v("Logout"), _c("div", { staticClass: "column" })]
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }