<template lang="pug">
app-container(:title="month" :sub-title="year" :footerButtons="footerButtons" :fetching="fetchingEvents" :showBottom="true" @submit="addDate" @showMonth="showMonth" @showWeek="showWeek" @titleClick="titleClick")
  template(slot="nav-bottom")
    table(v-if="displayMode == 'month'" width="100%")
      tbody
        tr
          td M
          td D
          td M
          td D
          td F
          td S
          td S

  .calendar
    month-select.month-selector(ref="select-month" v-model="date")

    .months(v-if="displayMonth" :style="`margin-top: ${marginTop}px;`")
      .month(v-for="(date, index) in dates" :ref="monthCodeFor(date)")
        .scroll-observer(ref="topScroll" v-if="index === 2")

        h2 {{ monthTitle(date) }}

        table(:class="displayMode")
          tbody
            tr(v-for="week in weeks(date)")
              td.day(v-for="day in week" :class="classFor(day, date)" @click="showEvents($event, day)")
                .td-container
                  div.invite-warning(:class="{ hidden: !hasWarningFor(day) }")
                  div.calendar-header(:class="{ current: isToday(day)}") {{ day.getDate() }}
                  ul
                    li(v-for="parent in parentsFor(day)" :class="parent.role") {{ parent.username }}

        .scroll-observer(ref="bottomScroll" v-if="index === 3")
    .calendar-weeks(v-else-if="displayMode == 'week'" :style="`margin-top: ${marginTop}px;`")
      .calendar-week(v-for="(date, index) in weekDates" :ref="monthCodeForWeek(date)")
        .scroll-observer(ref="topScroll" v-if="index === 2")
        table(:class="displayMode")
          tbody
            template(v-for="day in weekDays(date)")
              tr(v-if="day.getDate() === 1")
                th(colspan="2") {{ monthTitle(day) }}
              tr.week(:class="{ holiday: isHoliday(day) }")
                th.day-headline(:class="{ current: isToday(day)}" @click="addDate(day)") {{ formatWeekDay(day) }}
                td.day
                  ul
                    li(v-for="event in eventsFor(day)" @click="editEvent(event, day)" :class="eventEntryClass(event)")
                      | {{ weekEventName(event) }}
        .scroll-observer(ref="bottomScroll" v-if="index === 3")
    
</template>

<script>
import moment from "moment"
moment.locale("de")

import AppContainer from "../app_container.vue"
import MonthSelect from "../helpers/month_select.vue"
import { mapState, mapGetters, mapActions } from "vuex"

// const pageY = event => {
//   if (event.pageY) {
//     return event.pageY
//   }
//   if (event.touches && event.touches[0]) {
//     return event.touches[0].pageY
//   }
// }

const triggers = ["scroll", "resize"]

export default {
  components: {
    AppContainer,
    MonthSelect
  },
  data: () => ({
    animating: false,
    date: new Date(),
    currentDate: new Date(),
    displayMode: "month",
    eventContainerPos: { x: 0, y: 0 },
    marginTop: 10000,
    selectedDay: null,
    touching: false,
    startCount: 6,
    startIndex: -2,
    shouldChangeDates: false
    // selectedDayEvents: []
  }),
  computed: {
    ...mapGetters(["repeatableEvents", "childNames"]),
    ...mapState({
      fetchingEvents: ({ events }) => events.fetchingEvents,
      allEvents: ({ events }) => events.events,
      children: ({ children }) => children.children,
      accountMembers: ({ account }) => account.accountMembers,
      holidays: ({ events }) => events.holidays,
      user: ({ user }) => user.user
    }),
    events() {
      let events = {}

      const minDate = moment(this.currentDate)
        .subtract(1, "month")
        .startOf("month")
      const maxDate = moment(this.currentDate)
        .add(1, "month")
        .endOf("month")
      Object.values(this.allEvents).forEach(event => {
        const childDates = event.childEvents
          .map(childEvent =>
            childEvent.repeatDates.map(dates => moment(dates[0]))
          )
          .flat()
        event.repeatDates.forEach(date => {
          const startDate = moment(date[0])
          const endDate = moment(date[1])
          if (
            (startDate < minDate && endDate < minDate) ||
            (startDate > maxDate && endDate > maxDate)
          ) {
            return
          }

          for (
            let i = 0, days = endDate.diff(startDate, "days") + 1;
            i < days;
            i++
          ) {
            const addedDate = moment(startDate).add(i, "days")
            const key = addedDate.format("YYYY-MM-DD")
            if (
              childDates.find(childDate => childDate.isSame(addedDate, "day"))
            ) {
              continue
            }

            if (!events[key]) {
              events[key] = []
            }

            events[key].push(event)
          }

          Object.keys(events).forEach(function(key) {
            const entries = events[key]
            if (entries.length < 2) {
              return
            }

            events[key] = entries.sort((entryA, entryB) => {
              if (moment(entryA.startAt) < moment(entryB.startAt)) {
                return -1
              }
              return 1
            })
          })
        })
      })

      return events
    },
    footerButtons() {
      return {
        left: {
          title: "Monatsansicht",
          action: "showMonth",
          active: this.displayMode == "month"
        },
        right: {
          title: "Wochenansicht",
          action: "showWeek",
          active: this.displayMode == "week"
        },
        center: {
          type: "plus"
        }
      }
    },
    month() {
      return moment(this.currentDate).format("MMMM")
    },
    dates() {
      return [...Array(this.startCount)].map((_, i) => {
        return moment(this.date)
          .add(i + this.startIndex, "month")
          .toDate()
      })
    },
    weekDates() {
      return [...Array(this.startCount)].map((_, i) => {
        return moment(this.date)
          .add(i + this.startIndex, "week")
          .toDate()
      })
    },
    year() {
      return `${this.currentDate.getFullYear()}`
    },
    displayMonth() {
      return this.displayMode === "month"
    },
    displayWeek() {
      return this.displayMode === "week"
    }
    // dateList() {
    //   return this.$refs["date-list"]
    // },
    // firstListElement() {
    //   if (!this.dateList) {
    //     return
    //   }
    //   return this.dateList.querySelector("li")
    // }
  },
  watch: {
    displayMode() {
      this.$nextTick(this.updateScrollPoint)
    },
    date(date) {
      this.currentDate = date
    }
  },
  async mounted() {
    try {
      await this.loadEvents()
    } catch (e) {
      this.goTo({ name: "new_user_session" })
      return
    }
    if (this.$route.params.date) {
      this.date = new Date(this.$route.params.date)
    }

    triggers.forEach(event => window.addEventListener(event, this.didScroll))

    this.$nextTick(() => {
      this.shouldChangeDates = true
      this.updateScrollPoint()
    })
  },
  beforeDestroy() {
    triggers.forEach(event => window.removeEventListener(event, this.didScroll))
  },
  methods: {
    ...mapActions(["loadEvents"]),
    didScroll() {
      if (this.displayMonth || this.displayWeek) {
        const selector = this.displayMonth ? "div.month" : ".calendar-week"
        const center = window.scrollY + window.screen.height / 2
        const months = [...this.$el.querySelectorAll(selector)]
        const index = months.findIndex(month => {
          return (
            month.offsetTop <= center &&
            month.offsetTop + month.offsetHeight >= center
          )
        })
        const date = this.displayMonth
          ? this.dates[index]
          : this.weekDates[index]
        if (date) {
          this.currentDate = date
        }
      }

      if (!this.shouldChangeDates) {
        return
      }

      if (this.$refs.bottomScroll && this.$refs.bottomScroll.length) {
        const scroller = this.$refs.bottomScroll[0]
        if (scroller.offsetTop < window.scrollY + window.screen.height) {
          this.addDates()
          return
        }
      }

      if (this.$refs.topScroll && this.$refs.topScroll.length) {
        const scroller = this.$refs.topScroll[0]
        if (scroller.offsetTop > window.scrollY + window.screen.height) {
          this.addDatesBefore()
        }
      }
    },
    updateScrollPoint() {
      if (!this.displayMonth && !this.displayWeek) {
        return
      }
      this.scrollToDate(this.date)
    },
    addDates() {
      this.performDateChange(1)
    },
    addDatesBefore() {
      this.performDateChange(-1)
    },
    performDateChange(change) {
      try {
        this.shouldChangeDates = false
        const selector = this.displayMonth ? ".month" : ".calendar-week"
        const isDown = change > 0
        const monthElements = this.$el.querySelectorAll(selector)
        let height

        if (isDown) {
          height = monthElements[0].offsetHeight + 20
        }

        this.startIndex += change

        if (!isDown) {
          height = (monthElements[0].offsetHeight + 25) * -1
        }

        this.$nextTick(() => {
          // const index =
          //   change < 0 ? this.startIndex - change : this.startIndex + 1
          this.marginTop += height
          this.shouldChangeDates = true
        })
      } catch (e) {
        this.shouldChangeDates = true
        console.error(e)
      }
    },
    isToday(day) {
      return (
        moment(day).format("YYYY-MM-DD") ===
        moment(new Date()).format("YYYY-MM-DD")
      )
    },
    monthTitle(date) {
      return moment(date).format("MMMM YYYY")
    },
    monthCodeFor(date) {
      return `month-${moment(date).format("MM-YYYY")}`
    },
    monthCodeForWeek(date) {
      return `month-${moment(date).format("DD-MM-YYYY")}`
    },
    isBeforeLastDate(date) {
      return this.dates.indexOf(date) === this.dates.length - 2
    },
    scrollToDate(date) {
      const ref = this.displayMonth
        ? this.monthCodeFor(date)
        : this.monthCodeForWeek(date)
      if (this.$refs[ref] && this.$refs[ref][0]) {
        const height = this.$refs[ref][0].offsetTop - 110
        window.scrollTo(0, height)
      }
    },
    // touchStart(event) {
    //   if (this.animating) {
    //     return
    //   }
    //   this.touching = true
    //   this.touchPosition = pageY(event)
    // },
    // touchMove(event) {
    //   if (!this.touching) {
    //     return
    //   }
    //   this.moved = true
    //   const height = this.firstListElement.offsetHeight
    //   const pos = -height + pageY(event) - this.touchPosition
    //   this.movedPosition = pageY(event)
    //   this.scrollTo(pos, 0)
    // },
    // touchEnd(event) {
    //   this.touching = false
    //   if (this.moved) {
    //     setTimeout(() => (this.moved = false), 150)
    //   }

    //   const height = this.firstListElement.offsetHeight
    //   const diff = this.movedPosition - this.touchPosition

    //   let newPos = -height
    //   let monthChange = 0
    //   if (diff < 0 && diff < height / -2) {
    //     monthChange = 1
    //     newPos = -height * 2
    //   } else if (diff > 0 && diff > height / 2) {
    //     monthChange = -1
    //     newPos = 0
    //   }

    //   this.animating = true
    //   this.scrollTo(newPos, 200)
    //   setTimeout(() => {
    //     this.animating = false
    //     this.date = moment(this.date)
    //       .add(monthChange, this.displayMode)
    //       .toDate()
    //     this.scrollTo(-height, 0)
    //   }, 210)
    // },
    // scrollTo(y, duraction) {
    scrollTo() {
      // this.dateList.querySelectorAll("ul.swipeable > li").forEach(li => {
      //   li.style.transitionDuration = `${duraction}ms`
      //   li.style.transform = `translate3d(0px, ${y}px, 0px)`
      // })
    },
    weeks(date) {
      let weeks = []
      let startDate = moment(date).startOf("month")
      let sub = startDate.weekday()
      startDate = startDate.subtract(sub, "days")

      let endDate = moment(date).endOf("month")
      if (endDate.weekday() != 0) {
        sub = 6 - endDate.weekday()
      }
      endDate = endDate.add(sub, "days")

      let lastDate = startDate.toDate()
      while (lastDate <= endDate.toDate()) {
        let days = []
        for (let i = 0, n = 6; i <= n; i++) {
          days.push(
            moment(lastDate)
              .add(i, "days")
              .toDate()
          )
        }
        weeks.push(days)
        lastDate = moment(lastDate)
          .add(7, "days")
          .toDate()
      }

      return weeks
    },
    weekDays(date) {
      let days = []

      const startDate = moment(date).startOf("week")
      for (let i = 0, n = 7; i < n; i++) {
        days.push(
          moment(startDate)
            .add(i, "days")
            .add(12, "hours")
            .toDate()
        )
      }

      return days
    },
    classFor(date, origDate) {
      let classNames = []
      if (date.getMonth() < origDate.getMonth()) {
        classNames.push("last")
      } else if (date.getMonth() > origDate.getMonth()) {
        classNames.push("next")
      }

      if (this.eventsFor(date)) {
        classNames.push("has-date")
      }

      if (this.isHoliday(date)) {
        classNames.push("holiday")
      }

      return classNames.join(" ")
    },
    eventsFor(date) {
      return this.events[moment(date).format("YYYY-MM-DD")]
    },
    hasWarningFor(day) {
      const events = this.eventsFor(day)
      if (!events) {
        return false
      }
      return Boolean(
        events.find(event => {
          return event.pendingInviteParentIds.length
        })
      )
    },
    parentsFor(date) {
      const events = this.eventsFor(date)
      if (!events) {
        return []
      }
      let names = []
      let parents = []
      events.forEach(event => {
        if (!event.parentIds.length && !names.includes("--")) {
          names.push("--")
          parents.push({ username: "--", role: "undefined" })
          return
        }
        event.parentNames.split(", ").forEach(name => {
          if (!names.includes(name)) {
            const parent = this.accountMembers.find(accountMember => {
              return accountMember.username == name
            })
            if (parent) {
              names.push(name)
              parents.push(parent)
            }
          }
        })
      })
      return parents
    },
    isHoliday(date) {
      return this.holidays.includes(moment(date).format("YYYY-MM-DD"))
    },
    eventEntryClass(event) {
      if (event.pendingInviteParentIds.length) {
        return "invite"
      }
      if (!this.accountMemberFor(event)) {
        // console.log("hm", event)
      }
      const parents = this.accountMembersFor(event)
      const notMe = parents.find(parent => parent.userId !== this.user.id)
      const me = parents.find(parent => parent.userId === this.user.id)
      if (me && parents.length > 1 && notMe && notMe.role) {
        return me.role
      }
      return (this.accountMemberFor(event) || { role: "undefined" }).role
    },
    accountMemberFor(event) {
      return this.accountMembers.find(accountMember => {
        return event.parentIds.find(
          parentId => accountMember.parentId == parentId
        )
      })
    },
    accountMembersFor(event) {
      return this.accountMembers.filter(accountMember => {
        return event.parentIds.find(
          parentId => accountMember.parentId == parentId
        )
      })
    },
    weekEventName(event) {
      const name = [
        this.parentNameFor(event),
        event.title,
        this.childNameFor(event)
      ]
        .filter(prop => {
          return prop && prop.length
        })
        .join(" | ")
      return [name, this.startTime(event)].join(" ")
    },
    childNameFor(event) {
      return this.childNames(event.childIds)
    },
    parentNameFor(event) {
      const accountMembers = this.accountMembers
      const selectedAccountMembers = accountMembers.filter(accountMember => {
        return event.parentIds.includes(accountMember.userId)
      })
      if (
        accountMembers.length > 1 &&
        selectedAccountMembers.length == accountMembers.length
      ) {
        return "Beide"
      }

      return selectedAccountMembers
        .map(accountMember => accountMember.username)
        .join(", ")
        .toUpperCase()
    },
    startTime(event) {
      if (!event.hasStartTime) {
        return ""
      }
      return ` | ${event.startTime} Uhr`
    },
    formatWeekDay(date) {
      return moment(date).format("Do dd")
    },
    addDate(day) {
      this.$router.push({
        name: "new_calendar",
        params: { startAt: day || this.date }
      })
    },
    showMonth() {
      this.displayMode = "month"
    },
    showWeek() {
      this.displayMode = "week"
    },
    showEvents(e, day) {
      if (this.moved) {
        return
      }
      this.date = day
      this.displayMode = "week"
      // const events = this.eventsFor(day)
      // if (events && events.length > 0) {
      //   this.date = moment(events[0].startAt).toDate()
      //   this.displayMode = 'week'
      // }
    },
    editEvent(event, day) {
      this.$router.push({
        name: "edit_calendar",
        params: { id: event.id, startAt: day }
      })
    },
    editEventDay(day) {
      // TODO remove
      const events = this.eventsFor(day)
      if (events && events.length > 0) {
        this.$router.push({
          name: "edit_calendar",
          params: { id: events[0].id }
        })
      }
    },
    titleClick() {
      this.$refs["select-month"].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.spacer {
  height: 100px;
}

:deep(#main-container) {
  padding-left: 0;
  padding-right: 0;
}

select {
  opacity: 0;
  height: 0;
  position: absolute;
}

ul.swipeable {
  // margin: 0px -30px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  // white-space: nowrap;
  overflow: hidden;
  height: calc(100vh - 100px - 40px - 60px);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ul.swipeable > li {
  display: block;
  width: 100%;
  height: calc(100vh - 100px - 40px - 60px);
  overflow: hidden;
  vertical-align: top;

  // table {
  //   margin: 0px 30px;
  // }
}

.holiday {
  background: rgba(0, 186, 238, 0.12);
}

.week .current {
  font-family: AcuminPro-Bold;
}

.calendar-header {
  line-height: 20px;

  &.current {
    color: #fff;
    font-weight: bold;
    background: #6c6c6c;
  }
}

.td-container {
  position: relative;

  .invite-warning {
    position: absolute;
    width: 17px;
    height: 17px;
    background-image: url(./icon-info.png);
    background-size: cover;
    right: -10px;
    top: -9px;
  }
}

.calendar table.week td ul li {
  &.invite,
  &.undefined {
    background: $middle-gray-color;
  }
}

.hidden {
  display: none;
}
</style>
