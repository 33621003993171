<template lang="pug">
app-container(title="Anmelden" sub-title="Pendelkinder" :footer-buttons="footerButtons" @submit="onSubmit")
  h1 Vielen Dank
  p Vielen Dank für deine Anmeldung. Du bekommst in Kürze eine E-Mail von uns mit dem Bestätigungslink. Solltest du keine E-Mail in deinem Posteingang finden, schaue bitte bei dir im Spam-Ordner nach. Klicke auf den Bestätigungslink und fahre mit der Anmeldung fort.
</template>

<script>
import AppContainer from "../app_container.vue"

export default {
  components: { AppContainer },
  data() {
    return {
      footerButtons: {
        left: {
          title: "Login",
          link: "new_user_session",
          active: false
        },
        right: {
          title: "Anmelden",
          link: "new_user_registration",
          active: false
        }
      }
    }
  },
  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch("refreshUser")
        this.$router.push({ name: "settings_onboarding" })
      } catch (e) {
        this.notify(
          "Bitte bestätige die E-Mail, die wir dir geschickt haben.",
          "danger"
        )
      }
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 20px;
  color: #6db5e4;
}
</style>
