import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import user from "./store/user"
import account from "./store/account"
import profile from "./store/profile"
import events from "./store/events"
import todos from "./store/todos"
import children from "./store/children"
import contacts from "./store/contacts"
import messages from "./store/messages"
import uploads from "./store/uploads"
import http from "./store/http"
import pages from "./store/pages"
import onboarding from "./store/onboarding"

Vue.use(Vuex)

const plugins = []
if (location.hostname === "localhost") {
  const vuexLocal = new VuexPersistence({ storage: window.localStorage })
  plugins.push(vuexLocal.plugin)
}

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async initializeSession({ commit, dispatch }, { user }) {
      commit("setUser", user)

      await dispatch("loadAccounts")
      await dispatch("loadProfile")
      await dispatch("loadChildren")
      await dispatch("loadEvents")
      await dispatch("loadTodos")
      await dispatch("loadUploads")
      await dispatch("loadContacts")
    },
    async reloadSession({ dispatch }) {
      await dispatch("refreshUser")
      await dispatch("loadAccounts")
      await dispatch("loadProfile")
      await dispatch("loadChildren")
      await dispatch("loadEvents")
      await dispatch("loadTodos")
      await dispatch("loadContacts")
      await dispatch("loadUploads")
    }
  },
  modules: {
    user,
    account,
    profile,
    children,
    events,
    todos,
    contacts,
    messages,
    uploads,
    http,
    pages,
    onboarding
  },
  plugins
})
