import fetchApi from "../lib/fetch_api.js"

export default {
  state: {
    contacts: {}
  },
  mutations: {
    setContactsArray(state, contacts) {
      state.contacts = contacts
    },
    setContactAttrs(state, contact) {
      const _contact = state.contacts[contact.id] || {}
      state.contacts = {
        ...state.contacts,
        [contact.id]: { ..._contact, ...contact }
      }
    },
    addContact(state, contact) {
      state.contacts = { ...state.contacts, [contact.id]: contact }
    },
    removeContact(state, contact) {
      let contacts = state.contacts
      delete contacts[contact.id]
      state.contacts = { ...state.contacts }
    },
    clearSession(state) {
      state.contacts = {}
    }
  },
  actions: {
    async updateContact({ commit }, attrs) {
      // commit('setContactAttrs', attrs) // Update store and UI immediately for responsiveness

      const response = await fetchApi(`/api/contacts/${attrs.id}`, {
        method: "PUT",
        data: attrs
      })
      const { error, ...contact } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setContactAttrs", contact) // Update store and UI with potential changes from server
      }
    },
    async loadContacts({ commit }) {
      const response = await fetchApi("/api/contacts", { method: "GET" })
      const { error, ...contacts } = await response.json()

      if (!error) {
        Object.values(contacts).forEach(contact =>
          commit("addContact", contact)
        )
      }
    },
    async createContact({ commit }, attrs) {
      const response = await fetchApi("/api/contacts", {
        method: "POST",
        data: attrs
      })
      const { error, ...contact } = await response.json()

      if (!error) {
        commit("addContact", contact)
      }
    },
    async removeContact({ commit }, attrs) {
      const response = await fetchApi(`/api/contacts/${attrs.id}`, {
        method: "DELETE"
      })
      const { error, ...contact } = await response.json()

      if (!error) {
        commit("removeContact", contact)
      }
    }
  }
}
