<template lang="pug">
app-container.small(title="Handzettel" :footerButtons="footerButtons" :fetching="fetching" :show-bottom="false" @submit="addUpload")
  template(slot="sub-title")
    inline-select-field(:options="childOptions" v-model="childId")
  ul.list.uploads(v-if="hasUploads")
    template(v-for="uploadObject in sortedUploads")
      li.header {{ uploadObject.month }}
      router-link.upload(
        v-for="upload in uploadObject.group"
        :key="upload.id"
        tag="li"
        :to="{ name: 'upload', params: { id: upload.id } }"
      )
        | {{ titleFor(upload) }}
  p.flex(v-else)
    i Keine Uploads eingetragen
</template>

<script>
import AppContainer from "../app_container.vue"
import InlineSelectField from "../helpers/inline_select_field.vue"
import { mapGetters, mapState, mapActions } from "vuex"

import moment from "moment"
moment.locale("de")

export default {
  components: {
    AppContainer,
    InlineSelectField
  },
  data: () => ({
    fetching: false,
    childId: null,
    footerButtons: {
      center: {
        type: "plus"
      }
    }
  }),
  computed: {
    ...mapGetters(["childNames"]),
    ...mapState({
      uploads: ({ uploads }) => uploads.uploads,
      children: ({ children }) => children.children
    }),
    childOptions() {
      return [
        {
          value: null,
          title: "Alle"
        },
        ...Object.keys(this.children).map(key => {
          const child = this.children[key]
          return {
            value: child.id,
            title: child.name
          }
        })
      ]
    },
    hasUploads() {
      return Boolean(Object.values(this.uploads).length)
    },
    sortedUploads() {
      let uploads = []
      let group = []

      let lastMonth = ""
      Object.values(this.uploads)
        .filter(upload => {
          return (
            !this.childId ||
            upload.childIds.includes(parseInt(this.childId, 10))
          )
        })
        .forEach(upload => {
          const month = moment(upload.createdAt).format("MMMM YYYY")
          if (month != lastMonth) {
            if (lastMonth != "") {
              uploads.push({ month: lastMonth, group: group })
              group = []
            }
            lastMonth = month
          }

          group.push(upload)
        })

      const sortedGroup = group.sort(
        (a, b) => moment(b.createdAt) - moment(a.createdAt)
      )
      uploads.push({ month: lastMonth, group: sortedGroup })
      return uploads
    }
  },
  created() {
    this.fetchUploads()
  },
  methods: {
    ...mapActions(["loadUploads"]),
    async fetchUploads() {
      try {
        this.fetching = true
        await this.loadUploads()
        this.fetching = false
      } catch (e) {
        this.notify("Uploads konnten nicht geladen werden", "danger")
        this.fetching = false
      }
    },
    titleFor(upload) {
      const uploadDate = `upload am ${moment(upload.createdAt).format("L")}`
      return [upload.title, this.childNames(upload.childIds), uploadDate].join(
        " | "
      )
    },
    addUpload() {
      this.$router.push({ name: "new_upload" })
    }
  }
}
</script>

<style scoped>
>>> .inline-select-field select,
>>> .inline-select-field span {
  color: #fff !important;
}
</style>
