import UIkit from "uikit"

export default {
  methods: {
    notify(message, status = "danger", timeout = 5000, closeAll = false) {
      // console.log(message, status)
      if (closeAll) {
        UIkit.notification.closeAll()
      }
      UIkit.notification().close()
      return UIkit.notification(message, { status, timeout })
    },
    goBack() {
      history.back()
    },
    goTo(path) {
      this.$router.push(path)
    }
  }
}
