<template lang="pug">
app-container(
  :title="pageTitle"
  :sub-title="subTitle"
  :footerButtons="footerButtons"
  @submit="saveDate"
  @goBack="goBack"
  @deleteEvent="deleteEvent"
  :fetching="fetching"
)
  .calendar
    .strong-input-group(v-if="loaded")
      .uk-alert-primary(v-if="isConfirmationNeeded" uk-alert)
        p Du wurdest zu diesem Termin eingeladen. Möchtest du diesen bestätigen:
        .uk-flex(uk-margin)
          button.uk-flex-auto.uk-button.uk-button-default.uk-button-small(@click="wantConfirmEvent")
            | Bestätigen
          button.uk-flex-auto.uk-button.uk-button-default.uk-button-small.uk-margin-left(@click="wantDeclineEvent")
            | Ablehnen
      .uk-alert-primary(v-else-if="isConfirmationPending" uk-alert style="padding: 15px 29px 10px 15px")
        | Diesen Termin hast du erstellt & angefragt. Die Antwort steht noch aus!

      h2(v-if="isSpecialEvent") Ausnahme anlegen
      .row
        .col
          input(type="text" v-model="title" placeholder="Titel" @focus="shouldStartEdit")

      .row
        .left-col
          input.expandable(type="date" v-model="date" @focus="shouldStartEdit")
        .right-col
          input.expandable(type="date" v-model="endDate" @focus="shouldStartEdit")

      .row(v-if="isSpecialEvent")
        .col
          input#repeat-end-date(type="checkbox" v-model="repeatEndDate")
          label.checkbox-label(for="repeat-end-date") Serientermin ab diesem Tag beenden

      .row(v-if="!isSpecialEvent")
        .col
          label(v-for="option in repeatOptions" :for="`repeat-${option.id}`" :key="option.id")
            input(type="radio" v-model="repeatType" :value="option.id" :id="`repeat-${option.id}`" @focus="shouldStartEdit")
            span {{ option.value }}
      input(v-else type="hidden" v-model="repeatType")

      .row
        .left-col(style="position:static")
          input.expandable(type="text" v-model="startTime" @touchstart="changeType($event, 'time')" @focus="changeType($event, 'time')" placeholder="von")
        .right-col
          input.expandable(type="text" v-model="endTime" @touchstart="changeType($event, 'time')" @focus="changeType($event, 'time')" placeholder="bis")

      .row
        .left-col
          multi-select(v-model="childIds" :options="childrenOptions" placeholder="Kind" tip="Kind/er auswählen" @touchstart.native="shouldStartEdit")
        .right-col
          multi-select(v-model="parentIds" :options="parentOptions" placeholder="Elternteil" tip="Elternteil auswählen" all-label="Beide" @touchstart.native="shouldStartEdit")

      .row
        .col
          input(type="text" v-model="street" placeholder="Adresse" @focus="shouldStartEdit")

      .row
        .col
          textarea(v-model="description" placeholder="Notiz" @focus="shouldStartEdit")

      uploads(:files="uploadedFiles")

      .row
        .col
          input(placeholder="Datei" type="file" multiple @change="processFiles($event)" @focus="shouldStartEdit")
</template>

<script>
import moment from "moment"
moment.locale("de")

import { mapState, mapGetters, mapActions } from "vuex"
import AppContainer from "../app_container.vue"
import MultiSelect from "../helpers/multi_select.vue"
import Uploads from "../helpers/uploads.vue"
import { ask } from "../helpers/webview"

export default {
  components: {
    AppContainer,
    MultiSelect,
    Uploads
  },
  props: {
    id: {
      default: null,
      type: [String, Number]
    },
    startAt: {
      type: Date,
      default: null
    }
  },
  data: () => ({
    date: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    repeatEndDate: null,
    repeatType: "once",
    startTime: null,
    endTime: null,
    childIds: [],
    parentIds: [],
    title: null,
    street: null,
    description: null,
    event: null,
    files: null,
    loaded: false,
    fetching: false,
    askedSpecialEvent: false
  }),
  computed: {
    ...mapState({
      children: ({ children }) => children.children,
      events: ({ events }) => events.events,
      user: ({ user }) => user.user,
      accountMembers: ({ account }) => account.accountMembers
    }),
    ...mapGetters(["parentOptions"]),
    ...mapGetters({ userParentIds: "parentIds" }),
    pageTitle() {
      if (this.event) {
        return "Termin bearbeiten"
      } else {
        return "Neuer Termin"
      }
    },
    footerButtons() {
      if (this.user.isGuest) {
        return {}
      }
      let buttons = {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }
      if (this.event) {
        buttons.right = {
          title: "Löschen",
          action: "deleteEvent"
        }
      }

      return buttons
    },
    subTitle() {
      return `${moment(this.date).format("YYYY")}`
    },
    isSpecialEvent() {
      return Boolean(
        this.$route.name === "special_calendar" ||
          (this.event && this.event.parentEventId)
      )
    },
    repeatOptions() {
      return [
        {
          id: "once",
          value: "1x"
        },
        {
          id: "weekly",
          value: "jede Woche"
        },
        {
          id: "two_weekly",
          value: "alle 2 Wochen"
        },
        {
          id: "monthly",
          value: "Monat"
        },
        {
          id: "yearly",
          value: "Jahr"
        }
      ]
    },
    childrenOptions() {
      let options = []
      Object.keys(this.children).forEach(i => {
        const child = this.children[i]
        options.push({ id: child.id, value: child.name })
      })
      return options
    },
    isConfirmationNeeded() {
      return Boolean(
        this.event && this.event.pendingInviteParentIds.filter(id => this.userParentIds.includes(id)).length
      )
    },
    isConfirmationPending() {
      return Boolean(
        this.event &&
          this.event.pendingInviteParentIds.length &&
          !this.event.pendingInviteParentIds.filter(id => this.userParentIds.includes(id)).length
      )
    },
    uploadedFiles() {
      if (!this.event || !this.event.uploads) {
        return []
      }
      return this.event.uploads
    }
  },
  watch: {
    // date(newDate) {
    //   if (moment(newDate) > moment(this.endDate)) {
    //     this.endDate = newDate
    //   }
    // },
    // endDate(newEndDate) {
    //   if (moment(newEndDate) < moment(this.date)) {
    //     this.date = newEndDate
    //   }
    // }
  },
  mounted() {
    if (this.id) {
      let event = this.events[this.id]
      if (event) {
        if (this.isRepeatable(event) && this.startAt) {
          this.date = moment(this.startAt).format("YYYY-MM-DD")
          const endAt = moment(this.date)
            .subtract(event.startAt)
            .format("YYYY-MM-DD")
          this.endDate = moment(endAt).format("YYYY-MM-DD")
        } else {
          this.date = moment(event.startAt).format("YYYY-MM-DD")
          this.endDate = moment(event.endAt).format("YYYY-MM-DD")
        }
        if (event.repeatEndDate) {
          this.repeatEndDate = moment(event.repeatEndDate).format("YYYY-MM-DD")
        }
        if (event.hasStartTime) {
          this.startTime = moment(event.startAt).format("HH:mm")
        }
        if (event.hasEndTime) {
          this.endTime = moment(event.endAt).format("HH:mm")
        }
        this.repeatType = event.repeatType
        this.childIds = event.childIds
        this.title = event.title
        this.street = event.street
        this.description = event.description
        this.parentIds = event.parentIds

        this.event = event
      }
    } else {
      if (this.startAt) {
        this.date = moment(this.startAt).format("YYYY-MM-DD")
        this.endDate = moment(this.startAt).format("YYYY-MM-DD")
      }
      const childValues = Object.values(this.children)
      if (childValues.length == 1) {
        this.childIds = [childValues[0].id]
      }
      if (this.accountMembers.length == 1) {
        this.parentIds = [this.accountMembers[0].userId]
      }
    }

    this.loaded = true
  },
  methods: {
    ...mapActions(["confirmEvent", "declineEvent"]),
    changeType(e, type) {
      this.shouldStartEdit(e)
      e.target.type = type
    },
    async saveDate() {
      if (this.date && this.endDate && moment(this.endDate) < moment(this.date)) {
        this.notify("Startdatum muss vor dem Enddatum liegen. Bitte überprüfe deine Daten.", "danger")
        return
      }
      if (
        this.date &&
        this.title
        // this.endDate &&
        // this.childIds.length &&
        // this.parentIds.length
      ) {
        const startAt = moment(`${this.date} ${this.startTime || "12:00"}`)
        const event = {
          startAt,
          endAt: moment(`${this.endDate} ${this.endTime || "12:00"}`),
          repeatType: this.isSpecialEvent ? "once" : this.repeatType,
          childIds: this.childIds,
          parentIds: this.parentIds,
          title: this.title,
          street: this.street,
          description: this.description,
          files: this.files,
          hasStartTime: !!this.startTime,
          hasEndTime: !!this.endTime,
          changeAll: true,
          parentEventId:
            this.isSpecialEvent && this.event ? this.event.id : null,
          repeatEndDate: this.repeatEndDate ? startAt : null
        }

        try {
          this.fetching = true
          if (this.event && !this.isSpecialEvent) {
            event.id = this.event.id
            if (this.isRepeatable(event)) {
              const options = [
                {
                  title: "Abbrechen",
                  type: "cancel",
                  key: "cancel"
                },
                {
                  title: "Ja",
                  type: "default",
                  key: "ok"
                },
                {
                  title: "Nein",
                  type: "no",
                  key: "single"
                }
              ]
              ask(
                "Dieser Termin ist eine Ereignisserie. Sollen alle Termine angepasst werden?",
                options,
                async result => {
                  if (result === "cancel") {
                    return
                  } else if (result === "ok") {
                    await this.$store.dispatch("updateEvent", event)
                  } else {
                    await this.addExceptionTime()
                    event.repeatType = "once"
                    event.id = null
                    await this.$store.dispatch("createEvent", event)
                  }
                  this.goToOverview()
                }
              )
              return
            } else {
              await this.$store.dispatch("updateEvent", event)
            }
          } else {
            await this.$store.dispatch("createEvent", event)
          }

          this.goToOverview()
        } catch (e) {
          console.log(e)
          this.notify("Eintrag konnte nicht gespeichert werden.", "danger")
        } finally {
          this.fetching = false
        }
      } else {
        this.notify("Bitte Titel und Startdatum ausfüllen.", "danger")
      }
    },
    deleteEvent() {
      if (this.event.repeatType == "once" || !this.event.id) {
        if (confirm("Soll der Eintrag wirklich gelöscht werden?")) {
          this.removeEvent()
        }
      } else {
        const options = [
          {
            title: "Abbrechen",
            type: "cancel",
            key: "cancel"
          },
          {
            title: "Alle löschen",
            type: "default",
            key: "all"
          },
          {
            title: "Nur diesen Eintrg löschen",
            type: "default",
            key: "single"
          }
        ]
        ask(
          "Möchtest du alle Einträge löschen oder nur das angezeigte?",
          options,
          result => {
            if (result === "all") {
              this.removeEvent()
            } else if (result === "single") {
              this.addExceptionTime()
            }
          }
        )
      }
    },
    async removeEvent() {
      this.fetching = true

      await this.$store.dispatch("removeEvent", this.event)
      this.fetching = false
      this.goToOverview()
    },
    async addExceptionTime(callback) {
      this.fetching = true
      try {
        await this.$store.dispatch("addExceptionTime", {
          eventId: this.event.id,
          date: this.startAt || this.event.startAt
        })
        if (callback) {
          callback()
        } else {
          this.goToOverview()
        }
      } catch (e) {
        this.notify("Eintrag konnte nicht gespeichert werden.", "danger")
      } finally {
        this.fetching = false
      }
    },
    goBack() {
      history.back()
    },
    goToOverview() {
      const date = moment(this.date).format("YYYY-MM-DD")
      this.$router.push({ name: "calendar", params: { date } })
    },
    processFiles(event) {
      this.files = event.target.files
    },
    isRepeatable(event) {
      return Boolean(event && event.repeatType !== "once")
    },
    async wantDeclineEvent() {
      if (confirm("Termin wirklich ablehnen?")) {
        const result = await this.declineEvent(this.event.id)
        if (result) {
          this.goBack()
        }
      }
    },
    async wantConfirmEvent() {
      const result = await this.confirmEvent(this.event.id)
      if (result) {
        this.goBack()
      }
    },
    addSpecialDate() {
      this.goTo({ name: "special_calendar", params: { id: this.event.id } })
    },
    shouldStartEdit(event) {
      if (
        this.askedSpecialEvent ||
        this.isSpecialEvent ||
        !this.event ||
        this.event.repeatType === "once"
      ) {
        return
      }

      event.preventDefault()
      event.stopPropagation()
      event.target.blur()
      window.setTimeout(() => {
        if (confirm("Möchtest du diesen Termin ändern?")) {
          this.addSpecialDate()
        } else {
          this.askedSpecialEvent = true
          event.target.focus()
        }
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

h2 {
  color: $dove-gray-color;
}
label span {
  padding: 0px 10px 0px 3px;
  font-size: 12px;
}
.upload {
  width: 100%;
  max-height: 100%;
}
.uk-alert-warning {
  padding-right: 15px;
}

.checkbox-label {
  padding-left: 5px;
}
</style>
