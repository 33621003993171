<template lang="pug">
app-container(title="Aktivitäten" sub-title="Pendelkinder" :footerButtons="footerButtons" :fetching="fetching" :showBottom="false" @submit="showApp")
  ul.list.news
    template(v-for="newsObject in news")
      li.header {{ newsObject.date }}
      li.news-entry(
        v-for="newsEntry in newsObject.group"
        :class="{ deleted: newsEntry.deletedAt, 'is-new': newsEntry.isNew }"
        @click.prevent="newsEntryClick(newsEntry)"
      )
        span {{ newsEntry.newsType }}
        |
        | |
        | {{ newsEntry.title }}
</template>

<script>
import moment from "moment"
moment.locale("de")

import AppContainer from "../app_container.vue"

export default {
  components: { AppContainer },
  data: () => ({
    fetching: false,
    news: [],
    unreadCount: 0,
    footerButtons: {
      center: {
        type: "logo"
      }
    }
  }),
  mounted() {
    this.fetchNews()
  },
  methods: {
    async fetchNews() {
      this.fetching = true
      const response = await this.$http.get("/api/news")
      let news = []
      let group = []

      if (response.ok) {
        let lastDate = null
        const entries = response.body
        let keys = []
        let unreadCount = 0
        entries.forEach(newsEntry => {
          if (newsEntry.isNew) {
            unreadCount += 1
          }
          const newDate = moment(newsEntry.createdAt).format("LL")
          const key = [
            newsEntry.title,
            newsEntry.accountId,
            newsEntry.historyableId,
            newsEntry.historyableType,
            newsEntry.newsType
          ].join("-")
          if (keys.includes(key)) {
            return
          }
          keys.push(key)
          if (newsEntry && newDate != lastDate) {
            if (lastDate != null) {
              news.push({ date: lastDate, group: group })
              group = []
            }
            lastDate = newDate
          }

          group.push(newsEntry)
        })

        news.push({ date: lastDate, group: group })
        this.news = news
        this.unreadCount = unreadCount
        this.storeUnreadCount()
      }
      this.fetching = false
    },
    showApp() {
      this.$router.push({ name: "calendar" })
    },
    newsEntryClick(newsEntry) {
      if (newsEntry.isNew && this.unreadCount > 0) {
        this.unreadCount -= 1
        this.storeUnreadCount()
      }
      this.$http.put(`/api/news/${newsEntry.id}`)

      newsEntry.isNew = false
      if (newsEntry.deletedAt) {
        return
      }
      this.$nextTick(() => {
        if (newsEntry.historyableType == "Todo") {
          this.$router.push({
            name: "edit_todo",
            params: { id: newsEntry.historyableId }
          })
        } else if (newsEntry.historyableType == "Event") {
          this.$router.push({
            name: "edit_calendar",
            params: { id: newsEntry.historyableId }
          })
        } else if (newsEntry.historyableType == "Contact") {
          this.$router.push({
            name: "edit_contact",
            params: { id: newsEntry.historyableId }
          })
        } else if (newsEntry.historyableType == "Upload") {
          this.$router.push({
            name: "upload",
            params: { id: newsEntry.historyableId }
          })
        }
      })
    },
    storeUnreadCount() {
      this.$store.dispatch("setUnreadCounts", { newsCount: this.unreadCount })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.deleted {
  text-decoration: line-through;
}
.is-new {
  color: $turkies-color;
  font-weight: bold;
}
</style>

