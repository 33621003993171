var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inline-select-field" }, [
    _c(
      "select",
      { ref: "select", on: { change: _vm.change } },
      _vm._l(_vm.options, function(option) {
        return _c(
          "option",
          {
            key: option.value,
            domProps: {
              value: option.value,
              selected: option.value == _vm.value
            }
          },
          [_vm._v(_vm._s(option.title))]
        )
      }),
      0
    ),
    _c("span", [_vm._v("▼")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }