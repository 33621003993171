<template lang="pug">
app-container(title="Messenger" :sub-title="chatName" :footerButtons="footerButtons")
  template(slot="centerView")
    upload-form(ref="uploadForm" v-model="previewImage")
    form(@submit.prevent="sendMessage" ref="form" uk-tooltip="Emojies werden aus der Nachricht entfernt")
      button.left(type="button" @click="selectUpload")
        img(src="/btn-add.png" width="40" height="40")

      textarea-autosize(
        rows="1"
        @focus.native="scrollToBottom"
        ref="input"
        placeholder="DEINE NACHRICHT"
        :max-height="72"
        :min-height="32"
      )
      button.right(@mousedown.prevent="sendMessage" type="submit")
        img(src="/btn-confirm.png" width="40" height="40")

  .messages(ref="messages")
    message(
      v-for="message in messages"
      :message="message"
      :key="message.id"
      @showImage="showImage"
    )
  .message-bottom
  vk-modal-full(:show="shouldShowImage")
    .fullscreen-image(@click="fullscreenImage = null")
      img(v-if="fullscreenImage" :src="fullscreenImage")
</template>

<script>
import UIkit from "uikit"
import AppContainer from "../app_container.vue"
import Message from "./message.vue"
import UploadForm from "./upload_form.vue"
import { mapState, mapActions } from "vuex"

function removeEmojies(string) {
  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g

  return string.replace(regex, "")
}

export default {
  components: {
    AppContainer,
    Message,
    UploadForm
  },
  data() {
    return {
      cancelScroll: true,
      footerButtons: {
        centerView: true
      },
      previewImage: {},
      fullscreenImage: null,
      isSendingMessage: false
    }
  },
  computed: {
    ...mapState({
      messages: ({ messages }) => messages.messages,
      account: ({ account }) => account.account,
      accountMembers: ({ account }) => account.accountMembers,
      user: ({ user }) => user.user,
      profile: ({ profile }) => profile.profile
    }),
    chatName() {
      const accountMember = this.accountMembers.find(accountMember => {
        return accountMember.userId != this.user.id
      })
      if (!accountMember) {
        return ""
      }

      return `an ${accountMember.username.toUpperCase()}`
    },
    hasEmojies() {
      return removeEmojies(this.message()).length != this.message().length
    },
    shouldShowImage() {
      return Boolean(this.fullscreenImage)
    }
  },
  async mounted() {
    this.chat = window.App.cable.subscriptions.create(
      {
        channel: "ChatChannel",
        room: this.account.chatToken
      },
      {
        connected: () => {
          console.log("connected")
        },
        disconnected: () => {
          console.log("disconnected")
        },
        received: data => {
          if (!data.message || (!data.message.body && !data.message.upload)) {
            return
          }
          if (data.message.userId === this.user.id) {
            return
          }

          this.markMessagesAsRead()
          this.addMessage(data.message)
          window.setTimeout(this.scrollToBottom, 100)
        },
        sendMessage: (message, previewImage) => {
          const file = previewImage.file
          let newMessage = {
            accountId: this.account.id,
            body: message,
            createdAt: new Date().toJSON(),
            id: new Date().getTime(),
            parentMode: this.profile.parentMode || "mom",
            updatedAt: new Date().toJSON(),
            upload: null,
            userId: this.user.id
          }

          if (!file) {
            this.addMessage(newMessage)
            this.chat.perform("send_message", {
              account_id: this.account.id,
              message
            })
            window.setTimeout(this.scrollToBottom, 100)
            return
          }

          newMessage.upload = previewImage.src
          this.addMessage(newMessage)
          window.setTimeout(this.scrollToBottom, 100)

          const reader = new FileReader()
          const name = file.name
          reader.onload = () => {
            this.chat.perform("send_message", {
              account_id: this.account.id,
              message,
              name,
              file: reader.result
            })
          }
          reader.readAsDataURL(file)
        }
      }
    )
    UIkit.util.on(document, "beforeshow", this.beforeShowTooltip)

    window.addEventListener("scroll", this.dismissKeyboard)
    this.$refs.messages.addEventListener("scroll", this.dismissKeyboard)
    this.$refs.input.$el.addEventListener("input", this.changedMessage)
    await this.loadMessages()
    this.scrollToBottom(false)
    this.$store.dispatch("refreshUser")
    this.registerPush()
  },
  beforeDestroy() {
    this.chat.unsubscribe()
    window.removeEventListener("scroll", this.dismissKeyboard)
    this.$refs.messages.removeEventListener("scroll", this.dismissKeyboard)
    this.$refs.input.$el.removeEventListener("input", this.changedMessage)

    UIkit.util.off(document, "beforeshow", this.beforeShowTooltip)
  },
  methods: {
    ...mapActions({
      loadMessages: "loadMessages",
      markMessagesAsRead: "markMessagesAsRead",
      addMessage: "addMessage",
      registerPush: "registerPush"
    }),
    message() {
      return this.$refs.input.$el.value
    },
    changedMessage() {
      if (this.hasEmojies) {
        UIkit.tooltip(this.$refs.form).show()
      } else {
        UIkit.tooltip(this.$refs.form).hide()
      }
    },
    emptyMessage() {
      this.$refs.input.val = ""
      this.$refs.input.$el.value = ""
    },
    sendMessage() {
      if (this.isSendingMessage) {
        return
      }
      this.isSendingMessage = true
      if (!this.message().length && !this.previewImage.file) {
        window.setTimeout(() => this.isSendingMessage = false, 100)
        return
      }
      if (!removeEmojies(this.message()).length && !this.previewImage.file) {
        this.notify("Nachrichten mit Emoji werden nicht verschickt.", "warning")
        window.setTimeout(() => this.isSendingMessage = false, 100)
        return
      }

      try {
        this.chat.sendMessage(this.message(), this.previewImage)
      } catch (e) {
        console.log(e)
      }
      this.emptyMessage()
      this.previewImage = {}
      window.setTimeout(() => this.isSendingMessage = false, 100)
    },
    scrollToBottom() {
      this.cancelScroll = false
      // UIkit.scroll(document.body, { duration: animated == false ? 1 : 500 }).scrollTo(".message-bottom")
      window.scrollTo(0, document.body.clientHeight)
      const messageContainer = this.$refs.messages
      messageContainer.scrollTo(0, messageContainer.scrollHeight)
    },
    dismissKeyboard() {
      if (!this.cancelScroll) {
        window.setTimeout(() => {
          this.cancelScroll = true
        }, 500)

        return
      }
      this.$refs.input.$el.blur()
    },
    beforeShowTooltip(event) {
      if (!event.target.className.includes("uk-tooltip")) {
        return
      }
      if (!this.hasEmojies) {
        UIkit.tooltip(this.$refs.form).hide()
      }
      return this.hasEmojies
    },
    selectUpload(event) {
      this.$refs.uploadForm.$emit("selectFile", event)
    },
    showImage(image) {
      this.fullscreenImage = image
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/stylesheets/variables.scss";

form {
  width: 85%;
  white-space: nowrap;
  margin-top: 5px;
  margin: 4px auto 4px;
  display: flex;
  align-items: center;

  textarea {
    width: 100%;
    margin-right: 4px;
    background: #fff;
    border-radius: 20px;
    border: none;
    padding: 7px 12px;
    outline: none;
    display: block;
    font-size: 15px;
    color: $dove-gray-color;
    font-family: Arial;

    &::placeholder {
      color: $dove-gray-color;
    }
  }

  button {
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    background: none;
    border: none;
    outline: none;

    &.left {
      margin-right: 7px;
    }

    &.right {
      margin-left: 5px;
    }
  }
}

.messages {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  // top: 100px;
  max-height: calc(100% - 100px - 40px);
  bottom: 40px;
  margin: 0px -30px;
  padding: 0px 30px;
  width: 100%;
  overflow: auto;
}

.uk-tooltip {
  text-align: center;
}

.uk-tooltip-inner {
  line-height: 1rem;
}

.fullscreen-image {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background: black;

  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}
</style>
