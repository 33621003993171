<template lang="pug">
  .input-field
    span.toggle(
      v-if="type === 'password'"
      :class="{ active: isPasswordVisible }"
      @click="togglePasswordVisibility($event)"
    )
    input(
      :id="id"
      :type="type"
      :value="value"
      ref="input"
      :maxLength="max"
      :class="`input-${type}`"
      :placeholder="placeholder"
      @input="onInput($event)"
    )
</template>

<script>
export default {
  props: {
    id: { type: String, required: true },
    value: { type: String, required: true },
    autofocus: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    max: { type: Number, default: null },
    placeholder: { type: String, default: null }
  },
  data() {
    return {
      focused: false,
      isPasswordVisible: false
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus()
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },
    togglePasswordVisibility(event) {
      event.preventDefault()
      event.stopPropagation()
      this.isPasswordVisible = !this.isPasswordVisible

      const input = this.$refs.input
      if (this.isPasswordVisible) {
        input.setAttribute("type", "text")
      } else {
        input.setAttribute("type", "password")
      }
      input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.input-field {
  position: relative;
}

.center .input-password {
  padding-left: 35px !important;
}

.input-password {
  padding-right: 35px !important;
}

.toggle {
  display: block;
  background-image: url("../../images/icon-eye-open.png");
  &.active {
    background-image: url("../../images/icon-eye-closed.png");
  }
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  width: 27px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 0;
  margin-top: 1px;
  border: none;
  background-size: 27px 18px;
  background-position: center;
}
</style>
