var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: _vm.pageTitle,
        "sub-title": _vm.subTitle,
        footerButtons: _vm.footerButtons
      },
      on: { goBack: _vm.goBack }
    },
    [
      _c("h5", [_vm._v("Kein Kind angelegt")]),
      _c("p", [
        _vm._v(
          "Damit Du Pendelkinder verwenden kannst, musst du zunächst ein Kind in den Settings anlegen."
        )
      ]),
      _c(
        "p",
        [
          _c(
            "router-link",
            {
              staticClass: "btn",
              attrs: { to: { name: "settings" }, tag: "button" }
            },
            [_vm._v("Kind anlegen")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }