<template lang="pug">
  .plan
    .header
      .switch(v-if="!isDeletePlan" :class="{ active: plan.active }")
      .titles
        h1 {{ plan.group }}
        h2
          vue-markdown(:key="titleKey") {{ plan.title }}

    .description
      vue-markdown(:key="plan.key") {{ plan.text }}
    .big-input-group(v-if="isBookable")
      button(@click="purchase") Kaufen
    .big-input-group(v-else-if="isDeletePlan")
      delete-account-modal.delete-account(
        v-if="!user.markedAsDeletedAt"
      )
      button(
        v-else
        @click="cancelDeletion"
      ) Löschen abbrechen
</template>

<script>
import DeleteAccountModal from "./delete_account_modal.vue"
import VueMarkdown from "vue-markdown"
import { mapState, mapActions } from "vuex"

export default {
  components: {
    DeleteAccountModal,
    VueMarkdown
  },
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user
    }),

    titleKey() {
      return `title-${this.plan.key}`
    },
    supportedApp() {
      return window.appVersion === "1.1" || window.appVersion === "1.3.3"
    },
    switchId() {
      return this.plan.key
    },
    isBookable() {
      return Boolean(this.supportedApp && this.plan.productKey && !this.plan.active && !this.isDeletePlan)
    },
    isDeletePlan() {
      return this.plan.productKey === "delete"
    }
  },
  methods: {
    ...mapActions(["deleteAccount"]),

    purchase() {
      document.location.href = `pendelkinder://iap/${this.plan.productKey}`
    },
    cancelDeletion() {
      this.deleteAccount({})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.plan {
  border: 1px $middle-gray-color solid;
  padding: 7px 30px;
  margin-bottom: 20px;

  h1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: bold;
    color: $dove-gray-color;
  }

  h2 {
    color: $turkies-color;
    margin: 0 0 10px 0;
    font-size: 1.1rem;
    font-weight: bold;

    strong, b {
      color: $orange-color;
    }
  }

  .description :deep {
    * {
      font-size: 11px;
      line-height: 13px;
      color: $dove-gray-color;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .switch {
    float: right;
    margin: 10px -10px 0 0;
    width: 35px;
    height: 20px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    background: $dove-gray-color;
    border-radius: 18px;

    &.active {
      background: $turkies-color;

      &:after {
        left: auto;
        right: 0;
      }
    }

    &:after {
      left: 0;
      width: 18px;
      height: 18px;
      margin: 1px;
      content: "";
      position: absolute;
      background: #ffffff;
      border-radius: 10px;
    }
  }
}

:deep(button) {
  margin-top: 5px;
  height: 20px;
  line-height: 22px;
  font-size: 14px;
  width: auto;
  padding: 0 12px;
}

.delete-account :deep {
  .input {
    display: none;
  }
  .appended {
    padding-left: 0;
  }
}
</style>
