<template lang="pug">
app-container(
  title="Anmelden"
  sub-title="Pendelkinder"
  :footer-buttons="footerButtons"
  :show-navigation-bar-button="false"
  @goToApp="goToApp"
)
  .app-onboarding
    .app-onboarding-start.uk-text-center
      h4.title Es kann losgehen!
      p
        strong
          | Viel Spaß beim Umschauen in der 
          br 
          | Pendelkinder-App!
</template>

<script>
import AppContainer from "../app_container.vue"

export default {
  components: { AppContainer },
  computed: {
    footerButtons() {
      return {
        center: {
          type: "active",
          action: "goToApp"
        }
      }
    }
  },
  created() {
    this.$store.dispatch("reloadSession")
  },
  methods: {
    goToApp() {
      this.$router.push({ name: "root" })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

:deep(h4) {
  font-size: 1.1rem;
  font-weight: bold;

  &.title {
    color: $turkies-color;
  }
}
</style>
