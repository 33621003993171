var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        fetching: _vm.submitting,
        "footer-buttons": _vm.footerButtons
      },
      on: { submit: _vm.onSubmit }
    },
    [
      _c(
        "form",
        {
          staticClass: "signupForm",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("input-group", {
            attrs: {
              name: "name",
              type: "text",
              label: "Vorname",
              autofocus: true
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "name"
            }
          }),
          _c("input-group", {
            attrs: {
              name: "username",
              type: "text",
              label: "Benutzername",
              "sub-label": "Kürzel bestehend aus 2 Buchstaben",
              "max-length": 2
            },
            model: {
              value: _vm.username,
              callback: function($$v) {
                _vm.username = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "username"
            }
          }),
          _c("input-group", {
            attrs: { name: "email", type: "email", label: "E-Mail Adresse" },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "email"
            }
          }),
          _c("input-group", {
            attrs: { name: "password", type: "password", label: "Passwort" },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "password"
            }
          }),
          _c("input-group", {
            attrs: {
              name: "passwordConfirmation",
              type: "password",
              label: "Passwort",
              "sub-label": "Wiederholung"
            },
            model: {
              value: _vm.passwordConfirmation,
              callback: function($$v) {
                _vm.passwordConfirmation =
                  typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "passwordConfirmation"
            }
          }),
          _c("div", { staticClass: "small-input-group text-small" }, [
            _c("div", { staticClass: "custom-checkbox boolean" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.terms,
                    expression: "terms"
                  }
                ],
                attrs: { id: "terms", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.terms)
                    ? _vm._i(_vm.terms, null) > -1
                    : _vm.terms
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.terms,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.terms = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.terms = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.terms = $$c
                    }
                  }
                }
              }),
              _c("label", { attrs: { for: "terms" } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.showTermsAndConditions = true
                      }
                    }
                  },
                  [_vm._v("AGBs")]
                ),
                _vm._v("\n akzeptieren")
              ])
            ])
          ]),
          _c("div", { staticClass: "small-input-group text-small" }, [
            _c("div", { staticClass: "custom-checkbox boolean" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.privacyPolicy,
                    expression: "privacyPolicy"
                  }
                ],
                attrs: { id: "privacyPolicy", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.privacyPolicy)
                    ? _vm._i(_vm.privacyPolicy, null) > -1
                    : _vm.privacyPolicy
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.privacyPolicy,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.privacyPolicy = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.privacyPolicy = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.privacyPolicy = $$c
                    }
                  }
                }
              }),
              _c("label", { attrs: { for: "privacyPolicy" } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.showPrivacy = true
                      }
                    }
                  },
                  [_vm._v("Datenschutz")]
                ),
                _vm._v("\nakzeptieren")
              ])
            ])
          ]),
          _c(
            "vk-modal",
            {
              attrs: { show: _vm.showPrivacy, "overflow-auto": "" },
              on: {
                "update:show": function($event) {
                  _vm.showPrivacy = $event
                }
              }
            },
            [
              _c("vk-modal-close", {
                on: {
                  click: function($event) {
                    _vm.showPrivacy = false
                  }
                }
              }),
              _c(
                "vk-modal-title",
                { attrs: { slot: "header" }, slot: "header" },
                [_vm._v("Datenschutz")]
              ),
              _c("privacy-policy"),
              _c(
                "div",
                {
                  staticClass: "uk-text-center",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "vk-button",
                    {
                      staticClass: "uk-margin-small-right close-button",
                      on: {
                        click: function($event) {
                          _vm.showPrivacy = false
                        }
                      }
                    },
                    [_vm._v("Schließen")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vk-modal",
            {
              attrs: { show: _vm.showTermsAndConditions, "overflow-auto": "" },
              on: {
                "update:show": function($event) {
                  _vm.showTermsAndConditions = $event
                }
              }
            },
            [
              _c("vk-modal-close", {
                on: {
                  click: function($event) {
                    _vm.showTermsAndConditions = false
                  }
                }
              }),
              _c(
                "vk-modal-title",
                {
                  staticStyle: { "font-size": "1.7rem" },
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_vm._v("All­ge­meine Geschäfts­be­din­gun­gen")]
              ),
              _c("terms-and-conditions"),
              _c(
                "div",
                {
                  staticClass: "uk-text-center",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "vk-button",
                    {
                      staticClass: "uk-margin-small-right close-button",
                      on: {
                        click: function($event) {
                          _vm.showTermsAndConditions = false
                        }
                      }
                    },
                    [_vm._v("Schließen")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("p", { staticClass: "disclaimer text-center" }, [
            _vm._v(
              "Nach deiner Registrierung kannst du die App für eine Woche kostenlos testen. Um die App nach Ablauf der Testphase weiter nutzen können, hast du die Möglichkeit, die Vollversion für einmalig 9,99 € zu erwerben."
            )
          ]),
          _c(
            "button",
            { staticClass: "invisible", attrs: { disabled: !_vm.submittable } },
            [_vm._v("Registrieren")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }