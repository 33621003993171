import fetchApi from "../lib/fetch_api.js"

export default {
  state: {
    profile: {}
  },
  mutations: {
    setProfileAttrs(state, attrs) {
      state.profile = { ...state.profile, ...attrs }
    },
    clearSession(state) {
      state.profile = {}
    }
  },
  actions: {
    async updateProfile({ state, commit }, attrs) {
      commit("setProfileAttrs", attrs) // Update store and UI immediately for responsiveness

      const response = await fetchApi("/api/profile", {
        method: "PUT",
        data: state.profile
      })
      const { error, ...profile } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setProfileAttrs", profile) // Update store and UI with potential changes from server
      }
    },
    async loadProfile({ commit, dispatch }) {
      const response = await fetchApi("/api/profile", { method: "GET" })
      const { error, ...profile } = await response.json()

      if (profile.reminderTime) {
        dispatch("requestReminderPermissions")
      }

      if (!error) {
        commit("setProfileAttrs", profile)
      }
    }
  }
}
