<template lang="pug">
  #app-container
    template(v-if="fetching")
      transition(name="fade")
      loading-animation
    #navigation-bar
      .header
        .titles(@click.prevent="titleClick")
          h1 {{ title }}
          h2(v-if="subTitle && subTitle !== ''") {{ subTitle }}
          slot(name="sub-title" v-else)
        navigation-bar-menu(
          v-if="isNavigationBarMenuVisible"
          @onLogout="doLogout"
        )
      .bottom(v-if="showBottom")
        slot(name="nav-bottom")

    
    #main-container(:class="{ 'full-navigation': showBottom }")
      section(v-if="shouldShowGuestForbittenMessage")
        p Dies ist der Leseaccount von Pendelkinder. Du hast hier die Möglichkeit in alle Daten reinzusehen. Aber du kannst keine Termine oder Änderungen vornehmen.
      slot(v-else)

    #footer-bar
      template(v-if="shouldShowGuestForbittenMessage")
        router-link.center-button(:to="{ name: 'root' }")
      template(v-else)
        template(v-if="footerButtons.centerView")
          slot(name="centerView")
        template(v-else)
          template(v-if="footerButtons.left")
            router-link.left-button(v-if="footerButtons.left.link" :to="{ name: footerButtons.left.link }" v-bind:class="{ active: footerButtons.left.active, inactive: footerButtons.left.active == false }") {{ footerButtons.left.title }}
            a.left-button(v-else @click="leftButtonClick" href="#" v-bind:class="{ active: footerButtons.left.active, inactive: footerButtons.left.active == false }") {{ footerButtons.left.title }}
          a.right-button(v-if="footerButtons.right" @click="rightButtonClick" href="#" v-bind:class="{ active: footerButtons.right.active, inactive: footerButtons.right.active == false }") {{ footerButtons.right.title }}
          a.center-button(
            v-if="showFooterCenterButton"
            @click="centerButtonClick"
            href="#"
            :class="!isGuest && footerButtons && footerButtons.center && footerButtons.center.type ? footerButtons.center.type : ''"
          )
</template>

<script>
import NavigationBarMenu from "./navigation_bar/menu.vue"
import LoadingAnimation from "./helpers/loading_animation.vue"

export default {
  components: { NavigationBarMenu, LoadingAnimation },
  props: {
    transitionName: {
      type: String,
      default: "slide"
    },
    transitionIn: {
      type: String,
      default: "slideInLeft"
    },
    transitionOut: {
      type: String,
      default: "slideOutLeft"
    },
    fetching: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: null
    },
    showBottom: {
      type: Boolean,
      default: true
    },
    footerButtons: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showFooterCenterButton: {
      type: Boolean,
      default: true
    },
    showNavigationBarButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isNavigationBarMenuVisible() {
      return this.showNavigationBarButton && this.isUserSignedIn
    },
    isUserSignedIn() {
      return Boolean(this.$store.state.user && this.$store.state.user.user)
    },
    isGuest() {
      return this.isUserSignedIn && this.$store.state.user.user.isGuest
    },
    shouldShowGuestForbittenMessage() {
      if (!this.isGuest || !this.$route.meta || this.$route.meta.guestForbitten !== true) {
        return false
      }
      return true
    }
  },
  methods: {
    centerButtonClick(e) {
      e.preventDefault()
      if (this.isGuest) {
        return this.$router.push({ name: "root" })
      }

      let action = "submit"
      if (this.footerButtons.center && this.footerButtons.center.action) {
        action = this.footerButtons.center.action
      }
      this.$emit(action)
    },
    rightButtonClick(e) {
      e.preventDefault()
      if (this.footerButtons.right.link) {
        this.$router.push({ name: this.footerButtons.right.link })
      } else {
        this.$emit(this.footerButtons.right.action)
      }
    },
    leftButtonClick(e) {
      e.preventDefault()
      this.$emit(this.footerButtons.left.action)
    },
    titleClick() {
      this.$emit("titleClick")
    },
    async doLogout() {
      await this.$store.dispatch("signOut")
      this.$router.push({ name: "app_start" })
    }
  }
}
</script>

<style scoped>
.titles {
  width: 80%;
}
</style>
