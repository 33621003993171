<template lang="pug">
  app-container(title="Hilfe" sub-title="Pendelkinder" :fetching="isLoading" @submit="goBack")
    div(v-if="page")
      .content(v-html="page.body")
</template>

<script>
import AppContainer from "../app_container.vue"
import { mapState, mapActions } from "vuex"

export default {
  components: {
    AppContainer
  },
  props: {
    permalink: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapState({
      pages: ({ pages }) => pages.pages,
      isLoading: ({ pages }) => pages.isPageLoading
    }),
    page() {
      return this.pages[this.permalink]
    }
  },
  created() {
    this.loadPage(this.permalink)
  },
  methods: {
    ...mapActions(["loadPage"])
  }
}
</script>

<style lang="scss" scoped>
.content :deep {
  p {
    margin: 0;
  }

  p {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}
</style>
