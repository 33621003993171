var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Einstellungen",
        loading: _vm.loading,
        "sub-title": "Pendelkinder",
        "footer-buttons": _vm.footerButtons
      },
      on: {
        submit: _vm.submit,
        openAbonnement: _vm.openAbonnement,
        openTestphase: _vm.openTestphase
      }
    },
    [
      _c(
        "section",
        [
          _c("h3", [_vm._v("Pendelkinder")]),
          _vm._l(_vm.children, function(child) {
            return _c("child-input", {
              key: child.id,
              attrs: { child: child },
              on: { submit: _vm.removeChild, change: _vm.updateChild }
            })
          }),
          _c("child-input", {
            attrs: { child: _vm.newChild, action: "new" },
            on: { submit: _vm.addChild }
          })
        ],
        2
      ),
      _c("section", [
        _c("h3", [_vm._v("Elternteil 1")]),
        _c("div", { staticClass: "big-input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.name,
                expression: "name",
                modifiers: { trim: true }
              }
            ],
            staticClass: "input",
            attrs: { id: "name", type: "text", placeholder: "Dein Vorname" },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("div", { staticClass: "appended" }, [
            _c("button", { on: { click: _vm.updateName } }, [_vm._v("Ändern")])
          ])
        ]),
        _c("div", { staticClass: "big-input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.username,
                expression: "username",
                modifiers: { trim: true }
              }
            ],
            staticClass: "input",
            attrs: {
              id: "username",
              type: "text",
              placeholder: "Dein Username",
              maxlength: "2"
            },
            domProps: { value: _vm.username },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.username = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("div", { staticClass: "appended" }, [
            _c("button", { on: { click: _vm.updateUsername } }, [
              _vm._v("Ändern")
            ])
          ])
        ]),
        _c("div", { staticClass: "big-input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.email,
                expression: "email",
                modifiers: { trim: true }
              }
            ],
            staticClass: "input",
            attrs: {
              id: "email",
              type: "text",
              readonly: "",
              placeholder: "Deine E-Mail-Adresse"
            },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("div", { staticClass: "appended" }, [
            _c("button", [_vm._v("Aktiv")])
          ])
        ]),
        _c("div", { staticClass: "big-input-group" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.parentMode,
                  expression: "parentMode"
                }
              ],
              staticClass: "input",
              class: _vm.selectClass(_vm.parentMode),
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.parentMode = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.updateParentMode
                ]
              }
            },
            [
              _c("option", { attrs: { value: "" } }, [
                _vm._v("Welcher Elternteil bist Du?")
              ]),
              _c("option", { attrs: { value: "mom" } }, [_vm._v("Mutter")]),
              _c("option", { attrs: { value: "dad" } }, [_vm._v("Vater")])
            ]
          ),
          _c("div", { staticClass: "appended" }, [
            _c("button", [_vm._v(_vm._s(_vm.user.username))])
          ])
        ])
      ]),
      _c("section", [
        _c("h3", [_vm._v("Elternteil 2")]),
        _c("div", { staticClass: "big-input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.parentEmail,
                expression: "parentEmail",
                modifiers: { trim: true }
              }
            ],
            staticClass: "input",
            attrs: {
              id: "parent",
              type: "text",
              placeholder: "E-Mail-Adresse anderer Elternteil"
            },
            domProps: { value: _vm.parentEmail },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.parentEmail = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("div", { staticClass: "appended" }, [
            _c("button", { on: { click: _vm.invite } }, [
              _vm._v(_vm._s(_vm.parentConnectionTitle))
            ])
          ])
        ])
      ]),
      _c(
        "section",
        [
          _c("h3", [_vm._v("Allgemein")]),
          _c("div", { staticClass: "big-input-group" }, [
            _c("input", {
              staticClass: "input",
              attrs: { type: "text", value: "Aktivitäten", readonly: "" }
            }),
            _c("div", { staticClass: "appended" }, [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goTo({ name: "news_download" })
                    }
                  }
                },
                [_vm._v("Download")]
              )
            ])
          ]),
          _c("div", { staticClass: "big-input-group" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.state,
                    expression: "state"
                  }
                ],
                staticClass: "input",
                class: _vm.selectClass(_vm.state),
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.state = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("Ferien / Bundesland (optional)")
                ]),
                _vm._l(_vm.stateOptions, function(option) {
                  return _c("option", { domProps: { value: option.value } }, [
                    _vm._v(_vm._s(option.text))
                  ])
                })
              ],
              2
            ),
            _c("div", { staticClass: "appended" }, [
              _c("button", { on: { click: _vm.reset } }, [_vm._v("Hinzufügen")])
            ])
          ]),
          _c("edit-password-modal", {
            staticClass: "big-input-group",
            on: {
              startLoading: function($event) {
                _vm.loading = true
              },
              doneLoading: function($event) {
                _vm.loading = false
              }
            }
          }),
          _c("div", { staticClass: "big-input-group" }, [
            _c("input", {
              staticClass: "input",
              class: { inactive: !_vm.isPushActive },
              attrs: {
                type: "text",
                value: "Push-Benarchrichtigung",
                readonly: ""
              }
            }),
            _c("div", { staticClass: "appended" }, [
              _c("button", { on: { click: _vm.togglePushNotifications } }, [
                _vm._v(_vm._s(_vm.pushActive))
              ])
            ])
          ])
        ],
        1
      ),
      _c("section", [
        _c("h3", [_vm._v("Netzwerkcode")]),
        _c("div", { staticClass: "big-input-group" }, [
          _c("input", {
            ref: "code",
            staticClass: "input",
            attrs: { type: "text", readonly: "" },
            domProps: { value: _vm.code }
          }),
          _c("div", { staticClass: "appended" }, [
            _c("button", { on: { click: _vm.copyCode } }, [_vm._v("Kopieren")])
          ])
        ])
      ]),
      _c(
        "section",
        [
          _c("h3", [_vm._v("Account löschen")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.user.markedAsDeletedAt,
                  expression: "user.markedAsDeletedAt"
                }
              ]
            },
            [
              _c("p", [
                _vm._v("Dein Account wird in den nächsten 24 Stunden gelöscht.")
              ]),
              _c("div", { staticClass: "big-input-group" }, [
                _c("input", {
                  staticClass: "input",
                  attrs: {
                    type: "text",
                    value: "Löschen abbrechen",
                    readonly: ""
                  }
                }),
                _c("div", { staticClass: "appended" }, [
                  _c("button", { on: { click: _vm.cancelDeletion } }, [
                    _vm._v("Abbrechen")
                  ])
                ])
              ])
            ]
          ),
          _c("delete-account-modal", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.user.markedAsDeletedAt,
                expression: "!user.markedAsDeletedAt"
              }
            ],
            staticClass: "big-input-group",
            on: {
              startLoading: function($event) {
                _vm.loading = true
              },
              doneLoading: function($event) {
                _vm.loading = false
              }
            }
          })
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "margin info-text" },
        [_c("vue-markdown", [_vm._v(_vm._s(_vm.accountText))])],
        1
      ),
      _c(
        "p",
        { staticClass: "margin off info-text" },
        [
          _c(
            "router-link",
            {
              staticClass: "underlined",
              attrs: { to: { name: "page", params: { permalink: "help" } } }
            },
            [_vm._v("HILFE")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }