<template lang="pug">
app-container(title="Password vergessen" sub-title="Pendelkinder" :footer-buttons="footerButtons" @submit="submit" @goBack="goBack")
  p Gib hier deine E-Mailadresse an, mit der du dich bei Pendelkinder registriert hast. Du bekommst dann in Kürze eine E-Mail mit einer Anleitung, wie du dein Passwort zurücksetzen kannst.
  p
    input.uk-input(type="email" v-model="email")
</template>

<script>
import AppContainer from "../app_container.vue"

export default {
  components: {
    AppContainer
  },
  props: {
    givenEmail: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      email: null
    }
  },
  computed: {
    footerButtons() {
      return {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }
    }
  },
  mounted() {
    this.email = this.givenEmail
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch("newPassword", {
          email: this.email
        })
        this.notify(
          "Du erhältst in wenigen Minuten eine E-Mail mit der Anleitung, wie du dein Passwort zurücksetzen kannst.",
          "success"
        )
        this.$router.push({ name: "new_user_session" })
      } catch (error) {
        this.notify(
          "Das Passowrt konnte nicht zurückgesetzt werden. Bitte prüfe die E-Mailadresse und versuche es erneut.",
          "danger"
        )
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
