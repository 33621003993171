var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        fetching: _vm.fetching
      },
      on: { submit: _vm.onSubmit }
    },
    [
      _c(
        "div",
        { staticClass: "uk-text-center" },
        [
          _c("h1", [_vm._v("Einladung")]),
          _vm.sameAccount
            ? [
                _c("p", [
                  _vm._v(
                    "Du bist mit dem selben Account eingeloggt, mit dem du dich verbinden möchtest."
                  )
                ])
              ]
            : _vm.validInvite
            ? [
                _c("p", [
                  _vm._v(
                    "Du wurdest von " +
                      _vm._s(_vm.invite.inviter.email) +
                      " eingeladen, gemeinsam die Pendelkinder-App zu nutzen. Um beide Konten miteinander zu verbinden, nehme die Einladung mit einem klick an."
                  )
                ]),
                _c("p", { staticClass: "uk-margin-large-top" }, [
                  _c(
                    "button",
                    { staticClass: "uk-button", on: { click: _vm.onSubmit } },
                    [_vm._v("Einladung annehmen")]
                  )
                ])
              ]
            : [
                _vm._v(
                  "Es ist etwas schief gegangen. Bitte versuche den Link aus der E-Mail erneut zu öffnen."
                )
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }