<template lang="pug">
app-container(title="Aufgaben" :sub-title="year" :footerButtons="footerButtons" :fetching="loadingTodos" :showBottom="true" @submit="addTodo")
  .todos
    table.list(v-if="todosList.length")
      tbody
        tr(v-for="todo, i in todosList")
          th {{ i+1 }}.
          td.day
            todo-record(:todo="todo")
    p.flex(v-else)
      i Keine Aufgaben eingetragen
</template>

<script>
import moment from "moment"
moment.locale("de")

import AppContainer from "../app_container.vue"
import TodoRecord from "./todo_record.vue"
import { mapState, mapActions } from "vuex"

export default {
  components: {
    AppContainer,
    TodoRecord
  },
  data: () => ({
    year: moment(new Date()).format("YYYY")
  }),
  computed: {
    ...mapState({
      accountMembers: ({ account }) => account.accountMembers,
      todos: ({ todos }) => todos.todos,
      loadingTodos: ({ todos }) => todos.loadingTodos
    }),
    footerButtons() {
      return {
        center: {
          type: "plus"
        }
      }
    },
    todosList() {
      return Object.values(this.todos)
    }
  },
  created() {
    this.loadTodos()
  },
  methods: {
    ...mapActions(["loadTodos"]),
    addTodo() {
      this.$router.push({ name: "new_todo" })
    }
  }
}
</script>
