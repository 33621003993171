import fetchApi from "../lib/fetch_api.js"
import moment from "moment"
import _ from "lodash/string"
moment.locale("de")

const buildEventParams = function(attrs) {
  let headers
  let formData
  if (attrs.files) {
    formData = new FormData()
    Object.keys(attrs).forEach(key => {
      const value = attrs[key]
      const railsKey = _.snakeCase(key)
      if (key === "files") {
        for (let i = 0, n = value.length; i < n; i++) {
          formData.append(`event[${railsKey}][]`, value[i])
        }
        return
      }

      if (attrs[key]) {
        formData.append(`event[${railsKey}]`, attrs[key])
      }
    })

    headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-Key-Inflection": "camel",
      Accept: "application/json"
    }
  }

  return {
    data: formData || attrs,
    headers
  }
}

const updateInviteEventState = async function(
  { commit, dispatch },
  { eventId, action }
) {
  try {
    const response = await fetchApi(`/api/events/${eventId}/${action}`, {
      method: "PUT"
    })
    const { error, ...event } = await response.json()
    if (error) {
      throw new Error(error)
    }
    commit("setEventAttrs", event)
  } catch (error) {
    const message = "Termin konnte nicht gespeichert werden"
    dispatch("notify", { message }, { root: true })

    return false
  }
  return true
}

export default {
  state: {
    events: {},
    holidays: [],
    fetchingEvents: false
  },
  getters: {
    repeatableEvents(state) {
      const events = Object.values(state.events).flat()
      return events.filter(event => event.repeatType != "once")
    }
  },
  mutations: {
    setEventsArray(state, events) {
      state.events = events
    },
    setEventAttrs(state, event) {
      const _event = state.events[event.id] || {}
      event.startTime = moment(event.startAt).format("HH:mm")
      event.endTime = moment(event.endAt).format("HH:mm")
      state.events = { ...state.events, [event.id]: { ..._event, ...event } }
    },
    addEvent(state, event) {
      event.startTime = moment(event.startAt).format("HH:mm")
      event.endTime = moment(event.endAt).format("HH:mm")

      state.events = { ...state.events, [event.id]: event }
    },
    removeEvents(state) {
      state.events = {}
    },
    removeEvent(state, event) {
      let events = state.events
      delete events[event.id]
      state.events = { ...state.events }
    },
    removeHolidays(state) {
      state.holidays = []
    },
    addHoliday(state, holiday) {
      state.holidays.push(holiday)
    },
    clearSession(state) {
      state.events = {}
    }
  },
  actions: {
    async updateEvent({ commit }, attrs) {
      commit("setEventAttrs", attrs) // Update store and UI immediately for responsiveness

      const { data, headers } = buildEventParams(attrs)

      const response = await fetchApi(`/api/events/${attrs.id}`, {
        method: "PUT",
        data,
        headers
      })
      const { error, ...event } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setEventAttrs", event) // Update store and UI with potential changes from server
      }
    },
    async loadEvents({ commit, state, dispatch }) {
      state.fetchingEvents = true
      let customError = null
      const response = await fetchApi("/api/events", { method: "GET" })
      const { error, events, holidays } = await response.json()
      try {
        if (response.status === 401) {
          commit("clearSession")
          customError = new Error("Not Authenticated")
        } else if (response.status === 402) {
          if (document.location.pathname !== "/users/subscription/new") {
            // document.location.href = "/users/subscription/new"
            dispatch("notify", { message: "Dein Abo konnte nicht erkannt werden. Bitte prüfe dein Apo." })
          }
        } else if (!error) {
          commit("removeEvents")
          commit("removeHolidays")
          Object.values(events).forEach(event => commit("addEvent", event))
          holidays.forEach(holiday => commit("addHoliday", holiday))
        }
      } catch (e) {
      } finally {
        state.fetchingEvents = false
      }
      if (customError) {
        throw customError
      }
    },
    async createEvent({ commit }, attrs) {
      const { data, headers } = buildEventParams(attrs)
      const response = await fetchApi("/api/events", {
        method: "POST",
        data,
        headers
      })
      const { error, ...event } = await response.json()

      if (!error) {
        commit("addEvent", event)
      }
    },
    async removeEvent({ commit }, attrs) {
      const response = await fetchApi(`/api/events/${attrs.id}`, {
        method: "DELETE"
      })
      const { error, ...event } = await response.json()

      if (!error) {
        commit("removeEvent", event)
      }
    },
    async addExceptionTime({ commit }, attrs) {
      const data = { date: attrs.date }
      const response = await fetchApi(
        `/api/events/${attrs.eventId}/exception_time`,
        { method: "PUT", data }
      )
      const { error, ...event } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setEventAttrs", event) // Update store and UI with potential changes from server
      }
    },
    async confirmEvent(options, eventId) {
      return await updateInviteEventState(options, {
        eventId,
        action: "confirm"
      })
    },
    async declineEvent(options, eventId) {
      return await updateInviteEventState(options, {
        eventId,
        action: "decline"
      })
    }
  }
}
