import fetchApi from "../lib/fetch_api.js"

class BackendError extends Error {
  constructor(json, statusCode) {
    super(json.error || JSON.stringify(json))
    this.statusCode = statusCode
    this.json = json
  }
}

export default {
  actions: {
    async fetchJSON({ commit }, { path, method, data }) {
      const response = await fetchApi(path, { method, data })

      switch (response.status) {
        case 204:
          return null // No Content
        case 401:
          commit("clearSession") // Unauthorized
      }

      const json = await response.json()

      if (!response.ok) {
        const e = new BackendError(json, response.status)
        throw e
      }

      return json
    },
    ...["GET", "POST", "PUT", "DELETE"].reduce(
      (actions, method) => ({
        ...actions,
        [method]: ({ dispatch }, params) =>
          dispatch("fetchJSON", { ...params, method })
      }),
      {}
    )
  }
}
