<template lang="pug">
  .app-onboarding-start.uk-text-center
    h4.title Kinder
    p Alle Aktivitäten in der App können euren Kindern zugeordnet werden. Hierfür benötigen wir die Namen eurer Kinder. 

    h4 Wie heißen deine Kinder?
    child-input(v-for="child in children" :child="child" :key="child.id" @submit="removeStoredChild" @change="updateStoredChild")
    child-input(:child="newChild" action="new" @submit="appendChild")
</template>

<script>
import ChildInput from "../users/child_input.vue"
import { mapState, mapActions } from "vuex"
import onboardingForm from "./mixins/onboarding_form"

export default {
  components: { ChildInput },
  mixins: [onboardingForm],
  created() {
    this.validateForm()
  },
  data() {
    return {
      newChild: {
        name: "",
        id: -1
      }
    }
  },
  computed: {
    ...mapState({
      children: ({ children }) => children.children
    })
  },
  methods: {
    ...mapActions(["createChild", "removeChild", "updateChild"]),
    async appendChild(child) {
      await this.createChild(child)
      child.name = ""
      this.validateForm()
    },
    async removeStoredChild(child) {
      await this.removeChild(child)
      this.validateForm()
    },
    async updateStoredChild(child) {
      if (!child.name || !child.name.length) {
        await this.removeChild(child)
      } else {
        await this.updateChild(child)
      }
      this.validateForm()
    },
    validateForm() {
      this.$emit("isFormValidChange", Boolean(Object.values(this.children).length))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.big-input-group {
  button {
    background: $dove-gray-color;
    &:first-child {
      margin-right: 15px;
    }

    &.dad.selected {
      background: $turkies-color;
    }
    &.mom.selected {
      background: $orange-color;
    }
  }
}
</style>
