<template lang="pug">
  .big-input-group.mt-2
    input.input(type="text" v-model.trim="child.name" ref="input" placeholder="Name des Kindes" @blur="update")
    .appended
      button(@click="buttonClick") {{ buttonTitle }}
</template>

<script>
export default {
  props: {
    child: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: "edit"
    }
  },
  computed: {
    buttonTitle() {
      return this.action == "edit" ? "Löschen" : "Hinzufügen"
    }
  },
  methods: {
    buttonClick() {
      this.$emit("submit", this.child)
    },
    update() {
      if (this.action != "edit") {
        return
      }
      this.$emit("change", this.child)
    }
  }
}
</script>
