<template lang="pug">
  a(v-if="isLink" :href="link" :class="linkClass") {{ value }}
  span(v-else) {{ value }}
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: String
    }
  },
  computed: {
    isLink() {
      return ["mobile", "phone", "email"].includes(this.name)
    },
    link() {
      switch (this.name) {
        case "mobile":
        case "phone":
          return `tel://${this.value.replace(/[^0-9]/g, "")}`
        case "email":
          return `mailto:${this.value}`
      }

      return null
    },
    linkClass() {
      if (["mobile", "phone"].includes(this.name)) {
        return "call"
      }
      return null
    }
  }
}
</script>

<style scoped>
.call {
  display: block;
  background: url(./icon-call.png);
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 22px;
}
</style>
