<template lang="pug">
  app-container(title="Anmelden" sub-title="Pendelkinder" :fetching="submitting" @submit="onSubmit")
    div(v-if="submitting")
      vue-markdown.text-center.text-small.mt-2(:source="description")
    div(v-else)
      p.text-center
        strong {{ description }}
      .text-center(v-if="buttonTitle")
        router-link(:to="{ name: 'root' }") {{ buttonTitle }}
</template>

<script>
import AppContainer from "../app_container.vue"
import VueMarkdown from "vue-markdown"

export default {
  components: {
    AppContainer,
    VueMarkdown
  },
  props: {
    token: {
      required: true,
      type: String
    }
  },
  data: () => ({
    state: "initial",
    errorMessage: null
  }),
  computed: {
    description() {
      if (this.submitting) {
        return "E-Mail-Adresse wird bestätigt"
      }
      if (this.state === "error") {
        if (this.errorMessage == "email_already_confirmed") {
          return "Die E-Mail-Adresse wurde bereits bestätigt."
        }
      }
      if (this.state === "done") {
        return "Die E-Mail-Adresse wurde erfolgreich bestätigt"
      }

      return "Die E-Mail-Adresse konnte nicht bestätigt werden."
    },
    submitting() {
      return this.state === "initial" || this.state === "sending"
    },
    buttonTitle() {
      if (this.state === "done") {
        return null
      }

      return "Zurück"
    }
  },
  mounted() {
    this.confirmAccount()
  },
  methods: {
    onSubmit() {
      this.$router.push({ name: "settings" })
    },
    async confirmAccount() {
      this.state = "sending"
      this.errorMessage = null

      try {
        await this.$store.dispatch("sendConfirmationToken", {
          token: this.token
        })
        this.state = "done"
      } catch (e) {
        this.errorMessage = e.message
        this.state = "error"
      }
    }
  }
}
</script>
