<template lang="pug">
.onboarding
  img.logo(src="../../images/logo.png")
  h1 {{ t("start.title") }}

  uploads(
    :files="files"
    :loop="true"
  )

  .details
    h2 {{ t("start.sub_title") }}

    button.sign-up(@click="goTo({ name: 'new_user_registration' })") {{ t("start.buttons.sign_up") }}
    button.activation-code(@click="goTo({ name: 'new_activation_code_session' })") {{ t("start.buttons.activation_code") }}
    button.sign-in(@click="goTo({ name: 'new_user_session' })") {{ t("start.buttons.login") }}

    .disclaimer {{ t("start.disclaimer") }}
</template>

<script>
/* globals require */
import Uploads from "../helpers/uploads.vue"

export default {
  components: {
    Uploads
  },
  computed: {
    files() {
      return [...Array(4)].map((array, index) => {
        return {
          contentType: "image/jpg",
          uploadUrl: require(`../../images/onboarding-${index + 1}.jpg`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.onboarding {
  margin-top: -70px;

  h1 {
    margin: 5px 0 20px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    color: $dove-gray-color;
    text-align: center;
  }

  .logo {
    width: 30vw;
    text-align: center;
    display: block;
    margin: auto;
  }

  :deep(.VueCarousel-pagination) {
    margin-top: -20px;
    z-index: 10;

    .VueCarousel-dot-container {
      margin-top: 0px !important;
    }

    .VueCarousel-dot {
      outline: none;
      margin-top: 0px !important;
      padding: 2px !important;
      background-color: $middle-gray-color !important;
    }

    .VueCarousel-dot--active {
      background-color: $turkies-color !important;
    }
  }

  .details {
    margin-top: 30px;
    text-align: center;

    h2 {
      color: $dove-gray-color;
      font-size: 1rem;
      line-height: 1;
      font-weight: bold;
      margin: 0 0 2px;
    }

    p {
      margin: 0;
    }

    button {
      display: block;
      width: 75vw;
      margin: 10px auto;
      border: none;
      border-radius: 20px;
      padding: 9px;
      color: white;
      font-weight: bold;

      &.sign-up {
        background: $turkies-color;
      }

      &.activation-code {
        background: $dove-gray-color;
      }

      &.sign-in {
        background: $light-gray-color;
      }
    }

    .special {
      margin-top: 10px;
      text-transform: uppercase;
      color: $orange-color;
      font-weight: bold;
      font-size: 0.95em;

      span {
        font-size: 1.4em;
      }
    }
    
    .special-disclaimer {
      font-size: 9px;
    }

    .disclaimer {
      width: 75vw;
      margin: auto;
      font-size: 9px;
      line-height: 1.2;
      padding: 10px 0px;
    }
  }
}
</style>
