var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      staticClass: "small",
      attrs: {
        title: "Handzettel",
        footerButtons: _vm.footerButtons,
        fetching: _vm.fetching,
        "show-bottom": false
      },
      on: { submit: _vm.addUpload }
    },
    [
      _c(
        "template",
        { slot: "sub-title" },
        [
          _c("inline-select-field", {
            attrs: { options: _vm.childOptions },
            model: {
              value: _vm.childId,
              callback: function($$v) {
                _vm.childId = $$v
              },
              expression: "childId"
            }
          })
        ],
        1
      ),
      _vm.hasUploads
        ? _c(
            "ul",
            { staticClass: "list uploads" },
            [
              _vm._l(_vm.sortedUploads, function(uploadObject) {
                return [
                  _c("li", { staticClass: "header" }, [
                    _vm._v(_vm._s(uploadObject.month))
                  ]),
                  _vm._l(uploadObject.group, function(upload) {
                    return _c(
                      "router-link",
                      {
                        key: upload.id,
                        staticClass: "upload",
                        attrs: {
                          tag: "li",
                          to: { name: "upload", params: { id: upload.id } }
                        }
                      },
                      [_vm._v(_vm._s(_vm.titleFor(upload)))]
                    )
                  })
                ]
              })
            ],
            2
          )
        : _c("p", { staticClass: "flex" }, [
            _c("i", [_vm._v("Keine Uploads eingetragen")])
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }