<template lang="pug">
div
  .message(v-if="message.upload" :class="listClass")
    img(:src="message.upload" @click="showImage")
    .date(v-if="!hasBody") {{ createdAt }}
  .message(v-if="hasBody" :class="listClass")
    .bubble
      div
        vue-markdown(:key="message.id" :emoji="false") {{ message.body }}
    .date {{ createdAt }}
</template>

<script>
import moment from "moment"
moment.locale("de")

import VueMarkdown from "vue-markdown"
import { mapState } from "vuex"

export default {
  components: { VueMarkdown },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      profile: ({ profile }) => profile.profile,
      user: ({ user }) => user.user
    }),
    listClass() {
      let classes = [this.message.parentMode]
      if (this.message.userId == this.user.id) {
        classes.push("me")
      } else {
        classes.push("other")
      }

      return classes
    },
    createdAt() {
      return `${moment(this.message.createdAt).format("DD.MM.YY | HH:mm")} Uhr`
    },
    hasBody() {
      return Boolean(this.message.body && this.message.body !== "")
    }
  },
  methods: {
    showImage() {
      this.$emit("showImage", this.message.upload)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/stylesheets/variables.scss";

.message {
  margin-bottom: 5px;

  &.me {
    text-align: right;
  }

  &.mom {
    .bubble {
      background: $orange-color;
    }
  }

  &.dad {
    .bubble {
      background: $turkies-color;
    }
  }

  .bubble {
    line-height: 17px;
    color: #fff;
    display: inline-block;
    padding: 8px 12px;
    border-radius: 20px;
    max-width: 85%;

    &.is-image {
      padding: 0;
    }

    :deep(p) {
      margin: 0;
      line-height: 1;
    }
  }

  img {
    max-width: 40vw;
    max-height: 40vh;
    border-radius: 6px;
  }

  .date {
    font-size: 12px;
    padding: 2px 12px 8px;
  }
}
</style>
