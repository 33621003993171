<template lang="pug">
app-container(title="Anmelden" sub-title="Pendelkinder" @submit="onSubmit")
  .container
    h1 Hallo liebe, lieber {{ name }}
    p Um die Pendelkinder-App im vollem Umfang zu verwenden, benötigen wir von dir noch ein paar Angaben. Bist du bereit, dann klicke auf den blauen Button, um zu starten.
</template>

<script>
import AppContainer from "../app_container.vue"
import VueMarkdown from "vue-markdown"
import { mapState } from "vuex"

export default {
  components: { AppContainer, VueMarkdown },
  computed: mapState({
    name: ({ user }) => {
      return user.user.name
    }
  }),
  methods: {
    onSubmit() {
      this.$router.push({ name: "settings" })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
}

h1 {
  font-size: 20px;
  color: #6db5e4;
}

.box {
  margin-top: 40px;
  border: 1px #808080 solid;
  padding: 5px;

  a {
    color: #808080;
    text-decoration: none;
  }
}
</style>
