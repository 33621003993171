var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: _vm.pageTitle,
        fetching: _vm.loading,
        footerButtons: _vm.footerButtons
      },
      on: {
        submit: _vm.addUpload,
        goBack: _vm.goBack,
        deleteUpload: _vm.deleteUpload
      }
    },
    [
      _c("div", { staticClass: "upload" }, [
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "strong-input-group" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title"
                        }
                      ],
                      attrs: { placeholder: "Titel des Dokuments" },
                      domProps: { value: _vm.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.title = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "left-col" },
                    [
                      _c("multi-select", {
                        attrs: {
                          options: _vm.childrenOptions,
                          placeholder: "Kind",
                          tip: "Kind/er auswählen"
                        },
                        model: {
                          value: _vm.childIds,
                          callback: function($$v) {
                            _vm.childIds = $$v
                          },
                          expression: "childIds"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("uploads", { attrs: { files: _vm.uploadedFiles } }),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("input", {
                      attrs: {
                        placeholder: "Datei",
                        type: "file",
                        multiple: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.processFiles($event)
                        }
                      }
                    })
                  ])
                ])
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }