var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "onboarding" },
    [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../../images/logo.png") }
      }),
      _c("h1", [_vm._v(_vm._s(_vm.t("start.title")))]),
      _c("uploads", { attrs: { files: _vm.files, loop: true } }),
      _c("div", { staticClass: "details" }, [
        _c("h2", [_vm._v(_vm._s(_vm.t("start.sub_title")))]),
        _c(
          "button",
          {
            staticClass: "sign-up",
            on: {
              click: function($event) {
                return _vm.goTo({ name: "new_user_registration" })
              }
            }
          },
          [_vm._v(_vm._s(_vm.t("start.buttons.sign_up")))]
        ),
        _c(
          "button",
          {
            staticClass: "activation-code",
            on: {
              click: function($event) {
                return _vm.goTo({ name: "new_activation_code_session" })
              }
            }
          },
          [_vm._v(_vm._s(_vm.t("start.buttons.activation_code")))]
        ),
        _c(
          "button",
          {
            staticClass: "sign-in",
            on: {
              click: function($event) {
                return _vm.goTo({ name: "new_user_session" })
              }
            }
          },
          [_vm._v(_vm._s(_vm.t("start.buttons.login")))]
        ),
        _c("div", { staticClass: "disclaimer" }, [
          _vm._v(_vm._s(_vm.t("start.disclaimer")))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }