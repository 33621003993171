<template lang="pug">
  div
    input.expandable(:value="values" type="text" :placeholder="placeholder" readonly @click="showModal")

    vk-modal.uk-modal-full(:show.sync="modalVisible")
      icon.save(icon="check" size="l" @click="save" tag="button")

      .uk-padding-large.selection(uk-height-viewport)
        p
          template(v-if="!selectedOptions.length")
            | {{ tip }}
          template(v-else)
            span(v-for="option in selectedOptions" @click="removeOption(option)")
              | {{ option.value }}
              icon(icon="close" size="s")
        ul
          li(v-for="option in unselectedOptions" @click="selectOption(option)") {{ option.value }}
</template>

<script>
import Icon from "./icon.vue"

export default {
  components: { Icon },
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: null
    },
    tip: {
      type: String,
      default: ""
    },
    classNames: {
      type: String,
      default: null
    },
    allLabel: {
      type: String,
      default: "Alle"
    }
  },
  data: () => {
    return {
      modalVisible: false,
      selectedOptions: []
    }
  },
  computed: {
    values() {
      if (!this.selectedOptions) {
        return
      }
      if (
        this.selectedOptions.length == this.options.length &&
        this.options.length > 1
      ) {
        return this.allLabel
      }

      return this.selectedOptions
        .map(selectedOption => selectedOption.value)
        .join(", ")
    },
    unselectedOptions() {
      return this.options.filter(option => {
        return !this.selectedOptions.find(
          selectedOption => selectedOption.id == option.id
        )
      })
    }
  },
  mounted() {
    if (this.value) {
      this.selectedOptions = this.options.filter(option =>
        this.value.includes(option.id)
      )
    }
  },
  methods: {
    showModal() {
      this.modalVisible = true
    },
    save() {
      this.modalVisible = false
      this.$emit("input", this.selectedOptions.map(option => option.id))
    },
    selectOption(option) {
      this.selectedOptions.push(option)
    },
    removeOption(option) {
      const index = this.selectedOptions.indexOf(option)
      this.$delete(this.selectedOptions, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.save {
  top: 0;
  right: 0;
  padding: 20px;
  position: absolute;
  z-index: 1010;
}

span {
  padding: 5px 10px 2px;
  background: #00baee;
  border-radius: 15px;
  color: white;
  margin-right: 5px;

  i {
    padding-left: 5px;
  }
}

.selection * {
  font-size: 1.2rem;
}

ul li {
  line-height: 2;
}
</style>
