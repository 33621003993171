import fetchApi from "../lib/fetch_api.js"
import { unauthorizedHandler } from "../lib/error_handler"

export default {
  state: {
    children: {}
  },
  getters: {
    childNames: state => ids => {
      if (!ids) {
        return ""
      }
      const children = Object.values(state.children)
      const selectedChildren = children.filter(child => {
        return ids.includes(child.id)
      })
      if (children.length > 1 && selectedChildren.length == children.length) {
        return "Alle"
      }

      return selectedChildren.map(child => child.name).join(", ")
    }
  },
  mutations: {
    setChildrenArray(state, children) {
      state.children = children
    },
    setChildAttrs(state, child) {
      const _child = state.children[child.id] || {}
      state.children = {
        ...state.children,
        [child.id]: { ..._child, ...child }
      }
    },
    clearChildren(state) {
      state.children = {}
    },
    addChild(state, child) {
      state.children = { ...state.children, [child.id]: child }
    },
    removeChild(state, child) {
      let children = state.children
      delete children[child.id]
      state.children = { ...state.children }
    },
    clearSession(state) {
      state.children = {}
    }
  },
  actions: {
    async updateChild({ commit }, attrs) {
      commit("setChildAttrs", attrs) // Update store and UI immediately for responsiveness

      const response = await fetchApi(`/api/children/${attrs.id}`, {
        method: "PUT",
        data: attrs
      })
      const { error, ...child } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setChildAttrs", child) // Update store and UI with potential changes from server
      }
    },
    async loadChildren({ commit }) {
      const response = await fetchApi("/api/children", { method: "GET" })
      const { error, ...children } = await response.json()

      if (!error) {
        commit("clearChildren")
        Object.values(children).forEach(child => {
          if (!child.deletedAt) {
            commit("addChild", child)
          }
        })
      }
    },
    async createChild({ dispatch, commit }, attrs) {
      try {
        const child = await dispatch("POST", {
          path: "/api/children",
          data: attrs
        })
        commit("addChild", child)
      } catch (error) {
        unauthorizedHandler.handle(error)
      }
    },
    async removeChild({ dispatch, commit }, attrs) {
      try {
        const child = await dispatch("DELETE", {
          path: `/api/children/${attrs.id}`
        })
        commit("removeChild", child)
      } catch (error) {
        unauthorizedHandler.handle(error)
      }
    }
  }
}
