<template lang="pug">
app-container(title="Anmelden" sub-title="Pendelkinder" :fetching="submitting" :footer-buttons="footerButtons" @submit="onSubmit")
  form.signupForm(@submit.prevent="onSubmit")
    input-group(name="name" type="text" label="Vorname" v-model.trim="name" :autofocus="true")
    input-group(name="username" type="text" label="Benutzername" sub-label="Kürzel bestehend aus 2 Buchstaben" v-model.trim="username" :max-length="2")
    input-group(name="email" type="email" label="E-Mail Adresse" v-model.trim="email")
    input-group(name="password" type="password" label="Passwort" v-model.trim="password")
    input-group(name="passwordConfirmation" type="password" label="Passwort" sub-label="Wiederholung" v-model.trim="passwordConfirmation")

    .small-input-group.text-small
      .custom-checkbox.boolean
        input(id="terms", type="checkbox", v-model="terms")
        label(for="terms")
          a(href="#" @click.prevent="showTermsAndConditions = true")
            | AGBs
          |
          |  akzeptieren
    .small-input-group.text-small
      .custom-checkbox.boolean
        input(id="privacyPolicy", type="checkbox", v-model="privacyPolicy")
        label(for="privacyPolicy")
          a(href="#" @click.prevent="showPrivacy = true")
            | Datenschutz
          |
          | akzeptieren

    vk-modal(:show.sync="showPrivacy" overflow-auto)
      vk-modal-close(@click="showPrivacy = false")
      vk-modal-title(slot="header") Datenschutz
      privacy-policy
      .uk-text-center(slot="footer")
        vk-button.uk-margin-small-right.close-button(@click="showPrivacy = false") Schließen
    vk-modal(:show.sync="showTermsAndConditions" overflow-auto)
      vk-modal-close(@click="showTermsAndConditions = false")
      vk-modal-title(slot="header" style="font-size: 1.7rem;") All­ge­meine Geschäfts­be­din­gun­gen
      terms-and-conditions
      .uk-text-center(slot="footer")
        vk-button.uk-margin-small-right.close-button(@click="showTermsAndConditions = false") Schließen

    //- p.disclaimer.text-center Du kannst die App für 10 Tage kostenlos testen. Nach Ablauf der 10 Tage hast Du weiterhin Zugriff auf den Kalender, alle anderen Funkionen wie der Messanger, die Uploadfunktion und Stundenplan stehen Dir nach 30 Tagen in einem Abo zur Verfügung.
    p.disclaimer.text-center Nach deiner Registrierung kannst du die App für eine Woche kostenlos testen. Um die App nach Ablauf der Testphase weiter nutzen können, hast du die Möglichkeit, die Vollversion für einmalig 9,99 € zu erwerben.
    button.invisible(:disabled="!submittable") Registrieren
</template>

<script>
import AppContainer from "../app_container.vue"
import InputGroup from "../helpers/input_group.vue"
import PrivacyPolicy from "./privacy_policy.vue"
import TermsAndConditions from "./terms_and_conditions.vue"

export default {
  components: {
    AppContainer,
    InputGroup,
    PrivacyPolicy,
    TermsAndConditions
  },
  data: () => ({
    username: "",
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    terms: false,
    showPrivacy: false,
    showTermsAndConditions: false,
    privacyPolicy: false,
    submitting: false,
    submittable: true,
    footerButtons: {
      left: {
        title: "Login",
        link: "new_user_session",
        active: false
      },
      right: {
        title: "Anmelden",
        action: "",
        active: true
      }
    }
  }),
  methods: {
    async onSubmit() {
      this.submitting = true

      const {
        username,
        name,
        email,
        password,
        passwordConfirmation,
        terms,
        privacyPolicy
      } = this
      try {
        await this.$store.dispatch("signUp", {
          username,
          name,
          email,
          password,
          passwordConfirmation,
          termsAndPrivacyPolicy: terms && privacyPolicy
        })

        this.$router.push({ name: "welcome_user" })
      } catch (e) {
        console.log(e)
        this.notify(
          "Ein Fehler ist aufgetreten. Bitte prüfe alle Felder und achte auf die korrekte Schreibweise und die Länge des Passwortes von mindestens 6 Zeichen.",
          "danger"
        )
      }
      this.submitting = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.disclaimer {
  color: #a1a1a1;
  font-size: 0.7rem;
  line-height: 1rem;
  margin-top: 20px;
  margin-bottom: 40px;
}

:deep(input) {
  text-transform: none;
}

:deep(#username) {
  text-transform: uppercase;
}

.uk-modal-title,
.close-button {
  color: $dark-gray-color;
  text-align: center;
}
</style>
