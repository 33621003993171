<template lang="pug">
  #app.full-height
    template(v-if="isLoading")
      transition(name="fade")
      loading-animation
    transition(name="fade" mode="out-in")
      router-view.full-height
</template>

<script>
import LoadingAnimation from "./components/helpers/loading_animation.vue"
import store from "./store.js"
import router from "./router"
import { mapState, mapGetters } from "vuex"

const signedIn = () => store.getters.isUserPresent

window.setDeviceInfo = properties =>
  store.dispatch("setDeviceInfo", JSON.parse(properties))

export default {
  router,
  store,
  components: {
    LoadingAnimation
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    signedIn,
    ...mapState({
      user: ({ user }) => user.user,
      account: ({ account }) => account.account
    }),
    ...mapGetters(["isTestAccount"])
  },
  watch: {
    account(newAccount) {
      this.disconnectUpdateWatcher()
      if (newAccount && newAccount.chatToken) {
        this.connectUpdateWatcher()
      }
    },
    user(newUser) {
      this.renewPush()
      if (!newUser || !this.isTestAccount || !newUser.daysUntilExpired || store.state.user.ignoredTrialWarning) {
        return
      }

      if (newUser.daysUntilExpired <= 5) {
        // not active atm
        // store.state.user.ignoredTrialWarning = true
        // const days =
        //   newUser.daysUntilExpired === 1
        //     ? "einem Tag"
        //     : `${newUser.daysUntilExpired} Tagen`
        // this.notify(
        //   `<h1>Achtung<br>Testphase endet bald</h1><p>In ${days} endet deine Testphase. Gehe unter Settings und klicke auf Abonnement um eine monatliche oder jährliche Lizenz zu erwerben.</p>`,
        //   "expired",
        //   { timeout: 0, closeAll: true }
        // )
      }
    }
  },
  created() {
    store.dispatch("reloadSession")
    window.addEventListener("message", this.receiveMessage, false)

    this.renewPush()
  },
  beforeDestroy() {
    this.disconnectUpdateWatcher()
    window.removeEventListener("message", this.receiveMessage)
  },
  methods: {
    connectUpdateWatcher() {
      if (!this.account || !this.account.chatToken) {
        return
      }
      this.watcher = window.App.cable.subscriptions.create(
        {
          channel: "UpdateChannel",
          room: this.account.chatToken
        },
        {
          connected: () => {
            console.log("connected to update")
          },
          disconnected: () => {
            console.log("disconnected from update")
          },
          received: data => {
            this.receivedUpdate(data)
          }
        }
      )
    },
    disconnectUpdateWatcher() {
      if (this.watcher) {
        this.watcher.unsubscribe()
      }
    },
    receivedUpdate(data) {
      if (!this.user) {
        return
      }

      if (this.$route.name === "news" && app.$children[0].$children[0] && app.$children[0].$children[0].fetchNews) {
        app.$children[0].$children[0].fetchNews()
      }

      if (
        data &&
        data.message &&
        data.message.resource &&
        this.user.id !== data.message.userId
      ) {
        store.dispatch(`load${data.message.resource}`)
      }
    },
    receiveMessage(event) {
      try {
        if (event.data && (typeof event.data === "String" || typeof event.data === "string")) {
          const json = JSON.parse(event.data)
          if (json.action === "pushToken" && this.user) {
            this.$store.dispatch("updatePushToken", json.token)
          } else if (json.action === "refreshUser" && this.user) {
            this.$store.dispatch("refreshUser")
          } else if (json.action === "pushNotification" && this.user && json.notification) {
            if (json.notification.aps && json.notification.aps.alert && this.$route.name !== "messenger") {
              const alert = json.notification.aps.alert
              const text = alert.title && alert.body
                ? `<h1>${alert.title}</h1><p>${alert.body}</p>`
                : `<p>${alert.title || alert.body || ""}</p>`

              const notification = this.notify(text, "notification")
              notification.$el.addEventListener("click", () => {
                switch (json.notification.type) {
                  case "history_entry":
                    this.goToHistoryEntry(json.notification.foreign_id, json.notification.historyable_id, json.notification.historyable_type)
                    break
                  case "message":
                    this.$router.push({ name: "messenger" })
                    break
                }
              })
            }
            this.$store.dispatch("refreshUser")
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    renewPush() {
      const user = this.user
      if (!user || !user.isPushActive) {
        return
      }
      document.location.href = "pendelkinder://renewpush"
    },
    async goToHistoryEntry(newsId, id, historyableType) {
      await this.$http.put(`/api/news/${newsId}`)
      await this.$store.dispatch("refreshUser")

      if (!historyableType) {
        this.$router.push({ name: "news" })
        return
      }

      switch (historyableType) {
        case "Todo":
          this.$router.push({
            name: "edit_todo",
            params: { id }
          })
          return
        case "Event":
          this.$router.push({
            name: "edit_calendar",
            params: { id }
          })
          return
        case "Contact":
          this.$router.push({
            name: "edit_contact",
            params: { id }
          })
          return
        case "Upload":
          this.$router.push({
            name: "upload",
            params: { id }
          })
          return
      }
    }
  }
}
</script>

<style scoped>
#app {
  display: block;
}
</style>
