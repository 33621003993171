<template lang="pug">
app-container(title="Kontakt" sub-title="Pendelkinder" :footerButtons="footerButtons" @deleteContact="deleteContact" @submit="saveContact" @goBack="goBack")
  .contact
    .strong-input-group(v-if="loaded")
      .row
        .col
          input(v-model="firstName" placeholder="Vorname")

      .row
        .col
          input(v-model="lastName" placeholder="Nachname")

      .row
        .col
          input(v-model="phone" placeholder="Telefonnummer")

      .row
        .col
          input(v-model="mobile" placeholder="Mobilnummer")

      .row
        .col
          input(v-model="email" placeholder="E-Mail" type="email")

      .row
        .col
          input(v-model="website" placeholder="Website")

      .row
        .col
          input(v-model="address" placeholder="Adresse")

      .row
        .col
          input(v-model="postalCode" placeholder="Postleitzahl")

      .row
        .col
          input(v-model="city" placeholder="Stadt")

      .row
        .col
          textarea(v-model="notes" placeholder="Weitere Infos")
</template>

<script>
import moment from "moment"
moment.locale("de")

import { mapState } from "vuex"
import AppContainer from "../app_container.vue"

export default {
  components: { AppContainer },
  data() {
    return {
      contact: null,
      firstName: null,
      lastName: null,
      phone: null,
      mobile: null,
      email: null,
      website: null,
      address: null,
      postalCode: null,
      city: null,
      notes: null,
      loaded: false
    }
  },
  computed: {
    ...mapState({
      contacts: ({ contacts }) => contacts.contacts
    }),
    footerButtons() {
      let buttons = {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }

      if (this.contact) {
        buttons.right = {
          title: "Löschen",
          action: "deleteContact"
        }
      }
      return buttons
    }
  },
  created() {
    if (this.$route.params.id) {
      const contact = this.contacts[this.$route.params.id]
      let fields = {}
      if (contact && contact.fields) {
        contact.fields.forEach(field => {
          fields[field.name] = field.value
        })
      }

      // function getFieldValue(key) {
      //   return contact.fields
      // }

      if (contact) {
        this.contact = contact
        this.firstName = contact.firstName
        this.lastName = contact.lastName
        this.phone = fields.phone
        this.mobile = fields.mobile
        this.email = fields.email
        this.website = fields.website
        this.address = fields.address
        this.postalCode = fields.postal_code
        this.city = fields.city
        this.notes = contact.notes
      }
    }
    this.loaded = true
  },
  methods: {
    async saveContact() {
      if (this.firstName && this.lastName) {
        const contact = {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          mobile: this.mobile,
          email: this.email,
          website: this.website,
          address: this.address,
          postalCode: this.postalCode,
          city: this.city,
          notes: this.notes
        }

        try {
          if (this.contact) {
            contact.id = this.contact.id
            await this.$store.dispatch("updateContact", contact)
          } else {
            await this.$store.dispatch("createContact", contact)
          }

          this.$router.go(-1)
        } catch (e) {
          this.notify("Eintrag konnte nicht gespeichert werden.", "danger")
        }
      } else {
        this.notify("Bitte Vor- und Nachname ausfüllen.", "danger")
      }
    },
    async deleteContact() {
      if (confirm("Soll der Eintrag wirklich gelöscht werden?")) {
        await this.$store.dispatch("removeContact", this.contact)
        this.goTo({ name: "contacts" })
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
