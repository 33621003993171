var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "big-input-group mt-2" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.trim",
          value: _vm.child.name,
          expression: "child.name",
          modifiers: { trim: true }
        }
      ],
      ref: "input",
      staticClass: "input",
      attrs: { type: "text", placeholder: "Name des Kindes" },
      domProps: { value: _vm.child.name },
      on: {
        blur: [
          _vm.update,
          function($event) {
            return _vm.$forceUpdate()
          }
        ],
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.$set(_vm.child, "name", $event.target.value.trim())
        }
      }
    }),
    _c("div", { staticClass: "appended" }, [
      _c("button", { on: { click: _vm.buttonClick } }, [
        _vm._v(_vm._s(_vm.buttonTitle))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }