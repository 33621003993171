<template lang="pug">
div
  vk-modal-full(:show="shouldShowImage")
    .fullscreen-image(v-if="shouldShowImage" @click="hideImage")
      img(:src="value")
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    shouldShowImage() {
      return Boolean(this.value)
    }
  },
  watch: {
    shouldShowImage(visible) {
      const viewport = document.querySelector("meta[name=viewport]")
      if (visible) {
        viewport.setAttribute("content", "width=device-width, initial-scale=1, shrink-to-fit=no")
      } else {
        viewport.setAttribute("content", "width=device-width, initial-scale=1, shrink-to-fit=yes, user-scalable=no, maximum-scale=1.0")
      }
    }
  },
  methods: {
    hideImage(event) {
      this.$emit("input", null)
    }
  }
}
</script>

<style lang="scss" scoped>
.fullscreen-image {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background: black;

  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}
</style>
