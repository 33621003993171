import fetchApi from "../lib/fetch_api.js"
import { unauthorizedHandler } from "../lib/error_handler"
import { toHashById } from "../helpers/util"
import _ from "lodash/string"

const buildTodoParams = function(attrs) {
  let headers
  let formData
  if (attrs.files) {
    formData = new FormData()
    Object.keys(attrs).forEach(key => {
      const value = attrs[key]
      const railsKey = _.snakeCase(key)
      if (key === "files") {
        for (let i = 0, n = value.length; i < n; i++) {
          formData.append(`todo[${railsKey}][]`, value[i])
        }
        return
      }

      if (attrs[key]) {
        formData.append(`todo[${railsKey}]`, attrs[key])
      }
    })

    headers = {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json"
    }
  }

  return {
    data: formData || attrs,
    headers
  }
}

export default {
  state: {
    todos: {},
    loadingTodos: false
  },
  mutations: {
    setTodosArray(state, todos) {
      state.todos = todos
    },
    setTodoAttrs(state, todo) {
      const _todo = state.todos[todo.id] || {}
      state.todos = { ...state.todos, [todo.id]: { ..._todo, ...todo } }
    },
    addTodo(state, todo) {
      state.todos = { ...state.todos, [todo.id]: todo }
    },
    setTodos(state, todos) {
      state.todos = todos
    },
    removeTodo(state, todo) {
      let todos = state.todos
      delete todos[todo.id]
      state.todos = { ...state.todos }
    },
    clearSession(state) {
      state.todos = {}
    }
  },
  actions: {
    async updateTodo({ commit }, attrs) {
      commit("setTodoAttrs", attrs) // Update store and UI immediately for responsiveness

      const { data, headers } = buildTodoParams(attrs)

      const response = await fetchApi(`/api/todos/${attrs.id}`, {
        method: "PUT",
        data,
        headers
      })
      const { error, ...todo } = await response.json()

      if (error) {
        throw new Error(error)
      } else {
        commit("setTodoAttrs", todo) // Update store and UI with potential changes from server
      }
    },
    async loadTodos({ commit, dispatch }) {
      this.loadingTodos = true
      try {
        const todos = await dispatch("GET", { path: "/api/todos" })
        // const response = await fetchApi('/api/todos', { method: 'GET' })
        // const { error, ...todos } = await response.json()
        commit("setTodos", toHashById(todos))
      } catch (error) {
        console.log("error", error)
        unauthorizedHandler.ignore({ statusCode: 404 }).handle(error)
      } finally {
        this.loadingTodos = false
      }
    },
    async createTodo({ commit }, attrs) {
      const { data, headers } = buildTodoParams(attrs)

      const response = await fetchApi("/api/todos", {
        method: "POST",
        data,
        headers
      })
      const { error, ...todo } = await response.json()

      if (!error) {
        commit("addTodo", todo)
      }
    },
    async removeTodo({ commit }, attrs) {
      const response = await fetchApi(`/api/todos/${attrs.id}`, {
        method: "DELETE"
      })
      const { error, ...todo } = await response.json()

      if (!error) {
        commit("removeTodo", todo)
      }
    }
  }
}
