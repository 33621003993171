<template lang="pug">
app-container(
  title="Anmelden"
  sub-title="Pendelkinder"
  :footer-buttons="footerButtons"
  :show-navigation-bar-button="false"
  :fetching="isLoading"
  @submitForm="submitForm"
  @goStepBack="goStepBack"
  @skip="skip"
)
  .app-onboarding
    onboarding-steps

    transition(name="fade" mode="out-in")
      router-view(v-model="isFormValid")
</template>

<script>
import AppContainer from "../app_container.vue"
import OnboardingSteps from "./child_components/onboarding_steps.vue"
import onboardingRoutes from "./mixins/onboarding_routes"

export default {
  components: {
    AppContainer,
    OnboardingSteps
  },
  mixins: [onboardingRoutes],
  data() {
    return {
      isFormValid: false,
      isLoading: false
    }
  },
  computed: {
    footerButtons() {
      let buttons = {
        center: {
          type: this.isFormValid ? "active" : "inactive",
          action: "submitForm"
        },
        right: {
          title: "Überspringen",
          action: "skip",
          active: true
        }
      }
      if (this.currentOnboardingRouteIndex > 0) {
        buttons["left"] = {
          title: "Zurück",
          action: "goStepBack",
          active: false
        }
      }
      return buttons
    }
  },
  methods: {
    goStepBack() {
      if (!this.previousOnboardingRoute) {
        return
      }

      this.$router.push(this.previousOnboardingRoute)
    },
    skip() {
      this.isFormValid = true
      this.submitForm()
    },
    async submitForm() {
      if (this.isFormValid) {
        if (this.nextOnboardingRoute) {
          this.$router.push(this.nextOnboardingRoute)
        } else {
          this.isLoading = true
          try {
            await this.$store.dispatch("completeOnboarding")
          } catch (error) {
            console.log(error)
          }
          this.isLoading = false
          this.$router.push({ name: "app_onboarding_completed" })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

:deep(h4) {
  font-size: 1.1rem;
  font-weight: bold;
  color: $dark-gray-color;

  &.title {
    color: $turkies-color;
  }
}

:deep(p) {
  font-size: 0.95rem;
  line-height: 1.4rem;
}
</style>
