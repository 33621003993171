import fetchApi from "../lib/fetch_api.js"

export default {
  state: {
    account: {},
    accountMembers: []
  },
  getters: {
    parentOptions(state, getters) {
      return state.accountMembers.map(accountMember => {
        return {
          id: accountMember.parentId,
          value: accountMember.username.toUpperCase()
        }
      })
    }
  },
  mutations: {
    setAccount(state, { account }) {
      state.account = account
    },
    setAccountMembers(state, { accountMembers }) {
      state.accountMembers = accountMembers
    }
  },
  actions: {
    async loadAccounts({ commit }) {
      const response = await fetchApi("/api/account", { method: "GET" })
      const { account, accountMembers } = await response.json()

      await commit("setAccount", { account })
      await commit("setAccountMembers", { accountMembers })
    }
  }
}
