var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: _vm.t("code_sign_in.nav_bar.title"),
        "sub-title": _vm.t("pendelkinder"),
        fetching: _vm.submitting,
        footerButtons: _vm.footerButtons
      },
      on: { submit: _vm.onSubmit }
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("input-group", {
            attrs: {
              name: "code",
              type: "text",
              label: _vm.t("code_sign_in.labels.code")
            },
            model: {
              value: _vm.code,
              callback: function($$v) {
                _vm.code = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "code"
            }
          }),
          _c(
            "button",
            { staticClass: "invisible", attrs: { disabled: !_vm.submittable } },
            [_vm._v("Einloggen")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }