var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      ref: "select",
      domProps: { value: _vm.value },
      on: { input: _vm.input, blur: _vm.input, change: _vm.input }
    },
    _vm._l(_vm.options, function(option) {
      return _c(
        "option",
        {
          domProps: { value: option.date, selected: _vm.isCurrent(option.date) }
        },
        [_vm._v(_vm._s(option.name))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }