var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      staticClass: "small",
      attrs: {
        title: "Kontakt",
        "sub-title": "Pendelkinder",
        footerButtons: _vm.footerButtons,
        showBottom: false,
        fetching: _vm.fetching
      },
      on: { submit: _vm.addContact }
    },
    [
      _vm.allContacts.length > 0
        ? _c(
            "ul",
            { staticClass: "list contacts" },
            [
              _vm._l(_vm.contacts, function(contactObject) {
                return [
                  _c("li", { staticClass: "header" }, [
                    _vm._v(_vm._s(contactObject.char))
                  ]),
                  _vm._l(contactObject.group, function(contact) {
                    return _c(
                      "router-link",
                      {
                        key: contact.id,
                        staticClass: "contact",
                        attrs: {
                          tag: "li",
                          to: { name: "contact", params: { id: contact.id } }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(contact.firstName) +
                            " " +
                            _vm._s(contact.lastName)
                        )
                      ]
                    )
                  })
                ]
              })
            ],
            2
          )
        : _c("p", { staticClass: "flex" }, [
            _c("i", [_vm._v("Keine Kontakte eingetragen")])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }