<template lang="pug">
  div
    .row(v-if="images.length")
      .col
        carousel(:per-page="1" :loop="loop")
          slide(
            v-for="(image, index) in images"
            :key="image.id"
            @slideclick="showImage(image)"
          )
            img.upload(:src="image.uploadUrl")

    .row(v-if="documents.length")
      .col
        ul
          li(v-for="document in documents" :key="document.id")
            a(:href="document.uploadUrl" target="_blank") {{ document.filename }}

    fullscreen-image(v-model="fullscreenImage")
</template>

<script>
import FullscreenImage from "./fullscreen_image.vue"
import { Carousel, Slide } from "vue-carousel"

export default {
  components: {
    FullscreenImage,
    Carousel,
    Slide
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    loop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullscreenImage: null
    }
  },
  computed: {
    images() {
      return this.files.filter(
        file => file.contentType && file.contentType.startsWith("image/")
      )
    },
    documents() {
      return this.files.filter(
        file => file.uploadUrl && !file.contentType.startsWith("image/")
      )
    }
  },
  methods: {
    showImage(image) {
      setTimeout(() => {
        this.fullscreenImage = image.uploadUrl
      }, 100)
    }
  }
}
</script>
