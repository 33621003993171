import Vue from 'vue';
import fetchApi from "../lib/fetch_api.js"
import { unauthorizedHandler } from "../lib/error_handler"

export default {
  namespaced: true,
  state: {
    parentType: null,
    children: [],
    parentUserName: null
  },
  mutations: {
    setOnboardingProperty(state, { key, value }) {
      if (!state.hasOwnProperty(key)) {
        return
      }
      state[key] = value
    },
    
  },
  actions: {
    updateOnboardingProperty({ commit }, attrs) {
      commit("setOnboardingProperty", attrs)
    },
    
    async storeOnboarding({ commit, dispatch }) {
      // this.loadingTodos = true
      // try {
      //   const todos = await dispatch("GET", { path: "/api/todos" })
      //   // const response = await fetchApi('/api/todos', { method: 'GET' })
      //   // const { error, ...todos } = await response.json()
      //   commit("setTodos", toHashById(todos))
      // } catch (error) {
      //   console.log("error", error)
      //   unauthorizedHandler.ignore({ statusCode: 404 }).handle(error)
      // } finally {
      //   this.loadingTodos = false
      // }
      console.log("Integrate!")
    }
  }
}
