var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: _vm.month,
        "sub-title": _vm.year,
        footerButtons: _vm.footerButtons,
        fetching: _vm.fetchingEvents,
        showBottom: true
      },
      on: {
        submit: _vm.addDate,
        showMonth: _vm.showMonth,
        showWeek: _vm.showWeek,
        titleClick: _vm.titleClick
      }
    },
    [
      _c("template", { slot: "nav-bottom" }, [
        _vm.displayMode == "month"
          ? _c("table", { attrs: { width: "100%" } }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("M")]),
                  _c("td", [_vm._v("D")]),
                  _c("td", [_vm._v("M")]),
                  _c("td", [_vm._v("D")]),
                  _c("td", [_vm._v("F")]),
                  _c("td", [_vm._v("S")]),
                  _c("td", [_vm._v("S")])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "calendar" },
        [
          _c("month-select", {
            ref: "select-month",
            staticClass: "month-selector",
            model: {
              value: _vm.date,
              callback: function($$v) {
                _vm.date = $$v
              },
              expression: "date"
            }
          }),
          _vm.displayMonth
            ? _c(
                "div",
                {
                  staticClass: "months",
                  style: "margin-top: " + _vm.marginTop + "px;"
                },
                _vm._l(_vm.dates, function(date, index) {
                  return _c(
                    "div",
                    {
                      ref: _vm.monthCodeFor(date),
                      refInFor: true,
                      staticClass: "month"
                    },
                    [
                      index === 2
                        ? _c("div", {
                            ref: "topScroll",
                            refInFor: true,
                            staticClass: "scroll-observer"
                          })
                        : _vm._e(),
                      _c("h2", [_vm._v(_vm._s(_vm.monthTitle(date)))]),
                      _c("table", { class: _vm.displayMode }, [
                        _c(
                          "tbody",
                          _vm._l(_vm.weeks(date), function(week) {
                            return _c(
                              "tr",
                              _vm._l(week, function(day) {
                                return _c(
                                  "td",
                                  {
                                    staticClass: "day",
                                    class: _vm.classFor(day, date),
                                    on: {
                                      click: function($event) {
                                        return _vm.showEvents($event, day)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "td-container" }, [
                                      _c("div", {
                                        staticClass: "invite-warning",
                                        class: {
                                          hidden: !_vm.hasWarningFor(day)
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "calendar-header",
                                          class: { current: _vm.isToday(day) }
                                        },
                                        [_vm._v(_vm._s(day.getDate()))]
                                      ),
                                      _c(
                                        "ul",
                                        _vm._l(_vm.parentsFor(day), function(
                                          parent
                                        ) {
                                          return _c(
                                            "li",
                                            { class: parent.role },
                                            [_vm._v(_vm._s(parent.username))]
                                          )
                                        }),
                                        0
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          0
                        )
                      ]),
                      index === 3
                        ? _c("div", {
                            ref: "bottomScroll",
                            refInFor: true,
                            staticClass: "scroll-observer"
                          })
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm.displayMode == "week"
            ? _c(
                "div",
                {
                  staticClass: "calendar-weeks",
                  style: "margin-top: " + _vm.marginTop + "px;"
                },
                _vm._l(_vm.weekDates, function(date, index) {
                  return _c(
                    "div",
                    {
                      ref: _vm.monthCodeForWeek(date),
                      refInFor: true,
                      staticClass: "calendar-week"
                    },
                    [
                      index === 2
                        ? _c("div", {
                            ref: "topScroll",
                            refInFor: true,
                            staticClass: "scroll-observer"
                          })
                        : _vm._e(),
                      _c("table", { class: _vm.displayMode }, [
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.weekDays(date), function(day) {
                              return [
                                day.getDate() === 1
                                  ? _c("tr", [
                                      _c("th", { attrs: { colspan: "2" } }, [
                                        _vm._v(_vm._s(_vm.monthTitle(day)))
                                      ])
                                    ])
                                  : _vm._e(),
                                _c(
                                  "tr",
                                  {
                                    staticClass: "week",
                                    class: { holiday: _vm.isHoliday(day) }
                                  },
                                  [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "day-headline",
                                        class: { current: _vm.isToday(day) },
                                        on: {
                                          click: function($event) {
                                            return _vm.addDate(day)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.formatWeekDay(day)))]
                                    ),
                                    _c("td", { staticClass: "day" }, [
                                      _c(
                                        "ul",
                                        _vm._l(_vm.eventsFor(day), function(
                                          event
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              class: _vm.eventEntryClass(event),
                                              on: {
                                                click: function($event) {
                                                  return _vm.editEvent(
                                                    event,
                                                    day
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.weekEventName(event))
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ])
                                  ]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      index === 3
                        ? _c("div", {
                            ref: "bottomScroll",
                            refInFor: true,
                            staticClass: "scroll-observer"
                          })
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }