var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Vollversion",
        "sub-title": "Pendelkinder",
        "show-footer-center-button": _vm.hasPlan,
        "show-navigation-bar-button": _vm.hasPlan,
        fetching: _vm.purchasing
      },
      on: {
        submit: function($event) {
          return _vm.goTo({ name: "root" })
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm._l(_vm.plans, function(plan) {
            return _c("subscription-plan", {
              key: plan.key,
              attrs: { plan: plan }
            })
          }),
          _vm.isExpired
            ? _c("p", { staticClass: "uk-text-center" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.signOut($event)
                      }
                    }
                  },
                  [_vm._v("Ausloggen")]
                )
              ])
            : _vm._e(),
          _c("p", { staticClass: "uk-text-center" }, [
            _c("a", { attrs: { href: "pendelkinder://iap/restore" } }, [
              _vm._v("Kauf wiederherstellen")
            ])
          ]),
          _c("p", { staticClass: "uk-text-center" }, [
            _vm._v("Es gelten die\n"),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showTermsAndConditions = true
                  }
                }
              },
              [_vm._v("Nutzungsbedingungen")]
            ),
            _vm._v("\nund\n"),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showPrivacy = true
                  }
                }
              },
              [_vm._v("Datenschutzbestimmungen")]
            ),
            _vm._v("\nder Pendelkinder App")
          ])
        ],
        2
      ),
      _c(
        "vk-modal",
        {
          attrs: { show: _vm.showPrivacy, "overflow-auto": "" },
          on: {
            "update:show": function($event) {
              _vm.showPrivacy = $event
            }
          }
        },
        [
          _c("vk-modal-close", {
            on: {
              click: function($event) {
                _vm.showPrivacy = false
              }
            }
          }),
          _c("vk-modal-title", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Datenschutz")
          ]),
          _c("privacy-policy"),
          _c(
            "div",
            {
              staticClass: "uk-text-center",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "vk-button",
                {
                  staticClass: "uk-margin-small-right close-button",
                  on: {
                    click: function($event) {
                      _vm.showPrivacy = false
                    }
                  }
                },
                [_vm._v("Schließen")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vk-modal",
        {
          attrs: { show: _vm.showTermsAndConditions, "overflow-auto": "" },
          on: {
            "update:show": function($event) {
              _vm.showTermsAndConditions = $event
            }
          }
        },
        [
          _c("vk-modal-close", {
            on: {
              click: function($event) {
                _vm.showTermsAndConditions = false
              }
            }
          }),
          _c(
            "vk-modal-title",
            {
              staticStyle: { "font-size": "1.7rem" },
              attrs: { slot: "header" },
              slot: "header"
            },
            [_vm._v("All­ge­meine Geschäfts­be­din­gun­gen")]
          ),
          _c("terms-and-conditions"),
          _c(
            "div",
            {
              staticClass: "uk-text-center",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "vk-button",
                {
                  staticClass: "uk-margin-small-right close-button",
                  on: {
                    click: function($event) {
                      _vm.showTermsAndConditions = false
                    }
                  }
                },
                [_vm._v("Schließen")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }