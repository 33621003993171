<template lang="pug">
  .app-onboarding-start.uk-text-center
    h4.title Elternteil
    p Bitte teile uns mit, ob du Vater oder Mutter bist. Der Vater wird im Kalender in der Farbe Blau angezeigt und die Mutter in Orange. Deine Eingabe bestätigst du mit dem Klick auf den blauen Button.

    h4 Wer bist Du?

    .big-input-group.uk-flex.uk-flex-center
      button(
        v-for="button in buttons"
        :key="button.key"
        :class="buttonClass(button)"
        @click="selectParentType(button)"
      )
        | {{ button.value }}
</template>

<script>
import { mapState, mapActions } from "vuex"
import onboardingForm from "./mixins/onboarding_form"

export default {
  mixins: [onboardingForm],
  data() {
    return {
      buttons: [
        {
          key: 'dad',
          value: 'Vater'
        },
        {
          key: 'mom',
          value: 'Mutter'
        }
      ]
    }
  },
  created() {
    this.validateForm()
  },
  computed: {
    ...mapState({
      profile: ({ profile }) => profile.profile
    }),
    parentMode() {
      return this.profile.parentMode
    }
  },
  methods: {
    ...mapActions(["updateProfile"]),
    buttonClass({ key }) {
      let classList = [key]
      if (key === this.parentMode) {
        classList.push("selected")
      }
      return classList
    },
    selectParentType({ key }) {
      let value = key
      if (key === this.parentMode) {
        value = null
      }
      this.updateProfile({ parentMode: value })
      this.validateForm()
    },
    validateForm() {
      this.$emit("isFormValidChange", Boolean(this.parentMode))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.big-input-group {
  button {
    background: $dove-gray-color;
    &:first-child {
      margin-right: 15px;
    }

    &.dad.selected {
      background: $turkies-color;
    }
    &.mom.selected {
      background: $orange-color;
    }
  }

}
</style>
