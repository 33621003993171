<template lang="pug">
app-container(title="Kontakt" sub-title="Pendelkinder" :footerButtons="footerButtons" :fetching="fetching" :show-bottom="false" @submit="goBack" @editContact="editContact")
  .contact-detail
    .contact-hero
      h2 {{ contact.first_name }} {{ contact.last_name }}

    ul.fields(v-if="contact.fields && contact.fields.length > 0")
      li(v-for="field in contact.fields")
        contact-field(:name="field.name" :value="field.value")

    .notes(v-if="contact.notes && contact.notes !== ''")
      | {{ contact.notes }}
</template>

<script>
import AppContainer from "../app_container.vue"
import ContactField from "./contact_field.vue"

export default {
  components: {
    AppContainer,
    ContactField
  },
  data: () => ({
    fetching: false,
    lastCharacter: "",
    contact: {},
    footerButtons: {
      left: {
        title: "Bearbeiten",
        action: "editContact"
      }
    }
  }),
  mounted() {
    this.fetchContact()
  },
  methods: {
    async fetchContact() {
      const response = await this.$http.get(
        `/api/contacts/${this.$route.params.id}`
      )

      if (response.ok) {
        this.contact = response.body
      }
    },
    editContact() {
      this.$router.push({
        name: "edit_contact",
        params: { id: this.contact.id }
      })
    },
    goBack() {
      this.$router.push({ name: "contacts" })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/stylesheets/variables.scss";

:deep(#navigation-bar) {
  height: 70px;
}

.contact-hero {
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    color: #fff;
  }
}

a {
  color: $dark-gray-color;
}
</style>
