var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Kontakt",
        "sub-title": "Pendelkinder",
        footerButtons: _vm.footerButtons,
        fetching: _vm.fetching,
        "show-bottom": false
      },
      on: { submit: _vm.goBack, editContact: _vm.editContact }
    },
    [
      _c("div", { staticClass: "contact-detail" }, [
        _c("div", { staticClass: "contact-hero" }, [
          _c("h2", [
            _vm._v(
              _vm._s(_vm.contact.first_name) +
                " " +
                _vm._s(_vm.contact.last_name)
            )
          ])
        ]),
        _vm.contact.fields && _vm.contact.fields.length > 0
          ? _c(
              "ul",
              { staticClass: "fields" },
              _vm._l(_vm.contact.fields, function(field) {
                return _c(
                  "li",
                  [
                    _c("contact-field", {
                      attrs: { name: field.name, value: field.value }
                    })
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.contact.notes && _vm.contact.notes !== ""
          ? _c("div", { staticClass: "notes" }, [
              _vm._v(_vm._s(_vm.contact.notes))
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }