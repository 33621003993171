var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-onboarding-start uk-text-center" }, [
    _c("h4", { staticClass: "title" }, [_vm._v("Elternteil")]),
    _c("p", [
      _vm._v(
        "Bitte teile uns mit, ob du Vater oder Mutter bist. Der Vater wird im Kalender in der Farbe Blau angezeigt und die Mutter in Orange. Deine Eingabe bestätigst du mit dem Klick auf den blauen Button."
      )
    ]),
    _c("h4", [_vm._v("Wer bist Du?")]),
    _c(
      "div",
      { staticClass: "big-input-group uk-flex uk-flex-center" },
      _vm._l(_vm.buttons, function(button) {
        return _c(
          "button",
          {
            key: button.key,
            class: _vm.buttonClass(button),
            on: {
              click: function($event) {
                return _vm.selectParentType(button)
              }
            }
          },
          [_vm._v(_vm._s(button.value))]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }