var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vk-modal-full", { attrs: { show: _vm.shouldShowImage } }, [
        _vm.shouldShowImage
          ? _c(
              "div",
              { staticClass: "fullscreen-image", on: { click: _vm.hideImage } },
              [_c("img", { attrs: { src: _vm.value } })]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }