var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        "footer-buttons": _vm.footerButtons,
        "show-navigation-bar-button": false
      },
      on: { goToOnboarding: _vm.goToOnboarding }
    },
    [
      _c("div", { staticClass: "app-onboarding" }, [
        _c("div", { staticClass: "app-onboarding-start uk-text-center" }, [
          _c("h4", { staticClass: "title" }, [
            _vm._v("Hallo " + _vm._s(_vm.user.name))
          ]),
          _c("p", [
            _vm._v(
              "Wir freuen uns sehr, dass du dich bei der Pendelkinder-App angemeldet hast."
            )
          ]),
          _c("p", [
            _vm._v(
              "Wir möchten nun die Einstellungen für diese App gemeinsam mit dir vornehmen und dir dabei die Bedeutung der Einstellungsmöglichkeiten erläutern."
            )
          ]),
          _c("p", [
            _c("strong", [
              _vm._v("Klicke auf den blauen Button,"),
              _c("br"),
              _vm._v("wenn du bereit dafür bist.")
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }