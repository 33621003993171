var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Hilfe",
        "sub-title": "Pendelkinder",
        fetching: _vm.isLoading
      },
      on: { submit: _vm.goBack }
    },
    [
      _vm.page
        ? _c("div", [
            _c("div", {
              staticClass: "content",
              domProps: { innerHTML: _vm._s(_vm.page.body) }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }