var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-onboarding-start uk-text-center" }, [
    _c("h4", { staticClass: "title" }, [_vm._v("Koppelung der Kalender")]),
    _c("p", [
      _vm._v(
        "Du kannst die App mit dem anderen Elternteil gemeinsam nutzen. Um die Koppelung zu aktiveren gebe hier die E-Mail-Adresse des anderen Elternteils an und lade zu einer gemeinsamen Nutzung der App ein. Die E-Mail-Adresse kannst du auch später unter den Einstellungen hinzufügen."
      )
    ]),
    _c("div", { staticClass: "big-input-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.newParentEmail,
            expression: "newParentEmail",
            modifiers: { trim: true }
          }
        ],
        staticClass: "input",
        attrs: {
          type: "text",
          placeholder: "E-Mail-Adresse anderer Elternteil"
        },
        domProps: { value: _vm.newParentEmail },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.newParentEmail = $event.target.value.trim()
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      }),
      _c("div", { staticClass: "appended" }, [
        _c("button", { on: { click: _vm.invite } }, [
          _vm._v(_vm._s(_vm.parentConnectionTitle))
        ])
      ])
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v(
          "WICHTIG: Hast du bereits einen Einladungslink erhalten, dann überspringe diese Seite."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }