var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "privacy" }, [
      _c("p", [
        _c("strong", [
          _c("span", { staticStyle: { "font-size": "22px" } }, [
            _vm._v("1. Allgemeine Hinweise")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Pendelkinder SAV GmbH („Pendelkinder“) respektiert den Schutz Ihrer personenbezogenen Daten. In diesem Datenschutzhinweis informieren wir Sie, welche personenbezogenen Daten von Ihnen erhoben werden, wenn Sie unsere Pendelkinder App und unsere Webseite https://www.pendelkinder.de nutzen, wie wir Ihre personenbezogenen Daten nutzen und weitergeben und wie Ihre personenbezogenen Daten geschützt werden. Dieser Datenschutzhinweis dient Ihrer Information. Sollten wir für die Erhebung und Verarbeitung Ihrer Daten Ihre Einwilligung benötigen, werden wir Sie abseits dieses Datenschutzhinweises explizit darum bitten.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Verantwortlicher im Sinne des Datenschutzrechts ist\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Pendelkinder SAV GmbH"), _c("br")])
      ]),
      _vm._v(" "),
      _c("p", [_c("strong", [_vm._v("\n  Ricarda-Huch-Str. 12\n  ")])]),
      _vm._v(" "),
      _c("p", [
        _c("strong", [_vm._v("61350 Bad Homburg")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Deutschland")])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Falls Sie Fragen zu diesem Datenschutzhinweis haben, können Sie sich jederzeit per E-Mail (hello@pendelkinder.de) an uns wenden.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [
          _vm._v(
            "Wer ist verantwortlich für die Datenerfassung auf dieser Website?"
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum der Website entnehmen.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [
          _vm._v("Welche Rechte haben Sie bezüg­lich Ihrer Daten?")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Sie haben jeder­zeit das Recht unent­gelt­lich Aus­kunft über Her­kunft, Emp­fän­ger und Zweck Ihrer gespei­cher­ten per­so­nen­be­zo­ge­nen Daten zu erhal­ten. Sie haben außer­dem ein Recht, die Berich­ti­gung, Sper­rung oder Löschung dieser Daten zu ver­lan­gen. Hierzu sowie zu wei­te­ren Fragen zum Thema Daten­schutz können Sie sich jeder­zeit unter der im Impres­sum ange­ge­be­nen Adresse an uns wenden. Des Wei­te­ren steht Ihnen ein Beschwer­de­recht bei der zustän­di­gen Auf­sichts­be­hörde zu.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [
          _vm._v("Wider­ruf Ihrer Ein­wil­li­gung zur Daten­ver­ar­bei­tung")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Viele Daten­ver­ar­bei­tungs­vor­gänge sind nur mit Ihrer aus­drück­li­chen Ein­wil­li­gung mög­lich. Sie können eine bereits erteilte Ein­wil­li­gung jeder­zeit wider­ru­fen. Dazu reicht eine form­lose Mit­tei­lung per E-Mail an uns. Die Recht­mä­ßig­keit der bis zum Wider­ruf erfolg­ten Daten­ver­ar­bei­tung bleibt vom Wider­ruf unbe­rührt.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [
          _vm._v("Beschwer­de­recht bei der zustän­di­gen Auf­sichts­be­hörde")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Im Falle daten­schutz­recht­li­cher Ver­stöße steht dem Betrof­fe­nen ein Beschwer­de­recht bei der zustän­di­gen Auf­sichts­be­hörde zu. Zustän­dige Auf­sichts­be­hörde in daten­schutz­recht­li­chen Fragen ist der Lan­des­da­ten­schutz­be­auf­tragte des Bun­des­lan­des, in dem unser Unter­neh­men seinen Sitz hat. Eine Liste der Daten­schutz­be­auf­trag­ten sowie deren Kon­takt­da­ten können fol­gen­dem Link ent­nom­men werden: "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html",
              target: "_blank",
              title:
                "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            }
          },
          [
            _vm._v(
              "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            )
          ]
        ),
        _vm._v(".\n      "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Cookies")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Pendelkinder speichert so genannte „Cookies“, um Ihnen einen umfangreichen Funktionsumfang zu bieten und die Nutzung unserer Webseiten komfortabler zu gestalten. „Cookies“ sind kleine Dateien, die mit Hilfe Ihres Internetbrowsers auf Ihrem Rechner gespeichert werden. Wenn Sie den Einsatz von „Cookies“ nicht wünschen, können Sie das Speichern von „Cookies“ auf Ihrem Rechner durch entsprechende Einstellungen Ihres Internetbrowsers verhindern. Bitte beachten Sie, dass die Funktionsfähigkeit und -umfang unseres Angebots dadurch eingeschränkt sein kann.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Was ist die Pendlkinder-App?")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Pendelkinder ist eine digitale verknüpfte Kalender-App für getrennt lebende Eltern rund um die Organisation der gemeinsamen Kinder. Weitere Tools wie Stundenplan, Aufgabenliste, Kontakte und integrierter Messenger sind eine gewinnbringende Unterstützung für die herausfordernde Organisation mit dem Expartner.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("App-Stores")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Die App ist über von Dritten betriebene Vertriebsplattformen erhältlich, sogenannte App-Stores (Google Play und Apple iTunes). Ihr Download setzt gegebenenfalls eine vorherige Registrierung beim jeweiligen App-Store und die Installation der App-Store-Software voraus. Wir haben keinen Einfluss auf die Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten im Zusammenhang mit Ihrer Registrierung und der Bereitstellung von Downloads in dem jeweiligen App-Store und der App-Store-Software. Verantwortliche Stelle ist insoweit allein der Betreiber des jeweiligen App-Stores. Bitte informieren Sie sich bei Bedarf direkt bei dem jeweiligen App-Store-Anbieter.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticStyle: { "font-size": "22px" } }, [
          _c("strong", [_vm._v("2. Erhebung von Daten")])
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" Wir erheben personenbezogene Daten über Sie, wenn Sie:\n  ")
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("unsere App herunterladen und nutzen\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("sich in unserer App registrieren\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("unsere Webseite besuchen\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("sich bei unserem Newsletter anmelden\n      ")])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Wofür nutzen wir Ihre Daten?")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website und der App zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden um das Angebot von Pendelkinder stetig zu verbessern.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Automatisierte Datenerhebung")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Beim Zugriff auf die Webseiten und die App von Pendelkinder übermittelt Ihr Internetbrowser aus technischen Gründen automatisch Daten. Folgende Daten werden getrennt von anderen Daten, die Sie unter Umständen an uns übermitteln, gespeichert:\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("Datum und Uhrzeit des Zugriffs,\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("Browsertyp/ -version,\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("verwendetes Betriebssystem,\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("URL der zuvor besuchten Webseite,\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("Menge der gesendeten Daten,\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("IP-Adresse\n      ")])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Diese Daten werden ausschließlich aus technischen Gründen gespeichert und werden zu keinem Zeitpunkt einer bestimmten Person zugeordnet. Eine Zusam­men­füh­rung dieser Daten mit ande­ren Daten­quel­len wird nicht vor­ge­nom­men. Grund­lage für die Daten­ver­ar­bei­tung ist Art. 6 Abs. 1 lit. f DSGVO, der die Ver­ar­bei­tung von Daten zur Erfül­lung eines Ver­trags oder vor­ver­trag­li­cher Maß­nah­men gestat­tet.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Personenbezogene Daten")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Personenbezogene Daten sind solche, die Ihrer Person zugeordnet werden können (z. B. Kürzel oder E-Mail-Adresse). Die Erhebung solcher Daten ist für das Funktionieren der Pendelkinder App und den angestrebten gegenseitigen Informationsaustausch zwischen getrennten Eltern zwingend erforderlich. Die Datenerhebung dient aber auch der Aufdeckung und Untersuchung möglicherweise verbotener oder illegaler Aktivitäten sowie zur Durchsetzung unserer Allgemeinen Geschäftsbedingungen sowie der Vertragsabwicklung. Für die Nutzung der Pendelkinder App müssen Sie sich gesondert anmelden/registrieren. Hierfür werden Ihre personenbezogenen Daten benötigt, dies sind ein Kürzel, Ihre E-Mail-Adresse und die Zuordnung Vater oder Mutter. Diese Angaben sind zwingend erforderlich um die Pendelkinder App zu nutzen.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Von Nutzern eingegebene Daten")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Sie können das Produkt Pendelkinder ausschließlich mit Anmeldung nutzen (alle gängige iOS und Android Endgeräte). Um einen Account anlegen zu können müssen Sie Ihre E-Mail-Adresse angeben, ein Kürzel wählen ein Passwort erstellen und die Account-Zuordnung zu Vater oder Mutter festlegen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die Pendelkinder App hat eine Kalenderverwaltung als zentrale Funktion. Sie können das Produkt nur online und nicht offline nutzen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Zur Gewährleistung der Datensicherheit haben wir verschiedene technische und organisatorische Maßnahmen eingerichtet. Unter anderem sind das:\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v("Speicherung der Daten bei zertifizierten Partnern\n      ")
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("Passwortschutz der Pendelkinder App\n      ")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Passwörter werden nur verschlüsselt übertragen und gespeichert\n      "
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Alle Daten von und zur Pendelkinder App werden grundsätzlich über eine sichere Verbindung (SSL) übertragen\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Einem Internetnutzer muss jedoch bewusst sein, dass die von ihm online veröffentlichten Informationen von anderen Internetnutzern weltweit abgerufen und gespeichert werden können. Im Rahmen der Internetbenutzung bewegen Sie sich in einem öffentlichen Raum. Es ist in diesem Zusammenhang ausdrücklich darauf hinzuweisen, dass der Datenschutz für Datenübertragungen in offenen Netzen wie dem Internet nach dem Stand der Technik nicht absolut gewährleistet werden kann.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticStyle: { "font-size": "22px" } }, [
          _c("strong", [_vm._v("3. Newsletter & Formulare")])
        ])
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("strong", [_vm._v("Newsletter")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("POWr")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Die Pendelkinder Website nutzt die Dienste von POWr für den Versand von Newslettern. Anbieter ist die POWr HQ, 340 Pine Street, San Francisco, California 94104. POWr ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden kann. Wenn Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B. E-Mail-Adresse), werden diese auf den Servern von POWr in den USA gespeichert.\n      "
        ),
        _c("br"),
        _vm._v(
          " Mit Hilfe von POWr können wir unsere Newsletterkampagnen analysieren. Wenn Sie eine mit POWr versandte E-Mail öffnen, verbindet sich eine in der E-Mail enthaltene Datei (sog. web-beacon) mit den Servern von MailChimp in den USA. So kann festgestellt werden, ob eine Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden. Außerdem werden technische Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Diese Informationen können nicht dem jeweiligen Newsletter-Empfänger zugeordnet werden. Sie dienen ausschließlich der statistischen Analyse von Newsletterkampagnen. Die Ergebnisse dieser Analysen können genutzt werden, um künftige Newsletter besser an die Interessen der Empfänger anzupassen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Wenn Sie keine Analyse durch MailChimp wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den Newsletter auch direkt auf der Website abbestellen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren Servern als auch von den Servern von MailChimp gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.\n      "
        ),
        _c("br"),
        _vm._v(
          " Näheres entnehmen Sie den Datenschutzbestimmungen von MailChimp unter: https://mailchimp.com/legal/terms/.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Kommentare und Beiträge")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Wenn Nutzer Kommentare im Blog oder sonstige Beiträge hinterlassen, werden ihre IP-Adressen gespeichert. Das erfolgt zur Sicherheit des Anbieters, falls jemand in Kommentaren und Beiträgen widerrechtliche Inhalte schreibt (Beleidigungen, verbotene politische Propaganda, etc.). In diesem Fall kann der Anbieter selbst für den Kommentar oder Beitrag belangt werden und ist daher an der Identität des Verfassers interessiert.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("span", { staticStyle: { "font-size": "22px" } }, [
          _c("strong", [_vm._v("4. Analyse-Tools und Werbung")])
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Google Analytics")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.\n      "
        ),
        _c("br"),
        _vm._v(
          " Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("IP Anonymisierung")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Browser Plugin")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Widerspruch gegen Datenerfassung")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google Analytics deaktivieren.\n      "
        ),
        _c("br"),
        _vm._v(
          " Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://support.google.com/analytics/answer/6004245?hl=de",
              target: "_blank",
              title: "https://support.google.com/analytics/answer/6004245?hl=de"
            }
          },
          [_vm._v("https://support.google.com/analytics/answer/6004245?hl=de")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Auftragsverarbeitung")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Demografische Merkmale bei Google Analytics")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Google Analytics Remarketing")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Unsere Websites nutzen die Funktionen von Google Analytics Remarketing in Verbindung mit den geräteübergreifenden Funktionen von Google AdWords und Google DoubleClick. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Diese Funktion ermöglicht es die mit Google Analytics Remarketing erstellten Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von Google AdWords und Google DoubleClick zu verknüpfen. Auf diese Weise können interessenbezogene, personalisierte Werbebotschaften, die in Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem Endgerät (z.B. Handy) an Sie angepasst wurden auch auf einem anderen Ihrer Endgeräte (z.B. Tablet oder PC) angezeigt werden.\n      "
        ),
        _c("br"),
        _vm._v(
          " Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu diesem Zweck Ihren Web- und App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise können auf jedem Endgerät auf dem Sie sich mit Ihrem Google-Konto anmelden, dieselben personalisierten Werbebotschaften geschaltet werden.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Zur Unterstützung dieser Funktion erfasst Google Analytics google-authentifizierte IDs der Nutzer, die vorübergehend mit unseren Google-Analytics-Daten verknüpft werden, um Zielgruppen für die geräteübergreifende Anzeigenwerbung zu definieren und zu erstellen.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft widersprechen, indem Sie personalisierte Werbung in Ihrem Google-Konto deaktivieren; folgen Sie hierzu diesem Link: https://www.google.com/settings/ads/onweb/.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschließlich auf Grundlage Ihrer Einwilligung, die Sie bei Google abgeben oder widerrufen können (Art. 6 Abs. 1 lit. a DSGVO). Bei Datenerfassungsvorgängen, die nicht in Ihrem Google-Konto zusammengeführt werden (z.B. weil Sie kein Google-Konto haben oder der Zusammenführung widersprochen haben) beruht die Erfassung der Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich daraus, dass der Websitebetreiber ein Interesse an der anonymisierten Analyse der Websitebesucher zu Werbezwecken hat.\n      "
        ),
        _c("br"),
        _vm._v(
          " Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerklärung von Google unter: https://www.google.com/policies/technologies/ads/.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Google AdWords und Google Conversion-Tracking")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Diese Website verwendet Google AdWords. AdWords ist ein Online-Werbeprogramm der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google”).\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Im Rahmen von Google AdWords nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf eine von Google geschaltete Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei Cookies handelt es sich um kleine Textdateien, die der Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte Seiten dieser Website und das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.\n      "
        ),
        _c("br"),
        _vm._v(
          " Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können nicht über die Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie des Google Conversion-Trackings über ihren Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Die Speicherung von “Conversion-Cookies” und die Nutzung dieses Tracking-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.\n      "
        ),
        _c("br"),
        _vm._v(
          " Mehr Informationen zu Google AdWords und Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von Google: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.google.de/policies/privacy/",
              target: "_blank",
              title: "https://www.google.de/policies/privacy/"
            }
          },
          [_vm._v("https://www.google.de/policies/privacy/")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Google reCAPTCHA")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse stattfindet.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie folgenden Links: "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://www.google.com/intl/de/policies/privacy/%20und https://www.google.com/recaptcha/intro/android.html",
              target: "_blank",
              title:
                "https://www.google.com/intl/de/policies/privacy/ und https://www.google.com/recaptcha/intro/android.html"
            }
          },
          [
            _vm._v(
              "https://www.google.com/intl/de/policies/privacy/ und\n      https://www.google.com/recaptcha/intro/android.html"
            )
          ]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Datenschutz-Hinweis der VG WORT")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Wir setzen „Session-Cookies“ der VG Wort, München, zur Messung von Zugriffen auf Texte ein, um die Kopierwahrscheinlichkeit zu erfassen. Session-Cookies sind kleine Informationseinheiten, die ein Anbieter im Arbeitsspeicher des Computers des Besuchers speichert. In einem Session-Cookie wird eine zufällig erzeugte eindeutige Identifikationsnummer abgelegt, eine sogenannte Session-ID. Außerdem enthält ein Cookie die Angabe über seine Herkunft und die Speicherfrist. Session-Cookies können keine anderen Daten speichern. Diese Messungen werden von der INFOnline GmbH nach dem Skalierbaren Zentralen Messverfahren (SZM) durchgeführt. Sie helfen dabei, die Kopierwahrscheinlichkeit einzelner Texte zur Vergütung von gesetzlichen Ansprüchen von Autoren und Verlagen zu ermitteln. Wir erfassen keine personenbezogenen Daten über Cookies.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Viele unserer Seiten sind mit JavaScript-Aufrufen versehen, über die wir die Zugriffe an die Verwertungsgesellschaft Wort (VG Wort) melden. Wir ermöglichen damit, dass unsere Autoren an den Ausschüttungen der VG Wort partizipieren, die die gesetzliche Vergütung für die Nutzungen urheberrechtlich geschützter Werke gem. § 53 UrhG sicherstellen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Eine Nutzung unserer Angebote ist auch ohne Cookies möglich. Die meisten Browser sind so eingestellt, dass sie Cookies automatisch akzeptieren. Sie können das Speichern von Cookies jedoch deaktivieren oder Ihren Browser so einstellen, dass er Sie benachrichtigt, sobald Cookies gesendet werden.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [
          _vm._v(
            "Datenschutzerklärung zur Nutzung des Skalierbaren Zentralen Messverfahrens"
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Unsere Website und unser mobiles Webangebot nutzen das „Skalierbare Zentrale Messverfahren“ (SZM) der INFOnline GmbH ("
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.infonline.de",
              target: "_blank",
              title: "https://www.infonline.de"
            }
          },
          [_vm._v("https://www.infonline.de")]
        ),
        _vm._v(
          ") für die Ermittlung statistischer Kennwerte zur Ermittlung der Kopierwahrscheinlichkeit von Texten.\n      "
        ),
        _c("br"),
        _vm._v(
          " Dabei werden anonyme Messwerte erhoben. Die Zugriffszahlenmessung verwendet zur Wiedererkennung von Computersystemen alternativ ein Session-Cookie oder eine Signatur, die aus verschiedenen automatisch übertragenen Informationen Ihres Browsers erstellt wird. IP-Adressen werden nur in anonymisierter Form verarbeitet.\n      "
        ),
        _c("br"),
        _vm._v(
          " Das Verfahren wurde unter der Beachtung des Datenschutzes entwickelt. Einziges Ziel des Verfahrens ist es, die Kopierwahrscheinlichkeit einzelner Texte zu ermitteln.\n      "
        ),
        _c("br"),
        _vm._v(
          " Zu keinem Zeitpunkt werden einzelne Nutzer identifiziert. Ihre Identität bleibt immer geschützt. Sie erhalten über das System keine Werbung.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticStyle: { "font-size": "22px" } }, [
          _c("strong", [_vm._v("5. Plugins und Tools")])
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("YouTube")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.\n      "
        ),
        _c("br"),
        _vm._v(
          " Des Weiteren kann Youtube verschiedene Cookies auf Ihrem Endgerät speichern. Mit Hilfe dieser Cookies kann Youtube Informationen über Besucher unserer Website erhalten. Diese Informationen werden u. a. verwendet, um Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen. Die Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.google.de/intl/de/policies/privacy",
              target: "_blank",
              title: "https://www.google.de/intl/de/policies/privacy"
            }
          },
          [_vm._v("https://www.google.de/intl/de/policies/privacy")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Google Web Fonts")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.\n      "
        ),
        _c("br"),
        _vm._v(" Weitere Informationen zu Google Web Fonts finden Sie unter "),
        _c(
          "a",
          {
            attrs: {
              href: "https://developers.google.com/fonts/faq",
              target: "_blank",
              title: "https://developers.google.com/fonts/faq"
            }
          },
          [_vm._v("https://developers.google.com/fonts/faq")]
        ),
        _vm._v(" und in der Datenschutzerklärung von Google: "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.google.com/policies/privacy/",
              target: "_blank",
              title: "https://www.google.com/policies/privacy/"
            }
          },
          [_vm._v("https://www.google.com/policies/privacy/")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Spotify")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Auf unseren Seiten sind Funktionen des Musik-Dienstes Spotify eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61, 113 56 Stockholm in Schweden. Die Spotify Plugins erkennen Sie an dem grünen Logo auf unserer Seite. Eine Übersicht über die Spotify-Plugins finden Sie unter: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://developer.spotify.com",
              target: "_blank",
              title: "https://developer.spotify.com"
            }
          },
          [_vm._v("https://developer.spotify.com")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Dadurch kann beim Besuch unserer Seiten über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Spotify-Server hergestellt werden. Spotify erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Spotify Button anklicken während Sie in Ihrem Spotify-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Spotify Profil verlinken. Dadurch kann Spotify den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen.\n      "
        ),
        _c("br"),
        _vm._v(
          " Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der ansprechenden akustischen Ausgestaltung seiner Website.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Spotify: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.spotify.com/de/legal/privacy-policy/",
              target: "_blank",
              title: "https://www.spotify.com/de/legal/privacy-policy/"
            }
          },
          [_vm._v("https://www.spotify.com/de/legal/privacy-policy/")]
        ),
        _vm._v(".\n      "),
        _c("br"),
        _vm._v(
          " Wenn Sie nicht wünschen, dass Spotify den Besuch unserer Seiten Ihrem Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Spotify-Benutzerkonto aus.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticStyle: { "font-size": "22px" } }, [
          _c("strong", [_vm._v("6. Soziale Medien")])
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Instagram Plugin")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA integriert.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Die Verwendung des Instagram-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien.\n      "
        ),
        _c("br"),
        _vm._v(
          " Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://instagram.com/about/legal/privacy/",
              target: "_blank",
              title: "https://instagram.com/about/legal/privacy/"
            }
          },
          [_vm._v("https://instagram.com/about/legal/privacy/")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Pinterest")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Auf unserer Seite verwenden wir Social Plugins des sozialen Netzwerkes Pinterest, das von der Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA („Pinterest“) betrieben wird. Wenn Sie eine Seite aufrufen die ein solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von Pinterest her. Das Plugin übermittelt dabei Protokolldaten an den Server von Pinterest in die USA. Diese Protokolldaten enthalten möglicherweise Ihre IP-Adresse, die Adresse der besuchten Websites, die ebenfalls Pinterest-Funktionen enthalten, Art und Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest sowie Cookies.\n  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung und Nutzung der Daten durch Pinterest sowie Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den den Datenschutzhinweisen von Pinterest: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://about.pinterest.com/de/privacy-policy",
              target: "_blank",
              title: "https://about.pinterest.com/de/privacy-policy"
            }
          },
          [_vm._v("https://about.pinterest.com/de/privacy-policy")]
        )
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Facebook")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "http://developers.facebook.com/docs/plugins/",
              target: "_blank",
              title: "http://developers.facebook.com/docs/plugins/"
            }
          },
          [_vm._v("http://developers.facebook.com/docs/plugins/")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "http://de-de.facebook.com/policy.php",
              target: "_blank",
              title: "http://de-de.facebook.com/policy.php"
            }
          },
          [_vm._v("http://de-de.facebook.com/policy.php")]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(
          " Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook- Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.\n      "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticStyle: { "font-size": "22px" } }, [
          _c("strong", [
            _vm._v("7. Online Mar­ke­ting und Part­ner­pro­gramme")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Amazon Part­ner­pro­gramm")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Die Betrei­ber der Seiten nehmen am Amazon EU- Part­ner­pro­gramm teil. Auf unse­ren Seiten werden durch Amazon Wer­be­an­zei­gen und Links zur Seite von Amazon .de ein­ge­bun­den, an denen wir über Wer­be­kos­ten­er­stat­tung Geld ver­die­nen können. Amazon setzt dazu Coo­kies ein, um die Her­kunft der Bestel­lun­gen nach­voll­zie­hen zu können. Dadurch kann Amazon erken­nen, dass Sie den Part­ner­link auf unse­rer Web­site geklickt haben. Die Spei­che­rung von “Amazon-Coo­kies” erfolgt auf Grund­lage von Art. 6 lit. f DSGVO. Der Web­site­be­trei­ber hat hieran ein berech­tig­tes Inter­esse, da nur durch die Coo­kies die Höhe seiner Affi­liate-Ver­gü­tung fest­stell­bar ist. Wei­tere Infor­ma­tio­nen zur Daten­nut­zung durch Amazon erhal­ten Sie in der Daten­schutz­er­klä­rung von Amazon: "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401",
              target: "_blank",
              title:
                "https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401"
            }
          },
          [
            _vm._v(
              "https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401"
            )
          ]
        ),
        _vm._v(".\n  ")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("\n       \n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Stand dieser Datenschutzerklärung")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " 08.02.2019 – Wir behalten uns vor, diese Datenschutzerklärung jederzeit mit Wirkung für die Zukunft zu ändern.\n  "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }