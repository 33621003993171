var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-field" }, [
    _vm.type === "password"
      ? _c("span", {
          staticClass: "toggle",
          class: { active: _vm.isPasswordVisible },
          on: {
            click: function($event) {
              return _vm.togglePasswordVisibility($event)
            }
          }
        })
      : _vm._e(),
    _c("input", {
      ref: "input",
      class: "input-" + _vm.type,
      attrs: {
        id: _vm.id,
        type: _vm.type,
        maxLength: _vm.max,
        placeholder: _vm.placeholder
      },
      domProps: { value: _vm.value },
      on: {
        input: function($event) {
          return _vm.onInput($event)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }