var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Aktivitäten",
        "sub-title": "Pendelkinder",
        footerButtons: _vm.footerButtons,
        fetching: _vm.fetching,
        showBottom: false
      },
      on: { submit: _vm.showApp }
    },
    [
      _c(
        "ul",
        { staticClass: "list news" },
        [
          _vm._l(_vm.news, function(newsObject) {
            return [
              _c("li", { staticClass: "header" }, [
                _vm._v(_vm._s(newsObject.date))
              ]),
              _vm._l(newsObject.group, function(newsEntry) {
                return _c(
                  "li",
                  {
                    staticClass: "news-entry",
                    class: {
                      deleted: newsEntry.deletedAt,
                      "is-new": newsEntry.isNew
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.newsEntryClick(newsEntry)
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(newsEntry.newsType))]),
                    _vm._v("\n|\n" + _vm._s(newsEntry.title))
                  ]
                )
              })
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }