<template lang="pug">
  td
    template(v-if="entry")
      span(v-if="entry.value") {{ entry.value }}
      span.no-entry(v-else)
      div(v-if="date && entry.value") {{ date }} 
</template>

<script>
export default {
  props: {
    timetable: {
      type: Object,
      required: true
    },
    weekDay: {
      type: Number,
      required: true
    },
    hour: {
      type: Number,
      required: true
    },
    timeEntry: {
      type: Object,
      required: true
    }
  },
  computed: {
    entry() {
      const key = `${this.weekDay}-${this.hour - 1}`
      const entry = this.timetable[key]
      if (entry) {
        return entry
      }
      return null
    },
    date() {
      if (
        !this.timeEntry ||
        !this.timeEntry.start_on ||
        !this.timeEntry.end_on
      ) {
        return
      }
      return `${this.timeEntry.start_on} - ${this.timeEntry.end_on} Uhr`
    }
  }
}
</script>

<style scoped lang="scss">
td {
  white-space: nowrap;

  div {
    color: #b7b7b7;
    font-size: 10px;
    line-height: 10px;
    margin-top: -1px;
  }

  .no-entry {
    color: #808080;
  }
}
</style>
