export default (path, { data, ...options }) => {
  const headers = options.headers || {
    "X-Requested-With": "XMLHttpRequest",
    "X-Key-Inflection": "camel",
    Accept: "application/json",
    "Content-Type": "application/json"
  }
  delete options.headers

  return fetch(path, {
    body: data instanceof FormData ? data : JSON.stringify(data),
    credentials: "same-origin",
    headers,
    ...options
  })
}

// import axios from "axios"
// import router from "../router"

// axios.defaults.headers.common['Accept'] = 'application/json'
// axios.defaults.headers.common['X-Key-Inflection'] = 'camel'

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response.status === 401) {
//       router.push({ name: "new_user_session" })
//     }
//     return Promise.reject(error)
//   }
// )

// const defaultHeaders = {
//   'Accept': 'application/json',
//   'X-Key-Inflection': 'camel'
// }

// export default (path, { data, headers, ...options }) => {
//   const sendHeaders = { ...(headers || {}), ...defaultHeaders}
//   console.log("arguments", path, data, sendHeaders, options)
//   if (options.method == 'POST') {
//     return axios.post(path, data, sendHeaders)
//   } else if (options.method == 'PUT') {
//     return axios.put(path, data, sendHeaders)
//   }
// }
