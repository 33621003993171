var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    { staticClass: "input-group mt-2" },
    [
      _c("input-field", {
        ref: "input",
        staticClass: "center",
        attrs: {
          id: _vm.name,
          type: _vm.type,
          value: _vm.value,
          max: _vm.maxLength
        },
        on: {
          input: function($event) {
            return _vm.onUpdateValue($event)
          }
        }
      }),
      _c("label", { attrs: { for: _vm.name } }, [
        _vm._v(_vm._s(_vm.label)),
        _vm.subLabel ? _c("span", [_vm._v(_vm._s(_vm.subLabel))]) : _vm._e()
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }