<template lang="pug">
app-container(
  :title="pageTitle"
  :sub-title="subTitle"
  :footerButtons="footerButtons"
  :fetching="fetching"
  @submit="addTodo"
  @goBack="goBack"
  @deleteTodo="deleteTodo"
)
  .calendar
    .strong-input-group(v-if="loaded")
      .row
        .col
          input(v-model="title" placeholder="Aufgabe")

      .row
        .left-col
          select-field(v-model="child" :options="childrenOptions" placeholder="Kind")
        .right-col
          select-field(v-model="parent" :options="parentOptionsWithBlank" placeholder="Elternteil" :default-value="defaultParentValue")

      .row
        .col
          textarea(v-model="description" placeholder="Beschreibung" rows="8")

      uploads(:files="uploadedFiles")

      .row
        .col
          input(placeholder="Datei" type="file" multiple @change="processFiles($event)")
</template>

<script>
import moment from "moment"
moment.locale("de")

import { mapState, mapGetters } from "vuex"
import Uploads from "../helpers/uploads.vue"
import AppContainer from "../app_container.vue"
import SelectField from "../helpers/select_field.vue"

export default {
  components: {
    AppContainer,
    SelectField,
    Uploads
  },
  data: () => ({
    files: [],
    fetching: false,
    child: null,
    title: null,
    parent: null,
    description: null,
    todo: null,
    loaded: false,
    file: null
  }),
  computed: {
    ...mapState({
      children: ({ children }) => children.children,
      todos: ({ todos }) => todos.todos,
      user: ({ user }) => user.user,
      accountMembers: ({ account }) => account.accountMembers
    }),
    ...mapGetters(["parentOptions"]),
    parentOptionsWithBlank() {
      return [
        ...[
          {
            id: "",
            value: "offen"
          }
        ],
        ...this.parentOptions
      ]
    },
    defaultParentValue() {
      return this.todo ? "-" : ""
    },
    pageTitle() {
      if (this.todo) {
        return "Aufgabe bearbeiten"
      } else {
        return "Neue Aufgabe"
      }
    },
    footerButtons() {
      let buttons = {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }

      if (this.todo) {
        buttons.right = {
          title: "Löschen",
          action: "deleteTodo"
        }
      }
      return buttons
    },
    subTitle() {
      return `${moment(this.date).format("YYYY")}`
    },
    childrenOptions() {
      let options = []
      Object.keys(this.children).forEach(i => {
        const child = this.children[i]
        options.push({ id: child.id, value: child.name })
      })
      return options
    },
    uploadedFiles() {
      if (!this.todo || !this.todo.uploads) {
        return []
      }
      return this.todo.uploads
    }
  },
  mounted() {
    if (this.$route.params.id) {
      const todo = this.todos[this.$route.params.id]
      if (todo) {
        this.child = todo.childId
        this.title = todo.title
        this.description = todo.description
        this.parent = todo.parent ? todo.parent.id : null

        this.todo = todo
      }
    } else {
      const childValues = Object.values(this.children)
      if (childValues.length == 1) {
        this.child = childValues[0].id
      }
    }
    this.loaded = true
  },
  methods: {
    async addTodo() {
      if (this.child && this.title) {
        const todo = {
          childId: this.child,
          title: this.title,
          description: this.description,
          parentId: this.parent,
          files: this.files
        }

        try {
          this.fetching = true
          if (this.todo) {
            todo.id = this.todo.id
            await this.$store.dispatch("updateTodo", todo)
          } else {
            await this.$store.dispatch("createTodo", todo)
          }

          this.$router.go(-1)
        } catch (e) {
          console.log(e)
          this.notify("Eintrag konnte nicht gespeichert werden.", "danger")
        } finally {
          this.fetching = false
        }
      } else {
        this.notify("Bitte alle Felder ausfüllen.", "danger")
      }
    },
    async deleteTodo() {
      if (confirm("Soll der Eintrag wirklich gelöscht werden?")) {
        await this.$store.dispatch("removeTodo", this.todo)
        this.$router.go(-1)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    processFiles(event) {
      this.files = event.target.files
    }
  }
}
</script>

<style scoped>
.upload {
  width: 100%;
  max-height: 100%;
}
</style>
