window.addEventListener("message", receiveMessage, false)

export const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
const askSessions = {}
function receiveMessage(event) {
  let data = event.data
  if (typeof event.data === "string" || typeof event.data === "String") {
    try {
      data = JSON.parse(event.data)
    } catch (e) {
      return
    }
  }

  const { key, value } = data
  const askSession = askSessions[key]
  if (!askSession) {
    return
  }

  askSession(value)
  delete askSessions[value]
}

export const ask = (title, options, callback) => {
  const key = new Date().getTime().toString()

  askSessions[key] = callback
  document.location.href = `pendelkinder://sheet?key=${key}&title=${encodeURIComponent(
    title
  )}&options=${encodeURIComponent(JSON.stringify(options))}`
}
