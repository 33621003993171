var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: _vm.pageTitle,
        "sub-title": _vm.subTitle,
        footerButtons: _vm.footerButtons,
        fetching: _vm.fetching
      },
      on: {
        submit: _vm.addTodo,
        goBack: _vm.goBack,
        deleteTodo: _vm.deleteTodo
      }
    },
    [
      _c("div", { staticClass: "calendar" }, [
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "strong-input-group" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title"
                        }
                      ],
                      attrs: { placeholder: "Aufgabe" },
                      domProps: { value: _vm.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.title = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "left-col" },
                    [
                      _c("select-field", {
                        attrs: {
                          options: _vm.childrenOptions,
                          placeholder: "Kind"
                        },
                        model: {
                          value: _vm.child,
                          callback: function($$v) {
                            _vm.child = $$v
                          },
                          expression: "child"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right-col" },
                    [
                      _c("select-field", {
                        attrs: {
                          options: _vm.parentOptionsWithBlank,
                          placeholder: "Elternteil",
                          "default-value": _vm.defaultParentValue
                        },
                        model: {
                          value: _vm.parent,
                          callback: function($$v) {
                            _vm.parent = $$v
                          },
                          expression: "parent"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.description,
                          expression: "description"
                        }
                      ],
                      attrs: { placeholder: "Beschreibung", rows: "8" },
                      domProps: { value: _vm.description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.description = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("uploads", { attrs: { files: _vm.uploadedFiles } }),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("input", {
                      attrs: {
                        placeholder: "Datei",
                        type: "file",
                        multiple: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.processFiles($event)
                        }
                      }
                    })
                  ])
                ])
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }