var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-onboarding-start uk-text-center" }, [
    _c("h4", { staticClass: "title" }, [_vm._v("Leseberechtigung")]),
    _c("p", [
      _vm._v(
        "Du kannst Leseberechtigungen für den Kalender, Stundenplan, Kontakte und die Uploads vergeben. Dies geschieht über einen Netzwerkcode, der von deiner Patchworkfamilie genutzt und nach dem Download der App eingegeben werden kann. Deinen Netzwerkcode findest du auch unter den Einstellungen in der App."
      )
    ]),
    _c("div", { staticClass: "big-input-group" }, [
      _c("input", {
        staticClass: "input uk-text-center",
        attrs: { type: "text", readonly: "" },
        domProps: { value: _vm.code }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }