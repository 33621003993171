import { render, staticRenderFns } from "./todos.vue?vue&type=template&id=ae82c292&lang=pug&"
import script from "./todos.vue?vue&type=script&lang=js&"
export * from "./todos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports