var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        "footer-buttons": _vm.footerButtons,
        "show-navigation-bar-button": false,
        fetching: _vm.isLoading
      },
      on: {
        submitForm: _vm.submitForm,
        goStepBack: _vm.goStepBack,
        skip: _vm.skip
      }
    },
    [
      _c(
        "div",
        { staticClass: "app-onboarding" },
        [
          _c("onboarding-steps"),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _c("router-view", {
                model: {
                  value: _vm.isFormValid,
                  callback: function($$v) {
                    _vm.isFormValid = $$v
                  },
                  expression: "isFormValid"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }