<template lang="pug">
app-container(
  :title="t('code_sign_in.nav_bar.title')"
  :sub-title="t('pendelkinder')"
  :fetching="submitting"
  :footerButtons="footerButtons"
  @submit="onSubmit"
)
  form(@submit.prevent="onSubmit")
    input-group(
      name="code"
      type="text"
      :label="t('code_sign_in.labels.code')"
      v-model.trim="code"
    )
    button.invisible(:disabled="!submittable") Einloggen
</template>

<script>
import AppContainer from "../app_container.vue"
import InputGroup from "../helpers/input_group.vue"

export default {
  components: { AppContainer, InputGroup },
  data() {
    return {
      code: "",
      submitting: false,
      submittable: true,
      footerButtons: {
        left: {
          title: "Login",
          action: "new_user_session",
          active: true
        },
        right: {
          title: "Anmelden",
          link: "new_user_registration",
          active: false
        }
      }
    }
  },
  methods: {
    async onSubmit() {
      this.submitting = true

      const { code } = this
      try {
        await this.$store.dispatch("signIn", {
          code,
          rememberMe: true
        })

        this.$router.push({ name: "app_start" })
      } catch (e) {
        this.notify(
          this.t("code_sign_in.errors.wrong_code"),
          "danger"
        )
      }
      this.submitting = false
    }
  }
}
</script>

<style scoped>
form {
  margin-top: 4rem;
}
</style>
