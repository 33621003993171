<template lang="pug">
app-container(:title="pageTitle" :fetching="loading" :footerButtons="footerButtons" @submit="addUpload" @goBack="goBack" @deleteUpload="deleteUpload")
  .upload
    .strong-input-group(v-if="loaded")
      .row
        .col
          input(v-model="title" placeholder="Titel des Dokuments")

      .row
        .left-col
          multi-select(v-model="childIds" :options="childrenOptions" placeholder="Kind" tip="Kind/er auswählen")

      uploads(:files="uploadedFiles")

      .row
        .col
          input(placeholder="Datei" type="file" multiple @change="processFiles($event)")
</template>

<script>
import AppContainer from "../app_container.vue"
import MultiSelect from "../helpers/multi_select.vue"
import Uploads from "../helpers/uploads.vue"
import { mapState, mapActions } from "vuex"

import moment from "moment"
moment.locale("de")

export default {
  components: {
    AppContainer,
    MultiSelect,
    Uploads
  },
  data: () => ({
    childIds: [],
    title: null,
    parent: null,
    description: null,
    upload: null,
    loaded: false,
    files: [],
    loading: false,
    displayFileLink: false
  }),
  computed: {
    ...mapState({
      children: ({ children }) => children.children,
      uploads: ({ uploads }) => uploads.uploads
    }),
    pageTitle() {
      if (this.upload) {
        return "Handzettel bearbeiten"
      } else {
        return "Neuer Handzettel"
      }
    },
    footerButtons() {
      let buttons = {
        left: {
          title: "Abbrechen",
          action: "goBack"
        }
      }

      if (this.upload) {
        buttons.right = {
          title: "Löschen",
          action: "deleteUpload"
        }
      }
      return buttons
    },
    childrenOptions() {
      let options = []
      Object.keys(this.children).forEach(i => {
        const child = this.children[i]
        options.push({ id: child.id, value: child.name })
      })
      return options
    },
    uploadedFiles() {
      if (!this.upload || !this.upload.uploads) {
        return []
      }
      return this.upload.uploads
    }
  },
  mounted() {
    if (this.$route.params.id) {
      const upload = this.uploads[this.$route.params.id]
      if (upload) {
        this.childIds = upload.childIds
        this.title = upload.title

        this.upload = upload
      }
    } else {
      const childValues = Object.values(this.children)
      if (childValues.length == 1) {
        this.childIds = [childValues[0].id]
      }
    }
    this.loaded = true
  },
  methods: {
    ...mapActions(["updateUpload", "createUpload", "removeUpload"]),
    async addUpload() {
      this.loading = true
      if (this.childIds && this.title) {
        const upload = {
          childIds: this.childIds,
          title: this.title,
          files: this.files
        }

        try {
          if (this.upload) {
            upload.id = this.upload.id
            await this.updateUpload(upload)
          } else {
            await this.createUpload(upload)
          }

          this.$router.go(-1)
        } catch (e) {
          console.log(e)
          this.notify("Eintrag konnte nicht gespeichert werden.", "danger")
        }
      } else {
        this.notify("Bitte alle Felder ausfüllen.", "danger")
      }
      this.loading = false
    },
    async deleteUpload() {
      if (confirm("Soll der Eintrag wirklich gelöscht werden?")) {
        await this.removeUpload(this.upload)
        this.goBack()
      }
    },
    processFiles(event) {
      this.files = event.target.files
    },
    displayFile() {
      this.displayFileLink = true
    }
  }
}
</script>

<style scoped>
.upload {
  width: 100%;
  max-height: 100%;
}
</style>
