var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        staticClass: "input strong-placeholder",
        attrs: {
          readonly: "",
          placeholder: "Passwort",
          "sub-label": "Kein Leerzeichen und mindestens 6 Zeichen",
          autocomplete: "new-password"
        }
      }),
      _c("div", { staticClass: "appended" }, [
        _c("button", { on: { click: _vm.wantEditPassword } }, [
          _vm._v("ändern")
        ])
      ]),
      _c(
        "vk-modal",
        {
          attrs: { show: _vm.showPasswordConfirmation, "overflow-auto": "" },
          on: {
            "update:show": function($event) {
              _vm.showPasswordConfirmation = $event
            }
          }
        },
        [
          _c(
            "form",
            { on: { submit: _vm.submit } },
            [
              _c("vk-modal-close", {
                on: {
                  click: function($event) {
                    _vm.showPasswordConfirmation = false
                  }
                }
              }),
              _c(
                "vk-modal-title",
                { attrs: { slot: "header" }, slot: "header" },
                [_vm._v("Passwort ändern")]
              ),
              _c(
                "p",
                { staticClass: "strong-input-group" },
                [
                  _c("input-field", {
                    attrs: {
                      id: "password",
                      type: "password",
                      placeholder: "Passwort"
                    },
                    model: {
                      value: _vm.newPassword,
                      callback: function($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword"
                    }
                  })
                ],
                1
              ),
              _c("p", [
                _vm._v("Bitte bestätige das eingegebene Passwort noch einmal:")
              ]),
              _c(
                "p",
                { staticClass: "strong-input-group" },
                [
                  _c("input-field", {
                    class: _vm.passwordConfirmationClass,
                    attrs: {
                      id: "confirm-password",
                      type: "password",
                      placeholder: "Passwort bestätigen"
                    },
                    model: {
                      value: _vm.passwordConfirmation,
                      callback: function($$v) {
                        _vm.passwordConfirmation = $$v
                      },
                      expression: "passwordConfirmation"
                    }
                  })
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  "Zum Bestätigen gebe hier bitte dein aktuelles Passwort ein:"
                )
              ]),
              _c(
                "p",
                { staticClass: "strong-input-group" },
                [
                  _c("input-field", {
                    attrs: {
                      id: "current-password",
                      type: "password",
                      placeholder: "aktuelles Passwort"
                    },
                    model: {
                      value: _vm.currentPassword,
                      callback: function($$v) {
                        _vm.currentPassword = $$v
                      },
                      expression: "currentPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "uk-text-right",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("input", {
                    staticClass:
                      "uk-button uk-margin-small-right uk-button-submit",
                    attrs: { type: "submit", value: "Passwort ändern" }
                  })
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }