var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Password vergessen",
        "sub-title": "Pendelkinder",
        "footer-buttons": _vm.footerButtons
      },
      on: { submit: _vm.submit, goBack: _vm.goBack }
    },
    [
      _c("p", [
        _vm._v(
          "Gib hier deine E-Mailadresse an, mit der du dich bei Pendelkinder registriert hast. Du bekommst dann in Kürze eine E-Mail mit einer Anleitung, wie du dein Passwort zurücksetzen kannst."
        )
      ]),
      _c("p", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          staticClass: "uk-input",
          attrs: { type: "email" },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }