var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Passwort",
        "sub-title": "Pendelkinder",
        "footer-buttons": _vm.footerButtons
      },
      on: { submit: _vm.submit, goBack: _vm.goBack }
    },
    [
      _c(
        "form",
        {
          staticClass: "uk-text-center",
          attrs: { action: "#" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("h1", [_vm._v("Passwort zurücksetzen")]),
          _vm.state == "invalidToken"
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "Das Passwort kann nicht geändert werden. Bitte fordere eine neue Anfrage an:"
                  )
                ])
              ])
            : _c("div", [
                _c("p", [_vm._v("Bitte gib ein neues Passwort an.")]),
                _c("p", { class: { error: _vm.state === "error" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.password,
                        expression: "password",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "uk-input",
                    attrs: { type: "password", placeholder: "Passwort" },
                    domProps: { value: _vm.password },
                    on: {
                      change: _vm.resetError,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value.trim()
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]),
                _vm.state === "error"
                  ? _c("div", [
                      _vm._v(
                        "Passwort darf keine Leerzeichen enthalten und muss mindestens 6 Zeichen lang sein."
                      )
                    ])
                  : _vm._e(),
                _c("p")
              ]),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "uk-button",
                attrs: { type: "submit", role: "submit" }
              },
              [_vm._v("Weiter")]
            ),
            _c(
              "p",
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "new_user_session" } } },
                  [_vm._v("Login")]
                )
              ],
              1
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }