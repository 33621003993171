var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        "footer-buttons": _vm.footerButtons
      },
      on: { submit: _vm.onSubmit }
    },
    [
      _c("h1", [_vm._v("Vielen Dank")]),
      _c("p", [
        _vm._v(
          "Vielen Dank für deine Anmeldung. Du bekommst in Kürze eine E-Mail von uns mit dem Bestätigungslink. Solltest du keine E-Mail in deinem Posteingang finden, schaue bitte bei dir im Spam-Ordner nach. Klicke auf den Bestätigungslink und fahre mit der Anmeldung fort."
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }