<template lang="pug">
app-container(title="Einstellungen" :fetching="submitting" sub-title="Pendelkinder" @submit="submit")
  .download
    .strong-input-group(v-if="!isExported")
      .row
        .col
          p Hier kannst du alle wichtigen Aktivitäten die ihr in dieser App getätigt habt als PDF zusammenführen. Wir schicken dir das PDF an deine hinterlegte E-Mail-Adresse.

      .row(:class="{ disabled: isCompleteOption }")
        .left-col
          input(v-model="startDate" placeholder="Startdatum" type="date" :disabled="isCompleteOption")
        .right-col
          input(v-model="endDate" placeholder="Enddatum" type="date" :disabled="isCompleteOption")

      .row
        .col
          label(v-for="option in downloadOptions" :for="`download-${option.id}`" :key="option.id")
            input(type="radio" v-model="downloadOption" :value="option.id" :id="`download-${option.id}`")
            span {{ option.value }}
    div(v-else)
      p
        i Export wurde gestartet, bitte prüfe deine Inbox.
</template>

<script>
import AppContainer from "../app_container.vue"
import { mapState } from "vuex"

import moment from "moment"
moment.locale("de")

export default {
  components: {
    AppContainer
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      downloadOption: "custom",
      submitting: false,
      isExported: false
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user
    }),
    isCompleteOption() {
      return this.downloadOption === "complete"
    },
    downloadOptions() {
      return [
        {
          id: "custom",
          value: "eigenes Zeitfenster"
        },
        {
          id: "complete",
          value: "seit Veröffentlichung"
        }
      ]
    }
  },
  mounted() {
    this.startDate = moment(this.user.createdAt).format("YYYY-MM-DD")
    this.endDate = moment().format("YYYY-MM-DD")
  },
  methods: {
    async submit() {
      if (this.isExported) {
        this.goTo({ name: "root" })
        return
      }
      if (
        !this.isCompleteOption &&
        (!this.startDate.length || !this.endDate.length)
      ) {
        this.notify("Bitte gib ein Datum an.", "danger")
        return
      }

      const form = {
        download_option: this.downloadOption,
        start_date: this.isCompleteOption ? null : this.startDate
      }
      this.submitting = true
      try {
        await this.$http.post(`/api/news`, form)
        this.isExported = true
      } catch (e) {
        this.notify(
          "Report konnte nicht angelegt werden. Bitte versuche es später erneut.",
          "danger"
        )
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style scoped>
label span {
  padding: 0px 10px 0px 3px;
  font-size: 12px;
}

.disabled {
  opacity: 0.4;
}
</style>
