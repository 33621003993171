var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Messenger",
        "sub-title": _vm.chatName,
        footerButtons: _vm.footerButtons
      }
    },
    [
      _c(
        "template",
        { slot: "centerView" },
        [
          _c("upload-form", {
            ref: "uploadForm",
            model: {
              value: _vm.previewImage,
              callback: function($$v) {
                _vm.previewImage = $$v
              },
              expression: "previewImage"
            }
          }),
          _c(
            "form",
            {
              ref: "form",
              attrs: {
                "uk-tooltip": "Emojies werden aus der Nachricht entfernt"
              },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.sendMessage($event)
                }
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "left",
                  attrs: { type: "button" },
                  on: { click: _vm.selectUpload }
                },
                [
                  _c("img", {
                    attrs: { src: "/btn-add.png", width: "40", height: "40" }
                  })
                ]
              ),
              _c("textarea-autosize", {
                ref: "input",
                attrs: {
                  rows: "1",
                  placeholder: "DEINE NACHRICHT",
                  "max-height": 72,
                  "min-height": 32
                },
                nativeOn: {
                  focus: function($event) {
                    return _vm.scrollToBottom($event)
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass: "right",
                  attrs: { type: "submit" },
                  on: {
                    mousedown: function($event) {
                      $event.preventDefault()
                      return _vm.sendMessage($event)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/btn-confirm.png",
                      width: "40",
                      height: "40"
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { ref: "messages", staticClass: "messages" },
        _vm._l(_vm.messages, function(message) {
          return _c("message", {
            key: message.id,
            attrs: { message: message },
            on: { showImage: _vm.showImage }
          })
        }),
        1
      ),
      _c("div", { staticClass: "message-bottom" }),
      _c("vk-modal-full", { attrs: { show: _vm.shouldShowImage } }, [
        _c(
          "div",
          {
            staticClass: "fullscreen-image",
            on: {
              click: function($event) {
                _vm.fullscreenImage = null
              }
            }
          },
          [
            _vm.fullscreenImage
              ? _c("img", { attrs: { src: _vm.fullscreenImage } })
              : _vm._e()
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }