export default {
  model: {
    prop: "isFormValid",
    event: "isFormValidChange"
  },
  props: {
    isFormValid: {
      type: Boolean,
      required: true
    }
  }
}
