var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Aufgaben",
        "sub-title": _vm.year,
        footerButtons: _vm.footerButtons,
        fetching: _vm.loadingTodos,
        showBottom: true
      },
      on: { submit: _vm.addTodo }
    },
    [
      _c("div", { staticClass: "todos" }, [
        _vm.todosList.length
          ? _c("table", { staticClass: "list" }, [
              _c(
                "tbody",
                _vm._l(_vm.todosList, function(todo, i) {
                  return _c("tr", [
                    _c("th", [_vm._v(_vm._s(i + 1) + ".")]),
                    _c(
                      "td",
                      { staticClass: "day" },
                      [_c("todo-record", { attrs: { todo: todo } })],
                      1
                    )
                  ])
                }),
                0
              )
            ])
          : _c("p", { staticClass: "flex" }, [
              _c("i", [_vm._v("Keine Aufgaben eingetragen")])
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }