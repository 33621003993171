<template lang="pug">
.inline-select-field
  select(ref="select" @change="change")
    option(
      v-for="option in options"
      :value="option.value"
      :selected="option.value == value"
      :key="option.value"
    )
      | {{ option.title }}
  span ▼
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    options: {
      required: true,
      type: Array
    }
  },
  methods: {
    change() {
      this.$emit("input", this.$refs.select.value)
    }
  }
}
</script>

<style scoped>
select {
  background: none;
  outline: none;
  font-family: "AcuminPro-Light";
  color: #808080;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
</style>
