var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Einstellungen",
        fetching: _vm.submitting,
        "sub-title": "Pendelkinder"
      },
      on: { submit: _vm.submit }
    },
    [
      _c("div", { staticClass: "download" }, [
        !_vm.isExported
          ? _c("div", { staticClass: "strong-input-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("p", [
                    _vm._v(
                      "Hier kannst du alle wichtigen Aktivitäten die ihr in dieser App getätigt habt als PDF zusammenführen. Wir schicken dir das PDF an deine hinterlegte E-Mail-Adresse."
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "row",
                  class: { disabled: _vm.isCompleteOption }
                },
                [
                  _c("div", { staticClass: "left-col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.startDate,
                          expression: "startDate"
                        }
                      ],
                      attrs: {
                        placeholder: "Startdatum",
                        type: "date",
                        disabled: _vm.isCompleteOption
                      },
                      domProps: { value: _vm.startDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.startDate = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "right-col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.endDate,
                          expression: "endDate"
                        }
                      ],
                      attrs: {
                        placeholder: "Enddatum",
                        type: "date",
                        disabled: _vm.isCompleteOption
                      },
                      domProps: { value: _vm.endDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.endDate = $event.target.value
                        }
                      }
                    })
                  ])
                ]
              ),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  _vm._l(_vm.downloadOptions, function(option) {
                    return _c(
                      "label",
                      {
                        key: option.id,
                        attrs: { for: "download-" + option.id }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.downloadOption,
                              expression: "downloadOption"
                            }
                          ],
                          attrs: { type: "radio", id: "download-" + option.id },
                          domProps: {
                            value: option.id,
                            checked: _vm._q(_vm.downloadOption, option.id)
                          },
                          on: {
                            change: function($event) {
                              _vm.downloadOption = option.id
                            }
                          }
                        }),
                        _c("span", [_vm._v(_vm._s(option.value))])
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          : _c("div", [
              _c("p", [
                _c("i", [
                  _vm._v("Export wurde gestartet, bitte prüfe deine Inbox.")
                ])
              ])
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }