<template lang="pug">
  .steps
    button(
      v-for="onboardingRoute in onboardingChildRoutes"
      :key="onboardingRoute.name"
      :class="{ active: $route.name == onboardingRoute.name }"
    )
</template>

<script>
import onboardingRoutes from "../mixins/onboarding_routes"

export default {
  mixins: [onboardingRoutes]
}
</script>

<style lang="scss">
@import "../../../../assets/stylesheets/variables.scss";

.steps {
  text-align: center;
  margin-bottom: 30px;

  button {
    width: 10px;
    height: 10px;
    background-color: $middle-gray-color;
    outline: none;
    margin: 2px;
    padding: 2px;
    display: inline-block;
    appearance: none;
    border: none;
    background-clip: content-box;
    box-sizing: content-box;
    padding: 0;
    border-radius: 100%;
    outline: none;

    &.active {
      background-color: $turkies-color;
    }
  }
}
</style>
