<template lang="pug">
app-container.small(title="Kontakt" sub-title="Pendelkinder" :footerButtons="footerButtons" :showBottom="false" :fetching="fetching" @submit="addContact")
  ul.list.contacts(v-if="allContacts.length > 0")
    template(v-for="contactObject in contacts")
      li.header {{ contactObject.char }}
      router-link.contact(
        v-for="contact in contactObject.group"
        :key="contact.id"
        tag="li"
        :to="{ name: 'contact', params: { id: contact.id } }"
      )
        | {{ contact.firstName }} {{ contact.lastName }}
  p.flex(v-else)
    i Keine Kontakte eingetragen
</template>

<script>
import AppContainer from "../app_container.vue"

export default {
  components: { AppContainer },
  data: () => ({
    fetching: false,
    allContacts: [],
    contacts: [],
    footerButtons: {
      center: {
        type: "plus"
      }
    }
  }),
  mounted() {
    this.fetchContacts()
  },
  methods: {
    async fetchContacts() {
      this.fetching = true
      const response = await this.$http.get("/api/contacts")
      let contacts = []
      let group = []

      if (response.ok) {
        let lastChar = ""
        this.allContacts = response.body
        this.allContacts.forEach(contact => {
          const firstChar = contact.firstName[0].toUpperCase()
          if (contact && firstChar != lastChar) {
            if (lastChar != "") {
              contacts.push({ char: lastChar, group: group })
              group = []
            }
            lastChar = firstChar
          }

          group.push(contact)
        })

        contacts.push({ char: lastChar, group: group })
        this.contacts = contacts
      }
      this.fetching = false
    },
    addContact() {
      this.$router.push({ name: "new_contact" })
    }
  }
}
</script>

