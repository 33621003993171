<template lang="pug">
  div
    input.input(type="text" value="Account löschen" readonly)
    .appended
      button(@click="wantDeleteAccount") Löschen

    vk-modal(:show.sync="showDeleteConfirmation" overflow-auto)
      form(@submit="submit")
        vk-modal-close(@click="showDeleteConfirmation = false")
        vk-modal-title(slot="header") Account löschen
        p Bitte gib dein Passwort ein, um dein Account zu löschen. Deine Daten & dein Account werden daraufhin innerhalb von 24 Stunden gelöscht.
        p.strong-input-group
          input(v-model="password" type="password" placeholder="Passwort")

        .uk-text-right(slot="footer")
          input.uk-button.uk-margin-small-right.uk-button-submit(type="submit" value="Löschen")
</template>

<script>
import { mapActions } from "vuex"

export default {
  data() {
    return {
      showDeleteConfirmation: false,
      password: null
    }
  },
  methods: {
    ...mapActions(["deleteAccount"]),
    wantDeleteAccount() {
      this.showDeleteConfirmation = true
    },
    async submit(event) {
      event.preventDefault()

      const form = {
        password: this.password
      }

      this.$emit("startLoading")
      const result = await this.deleteAccount(form)
      this.$emit("doneLoading")

      if (result) {
        this.showDeleteConfirmation = false
        this.notify("Dein Account wird in den nächsten 24 Stunden gelöscht.")
      } else {
        this.notify("Account konnte nicht gelöscht werden. Bitte prüfe das eingegebene Passwort.")
      }
    }
  }
}
</script>
