<template lang="pug">
app-container(title="Einstellungen" :loading="loading" sub-title="Pendelkinder" :footer-buttons="footerButtons" @submit="submit" @openAbonnement="openAbonnement" @openTestphase="openTestphase")
  section
    h3 Pendelkinder
    child-input(v-for="child in children" :child="child" :key="child.id" @submit="removeChild" @change="updateChild")
    child-input(:child="newChild" action="new" @submit="addChild")

  section
    h3 Elternteil 1
    .big-input-group
      input.input(id="name" type="text" v-model.trim="name" placeholder="Dein Vorname")
      .appended
        button(@click="updateName") Ändern

    .big-input-group
      input.input(id="username" type="text" v-model.trim="username" placeholder="Dein Username" maxlength="2")
      .appended
        button(@click="updateUsername") Ändern

    .big-input-group
      input.input(id="email" type="text" v-model.trim="email" readonly placeholder="Deine E-Mail-Adresse")
      .appended
        button Aktiv

    .big-input-group
      select.input(v-model="parentMode" :class="selectClass(parentMode)" @change="updateParentMode")
        option(value="") Welcher Elternteil bist Du?
        //- TODO add dymaic fields
        option(value="mom") Mutter
        option(value="dad") Vater
      .appended
        button {{ user.username }}

  section
    h3 Elternteil 2
    .big-input-group
      input.input(id="parent" type="text" v-model.trim="parentEmail" placeholder="E-Mail-Adresse anderer Elternteil")
      .appended
        button(@click="invite") {{ parentConnectionTitle }}
  
  section
    h3 Allgemein
    .big-input-group
      input.input(type="text" value="Aktivitäten" readonly)
      .appended
        button(@click="goTo({ name: 'news_download' })") Download

    .big-input-group
      select.input(v-model="state" :class="selectClass(state)")
        option(value="") Ferien / Bundesland (optional)
        option(v-for="option in stateOptions" :value="option.value") {{ option.text }}
      .appended
        button(@click="reset") Hinzufügen

    edit-password-modal.big-input-group(@startLoading="loading = true" @doneLoading="loading = false")

    .big-input-group
      input.input(type="text" value="Push-Benarchrichtigung" readonly :class="{ inactive: !isPushActive }")
      .appended
        button(@click="togglePushNotifications") {{ pushActive }}

  section
    h3 Netzwerkcode
    .big-input-group
      input.input(ref="code" type="text" :value="code" readonly)
      .appended
        button(@click="copyCode") Kopieren

  section
    h3 Account löschen
    div(v-show="user.markedAsDeletedAt")
      p Dein Account wird in den nächsten 24 Stunden gelöscht.
      .big-input-group
        input.input(type="text" value="Löschen abbrechen" readonly)
        .appended
          button(@click="cancelDeletion") Abbrechen
    delete-account-modal.big-input-group(
      v-show="!user.markedAsDeletedAt"
      @startLoading="loading = true"
      @doneLoading="loading = false"
    )

  p.margin.info-text
    vue-markdown {{ accountText }}
  p.margin.off.info-text
    router-link.underlined(:to="{ name: 'page', params: { permalink: 'help' } }") HILFE

</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import AppContainer from "../app_container.vue"
import InputGroup from "../helpers/input_group.vue"
import ChildInput from "./child_input.vue"
import SelectField from "../helpers/select_field.vue"
import EditPasswordModal from "./edit_password_modal.vue"
import DeleteAccountModal from "./delete_account_modal.vue"
import VueMarkdown from "vue-markdown"

export default {
  components: {
    AppContainer,
    ChildInput,
    InputGroup,
    SelectField,
    EditPasswordModal,
    DeleteAccountModal,
    VueMarkdown
  },
  data() {
    return {
      loading: false,
      state: null,
      parentMode: null,
      email: null,
      name: null,
      username: null,
      parentEmail: "",
      newChild: {
        id: -1,
        name: ""
      }
    }
  },
  computed: {
    ...mapState({
      children: ({ children }) => children.children,
      profile: ({ profile }) => profile.profile,
      user: ({ user }) => user.user,
      parentConnectionTitle() {
        if (this.parent.state == "accepted") {
          return "Verbunden"
        } else if (this.parent.state === "invited" && this.parent.email) {
          return "Gesendet"
        } else {
          return "Verbinden"
        }
      },
      isPushActive() {
        return !this.user || this.user.isPushActive
      },
      pushActive() {
        return this.isPushActive ? "Aktiv" : "Inaktiv"
      },
      code() {
        return this.$store.state.account.account.accessCode
      }
    }),
    ...mapGetters(["parents", "isSubscription", "isTestAccount"]),
    parent() {
      if (Object.keys(this.parents).length == 0) {
        return {}
      }
      return Object.values(this.parents)[0]
    },
    footerButtons() {
      if (!this.user || this.user.isGuest) {
        return {}
      }
      return {
        left: {
          title: "Vollversion",
          action: "openAbonnement",
          active: this.isSubscription
        },
        right: {
          title: "Testphase",
          action: "openTestphase",
          active: this.isTestAccount
        }
      }
    },
    stateOptions() {
      return [
        { value: "BW", text: "Baden-Württemberg" },
        { value: "BY", text: "Bayern" },
        { value: "BE", text: "Berlin" },
        { value: "BB", text: "Brandenburg" },
        { value: "HB", text: "Bremen" },
        { value: "HH", text: "Hamburg" },
        { value: "HE", text: "Hessen" },
        { value: "MV", text: "Mecklenburg-Vorpommern" },
        { value: "NI", text: "Niedersachsen" },
        { value: "NW", text: "Nordrhein-Westfalen" },
        { value: "RP", text: "Rheinland-Pfalz" },
        { value: "SL", text: "Saarland" },
        { value: "SN", text: "Sachsen" },
        { value: "ST", text: "Sachsen-Anhalt" },
        { value: "SH", text: "Schleswig-Holstein" },
        { value: "TH", text: "Thüringen" }
      ]
    },
    accountText() {
      if (!this.isTestAccount) {
        return null
      }
      return "Du befindest dich z. Zt. in der Testphase und kannst die App für 7 Tage kostenlos testen. Um die App nach Ablauf der Testphase weiter nutzen können, hast du die Möglichkeit, die Vollversion für einmalig 9,99 € zu erwerben."
    }
  },
  mounted() {
    this.parentEmail = this.parent.email
    this.state = this.profile.state || ""
    this.parentMode = this.profile.parentMode || ""
    this.email = this.user.email || ""
    this.name = this.user.name || ""
    this.username = this.user.username

    // if (this.parentMode === "" || this.parentEmail === "" || !this.parentEmail || !this.children || !Object.values(this.children).length) {
    //   console.log("in", this.parentMode === "", this.parentEmail === "", !this.parentEmail, !this.children, !Object.values(this.children).length, Object.values(this.children))
    //   this.notify(
    //     "<h1>Achtung, bitte ausfüllen!</h1><p>Um die App im vollen Umfang nutzen zu können, trage die fehlenden Informationen ein.</p>",
    //     "missing"
    //   )
    // }
  },
  methods: {
    ...mapActions(["togglePushNotifications", "deleteAccount"]),
    async submit() {
      await this.$store.dispatch("updateProfile", { state: this.state })
      this.$router.push({ name: "calendar" })
    },
    async doLogout() {
      await this.$store.dispatch("signOut")
      this.$router.push({ name: "new_user_session" })
    },
    async reset() {
      await this.$store.dispatch("updateProfile", { state: this.state })
      await this.$store.dispatch("checkSignin")
    },
    async addChild(child) {
      await this.$store.dispatch("createChild", child)
      child.name = ""
    },
    async updateName() {
      await this.$store.dispatch("updateUser", { name: this.name })
      this.notify("Name wurde geändert", "danger")
    },
    async updateUsername() {
      if (!this.username || this.username.length !== 2) {
        this.notify("Username muss zwei Zeichen enthalten", "danger")
        return
      }

      await this.$store.dispatch("updateUser", { username: this.username })
      await this.$store.dispatch("reloadSession")
      this.username = this.user.username
      this.notify("Username wurde geändert", "danger")
    },
    async invite() {
      if (this.parent && this.parent.state === "accepted") {
        this.notify("Es wurde bereits ein Elternteil eingeladen.", "danger")
        return
      }

      if (
        this.parent &&
        this.parent.email &&
        !confirm(
          "Soll ein neues Elternteil eingeladen werden? Wenn ja, wird die erste Einladung ungültig."
        )
      ) {
        return
      }
      try {
        await this.$store.dispatch("inviteParent", { email: this.parentEmail })
      } catch (e) {
        this.notify(
          "Elternteil konnte nicht eingeladen werden. Stimmt die E-Mail-Adresse?",
          "danger"
        )
      }
    },
    removeChild(child) {
      if (confirm(`Soll das Kind ${child.name} wirklich gelöscht werden?`)) {
        this.$store.dispatch("removeChild", child)
      }
    },
    updateChild(child) {
      this.$store.dispatch("updateChild", child)
    },
    openAbonnement() {
      this.goTo({ name: "new_subscription" })
    },
    openTestphase() {
      this.goTo({ name: "new_subscription" })
    },
    updateParentMode() {
      this.$store.dispatch("updateProfile", { parentMode: this.parentMode })
    },
    cancelDeletion() {
      this.deleteAccount({})
    },
    selectClass(model) {
      return {
        placeholder: !model || model === ""
      }
    },
    copyCode() {
      const codeField = this.$refs.code
      codeField.select()
      codeField.setSelectionRange(0, 99999)
      document.execCommand("copy")
      alert(`Code wurde kopiert: ${this.code}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.margin {
  padding: 5px 10px;

  &.off {
    padding-top: 0px;
    margin-top: -25px;
  }

  a {
    color: #808080;
    text-decoration: none;

    &.underlined {
      font-family: Arial, Helvetica;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

section h3 {
  color: #00baee;
  margin: 15px 0px 5px;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1.1em;
}

select.placeholder {
  color: #959595;
}
</style>
