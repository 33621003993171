import fetchApi from "../lib/fetch_api.js"

export default {
  state: {
    pages: {},
    isPageLoading: false
  },
  mutations: {
    setPage(state, page) {
      const _page = state.pages[page.permalink] || {}
      state.pages = { ...state.pages, [page.permalink]: { ..._page, ...page } }
    }
  },
  actions: {
    async loadPage({ state, commit, dispatch }, permalink) {
      state.isPageLoading = true
      const response = await fetchApi(`/api/pages/${permalink}`, { method: "GET" })
      const { error, ...page } = await response.json()

      if (!error) {
        commit("setPage", page)
      }
      state.isPageLoading = false
    }
  }
}
