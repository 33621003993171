<template lang="pug">
  .app-onboarding-start.uk-text-center
    h4.title Elternteil 2
    p Zur alleinigen Nutzung der App und für den Testzeitraum benötigen wir ein vorläufiges Kürzel für den anderen Elternteil (bestehend aus zwei Buchstaben). Verbindet ihr eure Apps, kann natürlich jeder sein eigenes Kürzel bestimmen.

    h4 Vorläufiges Kürzel
    .big-input-group
      input.input(type="text" v-model.trim="newParentUserName" maxlength="2" placeholder="Kürzel Elternteil 2")
      .appended
        button(@click="updateName") {{ label }}
</template>

<script>
import ChildInput from "../users/child_input.vue"
import { mapState, mapGetters, mapActions } from "vuex"
import onboardingForm from "./mixins/onboarding_form"

export default {
  components: { ChildInput },
  mixins: [onboardingForm],
  created() {
    this.newParentUserName = this.parentUsername
    this.validateForm()
  },
  data() {
    return {
      newParentUserName: null
    }
  },
  computed: {
    ...mapGetters(["parents"]),

    parent() {
      if (!Object.keys(this.parents).length) {
        return {}
      }
      const values = Object.values(this.parents)
      return values[values.length - 1]
    },
    parentUsername() {
      return this.parent.username
    },
    label() {
      return this.parentUsername && this.parentUsername.length ? "Ändern" : "Hinzufügen"
    }
  },
  methods: {
    async updateName() {
      try {
        await this.$store.dispatch("inviteParent", { username: this.newParentUserName })
      } catch (e) {
        this.notify(
          "Elternteil konnte nicht eingeladen werden.",
          "danger"
        )
      }
      this.validateForm()
    },
    validateForm() {
      this.$emit("isFormValidChange", Boolean(this.parentUsername && this.parentUsername.length))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.big-input-group {
  .input {
    text-transform: uppercase;
    &::placeholder {
      text-transform: none;
    }
  }
  button {
    background: $turkies-color;
  }
}
</style>
