var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: "Anmelden",
        "sub-title": "Pendelkinder",
        "footer-buttons": _vm.footerButtons,
        "show-navigation-bar-button": false
      },
      on: { goToApp: _vm.goToApp }
    },
    [
      _c("div", { staticClass: "app-onboarding" }, [
        _c("div", { staticClass: "app-onboarding-start uk-text-center" }, [
          _c("h4", { staticClass: "title" }, [_vm._v("Es kann losgehen!")]),
          _c("p", [
            _c("strong", [
              _vm._v("Viel Spaß beim Umschauen in der "),
              _c("br"),
              _vm._v("Pendelkinder-App!")
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }