var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-container",
    {
      attrs: {
        title: _vm.pageTitle,
        "sub-title": _vm.subTitle,
        footerButtons: _vm.footerButtons,
        fetching: _vm.fetching
      },
      on: {
        submit: _vm.saveDate,
        goBack: _vm.goBack,
        deleteEvent: _vm.deleteEvent
      }
    },
    [
      _c("div", { staticClass: "calendar" }, [
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "strong-input-group" },
              [
                _vm.isConfirmationNeeded
                  ? _c(
                      "div",
                      {
                        staticClass: "uk-alert-primary",
                        attrs: { "uk-alert": "" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Du wurdest zu diesem Termin eingeladen. Möchtest du diesen bestätigen:"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex",
                            attrs: { "uk-margin": "" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-flex-auto uk-button uk-button-default uk-button-small",
                                on: { click: _vm.wantConfirmEvent }
                              },
                              [_vm._v("Bestätigen")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-flex-auto uk-button uk-button-default uk-button-small uk-margin-left",
                                on: { click: _vm.wantDeclineEvent }
                              },
                              [_vm._v("Ablehnen")]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm.isConfirmationPending
                  ? _c(
                      "div",
                      {
                        staticClass: "uk-alert-primary",
                        staticStyle: { padding: "15px 29px 10px 15px" },
                        attrs: { "uk-alert": "" }
                      },
                      [
                        _vm._v(
                          "Diesen Termin hast du erstellt & angefragt. Die Antwort steht noch aus!"
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.isSpecialEvent
                  ? _c("h2", [_vm._v("Ausnahme anlegen")])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title"
                        }
                      ],
                      attrs: { type: "text", placeholder: "Titel" },
                      domProps: { value: _vm.title },
                      on: {
                        focus: _vm.shouldStartEdit,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.title = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "left-col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.date,
                          expression: "date"
                        }
                      ],
                      staticClass: "expandable",
                      attrs: { type: "date" },
                      domProps: { value: _vm.date },
                      on: {
                        focus: _vm.shouldStartEdit,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.date = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "right-col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.endDate,
                          expression: "endDate"
                        }
                      ],
                      staticClass: "expandable",
                      attrs: { type: "date" },
                      domProps: { value: _vm.endDate },
                      on: {
                        focus: _vm.shouldStartEdit,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.endDate = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm.isSpecialEvent
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.repeatEndDate,
                              expression: "repeatEndDate"
                            }
                          ],
                          attrs: { id: "repeat-end-date", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.repeatEndDate)
                              ? _vm._i(_vm.repeatEndDate, null) > -1
                              : _vm.repeatEndDate
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.repeatEndDate,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.repeatEndDate = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.repeatEndDate = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.repeatEndDate = $$c
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "checkbox-label",
                            attrs: { for: "repeat-end-date" }
                          },
                          [_vm._v("Serientermin ab diesem Tag beenden")]
                        )
                      ])
                    ])
                  : _vm._e(),
                !_vm.isSpecialEvent
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        _vm._l(_vm.repeatOptions, function(option) {
                          return _c(
                            "label",
                            {
                              key: option.id,
                              attrs: { for: "repeat-" + option.id }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.repeatType,
                                    expression: "repeatType"
                                  }
                                ],
                                attrs: {
                                  type: "radio",
                                  id: "repeat-" + option.id
                                },
                                domProps: {
                                  value: option.id,
                                  checked: _vm._q(_vm.repeatType, option.id)
                                },
                                on: {
                                  focus: _vm.shouldStartEdit,
                                  change: function($event) {
                                    _vm.repeatType = option.id
                                  }
                                }
                              }),
                              _c("span", [_vm._v(_vm._s(option.value))])
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.repeatType,
                          expression: "repeatType"
                        }
                      ],
                      attrs: { type: "hidden" },
                      domProps: { value: _vm.repeatType },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.repeatType = $event.target.value
                        }
                      }
                    }),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "left-col",
                      staticStyle: { position: "static" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.startTime,
                            expression: "startTime"
                          }
                        ],
                        staticClass: "expandable",
                        attrs: { type: "text", placeholder: "von" },
                        domProps: { value: _vm.startTime },
                        on: {
                          touchstart: function($event) {
                            return _vm.changeType($event, "time")
                          },
                          focus: function($event) {
                            return _vm.changeType($event, "time")
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.startTime = $event.target.value
                          }
                        }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "right-col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.endTime,
                          expression: "endTime"
                        }
                      ],
                      staticClass: "expandable",
                      attrs: { type: "text", placeholder: "bis" },
                      domProps: { value: _vm.endTime },
                      on: {
                        touchstart: function($event) {
                          return _vm.changeType($event, "time")
                        },
                        focus: function($event) {
                          return _vm.changeType($event, "time")
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.endTime = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "left-col" },
                    [
                      _c("multi-select", {
                        attrs: {
                          options: _vm.childrenOptions,
                          placeholder: "Kind",
                          tip: "Kind/er auswählen"
                        },
                        nativeOn: {
                          touchstart: function($event) {
                            return _vm.shouldStartEdit($event)
                          }
                        },
                        model: {
                          value: _vm.childIds,
                          callback: function($$v) {
                            _vm.childIds = $$v
                          },
                          expression: "childIds"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right-col" },
                    [
                      _c("multi-select", {
                        attrs: {
                          options: _vm.parentOptions,
                          placeholder: "Elternteil",
                          tip: "Elternteil auswählen",
                          "all-label": "Beide"
                        },
                        nativeOn: {
                          touchstart: function($event) {
                            return _vm.shouldStartEdit($event)
                          }
                        },
                        model: {
                          value: _vm.parentIds,
                          callback: function($$v) {
                            _vm.parentIds = $$v
                          },
                          expression: "parentIds"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.street,
                          expression: "street"
                        }
                      ],
                      attrs: { type: "text", placeholder: "Adresse" },
                      domProps: { value: _vm.street },
                      on: {
                        focus: _vm.shouldStartEdit,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.street = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.description,
                          expression: "description"
                        }
                      ],
                      attrs: { placeholder: "Notiz" },
                      domProps: { value: _vm.description },
                      on: {
                        focus: _vm.shouldStartEdit,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.description = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("uploads", { attrs: { files: _vm.uploadedFiles } }),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("input", {
                      attrs: {
                        placeholder: "Datei",
                        type: "file",
                        multiple: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.processFiles($event)
                        },
                        focus: _vm.shouldStartEdit
                      }
                    })
                  ])
                ])
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }