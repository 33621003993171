<template lang="pug">
  .app-onboarding-start.uk-text-center
    h4.title Koppelung der Kalender
    p Du kannst die App mit dem anderen Elternteil gemeinsam nutzen. Um die Koppelung zu aktiveren gebe hier die E-Mail-Adresse des anderen Elternteils an und lade zu einer gemeinsamen Nutzung der App ein. Die E-Mail-Adresse kannst du auch später unter den Einstellungen hinzufügen.

    .big-input-group
      input.input(type="text" v-model.trim="newParentEmail" placeholder="E-Mail-Adresse anderer Elternteil")
      .appended
        button(@click="invite") {{ parentConnectionTitle }}

    p
      strong WICHTIG: Hast du bereits einen Einladungslink erhalten, dann überspringe diese Seite.
</template>

<script>
import ChildInput from "../users/child_input.vue"
import { mapState, mapGetters, mapActions } from "vuex"
import onboardingForm from "./mixins/onboarding_form"

export default {
  components: { ChildInput },
  mixins: [onboardingForm],
  created() {
    this.newParentEmail = this.parentEmail
    this.validateForm()
  },
  data() {
    return {
      newParentEmail: null
    }
  },
  computed: {
    ...mapGetters(["parents"]),

    parent() {
      if (!Object.keys(this.parents).length) {
        return {}
      }
      const values = Object.values(this.parents)
      return values[values.length - 1]
    },
    parentEmail() {
      return this.parent.email
    },
    isInvited() {
      return Boolean(this.parent.state === "invited" && this.parent.email)
    },
    parentConnectionTitle() {
      if (this.parent.state === "accepted") {
        return "Verbunden"
      } else if (this.isInvited) {
        return "Gesendet"
      } else {
        return "Verbinden"
      }
    },
    parentUserName() {
      return this.parent.username
    },
  },
  methods: {
    async invite() {
      if (this.parent && this.parent.state === "accepted") {
        this.notify("Es wurde bereits ein Elternteil eingeladen.", "danger")
        return
      }

      if (
        this.parent &&
        this.parent.email &&
        !confirm(
          "Soll ein neues Elternteil eingeladen werden? Wenn ja, wird die erste Einladung ungültig."
        )
      ) {
        return
      }
      try {
        await this.$store.dispatch("inviteParent", { email: this.newParentEmail, username: this.parentUserName })
      } catch (e) {
        this.notify(
          "Elternteil konnte nicht eingeladen werden. Stimmt die E-Mail-Adresse?",
          "danger"
        )
      }
      this.validateForm()
    },
    validateForm() {
      this.$emit("isFormValidChange", this.parent.state === "accepted" || this.isInvited)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/variables.scss";

.big-input-group {
  button {
    background: $turkies-color;
  }
}
</style>
