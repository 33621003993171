<template lang="pug">
  div
    select(v-model="selectedValue" :class="classNames")
      option(:value="defaultValue" disabled) {{ placeholder }}
      option(v-for="option in options" :value="option.id") {{ option.value }}
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: null
    },
    classNames: {
      type: String,
      default: null
    },
    defaultValue: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {
      active: false,
      width: 0,
      preventClose: false,
      selectedValue: ""
    }
  },
  computed: {
    currentValue() {
      for (var i = this.options.length - 1; i >= 0; i--) {
        if (this.value == this.options[i].id) {
          return this.options[i].value
        }
      }

      return ""
    }
  },
  watch: {
    selectedValue(newValue) {
      this.$emit("input", newValue)
    }
  },
  created() {
    if (this.value) {
      this.selectedValue = this.value
    }
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.closeSelect)
  },
  methods: {
    closeSelect() {
      if (this.preventClose) {
        this.preventClose = false
        return
      }
      this.active = false
    },
    focus(e) {
      this.width = e.target.offsetWidth
      this.preventClose = true
      this.active = true
    },
    optionSelected(option) {
      this.$emit("input", option.id)
    }
  }
}
</script>
